import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import Divider from '@/components/primitives/Divider';
import { cn } from '@/utils/cn';
import { Checkbox } from '@/components/primitives/Checkbox';
import { TransText } from '@/i18n/trans/text';
import type { LegDTO } from '@/types/dto';
import { TransportationTypeIcon } from '@/components/icons/TransportationTypeIcon';
import type { FC } from 'react';
import { formatDateWithDayOfWeek, formatTime } from '@/utils/date-time';
import { getPtModeIconName } from '@/utils/icon';

interface LegSelectionCardProps {
  leg: LegDTO;
  isSelected: boolean;
  onSelect: () => void;
  refundable: 'YES' | 'NO' | 'WITH_CONDITION';
  productSummary: string;
  indeterminate?: boolean;
}

export const LegSelectionCard: FC<LegSelectionCardProps> = ({
  leg,
  isSelected,
  onSelect,
  refundable,
  productSummary,
  indeterminate = false,
}) => {
  const ptModeIcon = getPtModeIconName(leg.ptMode);
  const isRefundable = refundable === 'YES';

  const handleToggle = () => {
    if (isRefundable) {
      onSelect();
    }
  };

  return (
    <div
      className={cn(
        'cursor-pointer rounded-sm border border-neutral-light p-3',
        'laptop:hover:border-primary-medium laptop:hover:bg-primary-pale',
        {
          'border-primary bg-primary-light':
            (isSelected || indeterminate) && isRefundable,
          'cursor-not-allowed bg-greyscale-100 laptop:hover:border-neutral-light laptop:hover:bg-greyscale-100':
            !isRefundable,
        }
      )}
      onClick={handleToggle}
      data-state={isSelected ? 'checked' : 'unchecked'}
    >
      <div className="mb-2 flex items-center gap-3">
        <Checkbox
          checked={isSelected}
          onClick={handleToggle}
          disabled={!isRefundable}
          indeterminate={indeterminate}
        />
        <Typography
          variant="body1-bold"
          className={cn({ 'text-neutral': !isRefundable })}
        >
          {formatDateWithDayOfWeek(leg.departureTime)}
        </Typography>
      </div>

      <div className="grid grid-cols-[auto,1fr] gap-x-2 gap-y-2 pl-7">
        <div className="flex items-center justify-center">
          <TransportationTypeIcon
            ptModeIcon={ptModeIcon}
            className={cn({ 'text-neutral': !isRefundable })}
          />
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Typography
              variant="body2"
              className={cn({ 'text-neutral': !isRefundable })}
            >
              {leg.originStop?.name}
            </Typography>
          </div>
          <Typography
            variant="body2"
            className={cn('text-neutral', {
              'text-neutral-medium': !isRefundable,
            })}
          >
            {formatTime(leg.departureTime)}
          </Typography>
        </div>

        <div className="relative flex items-center justify-center">
          <div
            className={cn(
              'absolute bottom-[14px] left-1/2 h-[calc(100%-8px)] w-[2px] -translate-x-[50%] bg-neutral-light',
              {
                'bg-neutral': !isRefundable,
              }
            )}
          />
          <Icons.destination
            height={12}
            width={12}
            className={cn('text-primary', { 'text-neutral': !isRefundable })}
          />
        </div>
        <div className="flex items-center justify-between">
          <Typography
            variant="body2"
            className={cn({ 'text-neutral': !isRefundable })}
          >
            {leg.destinationStop?.name}
          </Typography>
          <Typography
            variant="body2"
            className={cn('text-neutral', {
              'text-neutral-medium': !isRefundable,
            })}
          >
            {formatTime(leg.arrivalTime)}
          </Typography>
        </div>
      </div>

      <Divider
        className="my-2"
        variant={
          (isSelected || indeterminate) && isRefundable ? 'strong' : 'medium'
        }
      />

      <div className="flex items-center justify-between">
        <Typography
          variant="body2-bold"
          className={cn({ 'text-neutral': !isRefundable })}
        >
          {productSummary}
        </Typography>
        <div className="flex items-center gap-2">
          <Icons.checkCircle
            className={cn('text-success-text', {
              'text-error-text': !isRefundable,
            })}
            height={12}
            width={12}
          />
          <Typography variant="body2" className="text-dark">
            <TransText
              i18nKey={isRefundable ? 'refundable' : 'nonRefundable'}
            />
          </Typography>
        </div>
      </div>
    </div>
  );
};
