import { type FC, useCallback, useEffect } from 'react';
import { TransText } from '@/i18n/trans/text';
import { Typography } from '@/components/primitives/Typography';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { Icons } from '@/components/icons';
import Divider from '@/components/primitives/Divider';
import { Button } from '@/components/primitives/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { CenteredLayout } from '@/components/layouts/CenteredLayout';
import { withAuthGuard } from '@/hoc/withAuthGuard';

interface SignupConfirmationProps {}

const SignupConfirmationComponent: FC<SignupConfirmationProps> = () => {
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state?.id) {
      navigate('/signup');
    }
  }, [location, navigate]);

  const handleClick = useCallback(() => {
    // once BR-50961 completed:
    // TODO: Replace endpoint in createCustomerProfile with POST /unverified-customer-profiles
    // TODO: Take account id from response and pass it into navigate state
    // TODO: Take account id from navigate state and call endpoint to resend verification email

    console.log(`To be implemented. ${location.state.id}`);
  }, [location]);

  return (
    <CenteredLayout>
      <div className="mx-5 mt-10 laptop:mx-auto laptop:max-w-[410px]">
        <Typography
          variant={isLaptopOrBigger ? 'title' : 'mobile-title'}
          className="text-left"
          asChild
        >
          <h1>
            <TransText i18nKey="confirmRegistration" />
          </h1>
        </Typography>
        <CardPrimitiveRoot className="mt-8 rounded-xl">
          <CardContent className="flex flex-col gap-3">
            <div className="flex items-center gap-2">
              <Icons.checkCircle className="text-success-text" />
              <Typography variant="subtitle">
                <TransText i18nKey="checkYourEmail" />
              </Typography>
            </div>
            <Typography variant="body1">
              <TransText i18nKey="confirmRegistrationDescription" />
            </Typography>
            <Divider />
            <Button
              variant="ghost"
              data-testid="resend-confirmation-email-button"
              onClick={handleClick}
              className="self-center"
            >
              <Typography variant="button">
                <TransText i18nKey="resendConfirmationEmail" />
              </Typography>
            </Button>
          </CardContent>
        </CardPrimitiveRoot>
      </div>
    </CenteredLayout>
  );
};

const SignupConfirmation = withAuthGuard('loginOrSignup')(
  SignupConfirmationComponent
);

export default SignupConfirmation;
