import { z } from 'zod';
import type { TFunction } from 'i18next';
import type { ProductRequirements } from '@/types/fieldRequirements';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const createCheckoutSchema = (
  t: TFunction,
  productRequirements: ProductRequirements
) => {
  const { passenger: passengerReqs, purchaser: purchaserReqs } =
    productRequirements;

  return z
    .object({
      passengers: z.array(
        z.object({
          id: z.string(),
          externalReference: z.string(),
          firstName: passengerReqs.firstName.required
            ? z.string().min(1, { message: t('text.mustBeFilled') })
            : z.string().optional(),
          lastName: passengerReqs.lastName.required
            ? z.string().min(1, { message: t('text.mustBeFilled') })
            : z.string().optional(),
          email: passengerReqs.email.required
            ? z
                .string()
                .min(1, { message: t('text.mustBeFilled') })
                .email({ message: t('text.invalidEmailFormat') })
            : z
                .string()
                .email({ message: t('text.invalidEmailFormat') })
                .optional(),
          phone: z.object({
            number: passengerReqs.phone.required
              ? z
                  .string()
                  .min(1, { message: t('text.mustBeFilled') })
                  .refine((val) => !val || isValidPhoneNumber(val), {
                    message: t('text.invalidPhoneNumberFormat'),
                  })
              : z
                  .string()
                  .refine((val) => !val || isValidPhoneNumber(val), {
                    message: t('text.invalidPhoneNumberFormat'),
                  })
                  .optional(),
          }),
          age: z.number().nullable(),
        })
      ),
      purchaser: z.object({
        externalReference: z.string().optional(),
        firstName: purchaserReqs.firstName.required
          ? z.string().min(1, { message: t('text.mustBeFilled') })
          : z.string().optional(),
        lastName: purchaserReqs.lastName.required
          ? z.string().min(1, { message: t('text.mustBeFilled') })
          : z.string().optional(),
        email: purchaserReqs.email.required
          ? z
              .string()
              .min(1, { message: t('text.mustBeFilled') })
              .email({ message: t('text.invalidEmailFormat') })
          : z
              .string()
              .email({ message: t('text.invalidEmailFormat') })
              .optional(),
        phone: z.object({
          number: purchaserReqs.phone.required
            ? z
                .string()
                .min(1, { message: t('text.mustBeFilled') })
                .refine((val) => !val || isValidPhoneNumber(val), {
                  message: t('text.invalidPhoneNumberFormat'),
                })
            : z
                .string()
                .refine((val) => !val || isValidPhoneNumber(val), {
                  message: t('text.invalidPhoneNumberFormat'),
                })
                .optional(),
        }),
      }),
      consents: z.array(
        z.object({
          isMandatory: z.boolean(),
          description: z.string().optional(),
          checked: z.boolean(),
          id: z.union([z.number(), z.string()]),
          code: z.union([z.string(), z.undefined()]),
        })
      ),
    })
    .superRefine(({ consents }, ctx) => {
      consents.forEach((consent, index) => {
        if (consent.isMandatory && !consent.checked) {
          ctx.addIssue({
            path: ['consents', index],
            code: z.ZodIssueCode.custom,
            message: '', // No message needed, red text for consent description is enough
          });
        }
      });
    });
};

export type CheckoutValues = z.infer<ReturnType<typeof createCheckoutSchema>>;

export const createAncillarySchema = (t: TFunction) => {
  return z.object({
    selectedLegId: z.string().min(1, { message: t('text.mustChooseLeg') }),
    selectedAncillaries: z
      .array(
        z.object({
          id: z.string(),
          bookedOfferId: z.string(),
          additionalOfferId: z.string(),
          amount: z.number(),
          passengersExternalReferences: z
            .array(z.string())
            .min(1, { message: t('text.minimumOne') }),
        })
      )
      .min(1, { message: t('text.minimumOne') }),
  });
};

export type AncillaryValues = z.infer<ReturnType<typeof createAncillarySchema>>;

export const createFindBookingSchema = (t: TFunction) => {
  return z.object({
    email: z
      .string()
      .min(1, { message: t('text.mustBeFilled') })
      .email({ message: t('text.invalidEmailFormat') }),
    bookingNumber: z.string().min(1, { message: t('text.mustBeFilled') }),
  });
};

export type FindBookingValues = z.infer<
  ReturnType<typeof createFindBookingSchema>
>;

export const createLoginSchema = (t: TFunction) => {
  return z.object({
    email: z
      .string()
      .min(1, { message: t('text.mustBeFilled') })
      .email({ message: t('text.invalidEmailFormat') }),
    password: z
      .string()
      .min(8, { message: t('text.minimumEight') })
      .max(64, { message: t('text.maximum64') }),
  });
};

export type LoginValues = z.infer<ReturnType<typeof createLoginSchema>>;

export const createSignupMethodSchema = (t: TFunction) => {
  return z.object({
    email: z
      .string()
      .min(1, { message: t('text.mustBeFilled') })
      .email({ message: t('text.invalidEmailFormat') }),
  });
};

export type SignupMethodValues = z.infer<
  ReturnType<typeof createSignupMethodSchema>
>;

export const createSignupSchema = (
  t: TFunction,
  passwordRegexString: string | undefined
) => {
  return z
    .object({
      email: z
        .string()
        .min(1, { message: t('text.requiredField') })
        .email({ message: t('text.invalidEmailFormat') }),
      firstName: z.string().min(1, { message: t('text.requiredField') }),
      lastName: z.string().min(1, { message: t('text.requiredField') }),
      phoneNumber: z.string().optional(),
      password: z.string().regex(new RegExp(passwordRegexString || ''), {
        message: t('text.doesntMatchCriteriaDescribedAbove'),
      }),
      repeatPassword: z.string(),
      consents: z.array(
        z.object({
          isMandatory: z.boolean(),
          description: z.string().optional(),
          checked: z.boolean(),
          id: z.union([z.number(), z.string()]),
          code: z.union([z.string(), z.undefined()]),
        })
      ),
    })
    .superRefine(({ password, repeatPassword, consents }, ctx) => {
      if (password !== repeatPassword) {
        ctx.addIssue({
          path: ['password'],
          code: z.ZodIssueCode.custom,
          message: t('text.passwordsMustMatch'),
        });
        ctx.addIssue({
          path: ['repeatPassword'],
          code: z.ZodIssueCode.custom,
          message: t('text.passwordsMustMatch'),
        });
      }

      consents.forEach((consent, index) => {
        if (consent.isMandatory && !consent.checked) {
          ctx.addIssue({
            path: ['consents', index],
            code: z.ZodIssueCode.custom,
            message: '', // No message needed, red text for consent description is enough
          });
        }
      });
    });
};

export type SignupValues = z.infer<ReturnType<typeof createSignupSchema>>;
