import { enUS as en, it } from 'date-fns/locale';
import { capitalize } from 'lodash';

export const locales = {
  en,
  it: {
    ...it,
    localize: {
      ...it.localize,
      // @ts-expect-error args typed as any[]
      day: (...args) => capitalize(it.localize.day(...args)),
      // @ts-expect-error args typed as any[]
      month: (...args) => capitalize(it.localize.month(...args)),
    },
  },
} as const;

export type Locale = (typeof locales)[keyof typeof locales];
