import type { InternalAxiosRequestConfig } from 'axios';
import { getLanguage } from '@/i18n';

/**
 * @description Prefixes requests with the language that the user has chosen
 * @example /offers -> /en/offers
 * @param config
 */
export default function languageMiddleware(
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig {
  const language = getLanguage();

  config.baseURL = `${config.baseURL}/${language}`;

  return config;
}
