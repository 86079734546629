import type { FC } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import type { BookingItem } from '@/types/booking';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import { cn } from '@/utils/cn';
import { useCallback } from 'react';

export type BookingItemRowProps = BookingItem & {
  icon: keyof typeof Icons;
};

const BookingItemRow: FC<BookingItemRowProps> = ({
  icon,
  description,
  amount,
  status,
  ...rest
}) => {
  const currency = useSelector(currencySelector);
  const IconComponent = Icons[icon];
  const isExchanged = status === 'EXCHANGED';
  const isRefunded = status === 'REFUNDED';

  const renderPassengerInfo = useCallback(() => {
    if ('passengerIndex' in rest) {
      const { passengerIndex, firstName, lastName } = rest;

      if (passengerIndex !== null && !firstName && !lastName) {
        return (
          <div className="flex items-center text-neutral">
            <Icons.dot className="mx-1 mt-0.5" />
            <Typography variant="body2">
              <TransText
                i18nKey="passengerIndex"
                values={{ count: passengerIndex + 1 }}
              />
            </Typography>
          </div>
        );
      } else if (firstName || lastName) {
        return (
          <div className="flex items-center text-neutral">
            <Icons.dot className="mx-1 mt-0.5" />
            {firstName && (
              <Typography variant="body2" className="mr-1">
                {firstName}
              </Typography>
            )}
            {lastName && <Typography variant="body2">{lastName}</Typography>}
          </div>
        );
      }
    }

    return null;
  }, [rest]);

  const renderTripInfo = useCallback(() => {
    if ('originStopName' in rest && 'destinationStopName' in rest) {
      const { originStopName, destinationStopName } = rest;

      if (originStopName && destinationStopName) {
        return (
          <div className="ml-6 flex flex-wrap items-center text-neutral">
            <Typography variant="body2">{originStopName}</Typography>
            <Icons.oneWay className="mx-1 mt-0.5 h-3 w-3" />
            <Typography variant="body2">{destinationStopName}</Typography>
            {renderPassengerInfo()}
          </div>
        );
      }
    }

    return null;
  }, [rest, renderPassengerInfo]);

  if (isExchanged) {
    return null;
  }

  return (
    <div className="px-4 py-2">
      <div className="flex items-center justify-between">
        <div className="flex items-end gap-2">
          <IconComponent
            className={cn('h-4 w-4 text-primary', {
              'text-neutral': isRefunded,
            })}
          />

          <Typography
            variant="body1-bold"
            className={cn('text-dark', {
              'text-neutral line-through decoration-error-border': isRefunded,
            })}
          >
            {description}
          </Typography>
        </div>
        <Typography
          variant="body2-bold"
          className={cn('text-primary', {
            'text-neutral line-through decoration-error-border': isRefunded,
          })}
        >
          {amount}
          {currency.symbol}
        </Typography>
      </div>
      {renderTripInfo()}
    </div>
  );
};

export default BookingItemRow;
