import React, { createContext } from 'react';
import type { BackgroundTheme } from '@/types/color';

export const BackgroundThemeContext = createContext<
  BackgroundTheme | undefined
>(undefined);

interface BackgroundThemeProviderProps {
  children: React.ReactNode;
  backgroundTheme: BackgroundTheme;
}

export const BackgroundThemeProvider: React.FC<
  BackgroundThemeProviderProps
> = ({ children, backgroundTheme }) => {
  return (
    <BackgroundThemeContext.Provider value={backgroundTheme}>
      {children}
    </BackgroundThemeContext.Provider>
  );
};
