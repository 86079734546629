import { type FC, type ReactNode } from 'react';
import type { BookedTripDTO } from '@/types/dto';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import LegCard from '@/components/manage/tickets/LegCard';
import { useSelector } from '@/store/utils';
import { manageFlowBookingAdmissionsByIdSelector } from '@/features/manage/manageSelectors';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { cn } from '@/utils/cn';
import { useBookingNotExchangedTrips } from '@/hooks/useBookingNotExchangedTrips';
import { Flow } from '@/types/booking';

interface TripsByDirectionProps {
  trips: Array<BookedTripDTO>;
  header: ReactNode;
}

const TripsByDirection: FC<TripsByDirectionProps> = ({ trips, header }) => {
  const admissionsByLegId = useSelector(
    manageFlowBookingAdmissionsByIdSelector
  );
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const notExchangedTrips = useBookingNotExchangedTrips(
    trips,
    Flow.manageBooking
  );

  return (
    <div className="flex flex-col">
      {notExchangedTrips.map((trip, key) => (
        <div key={key}>
          <div
            className={cn('mb-4 flex flex-row items-baseline gap-4', {
              'flex-col gap-1': !isLaptopOrBigger,
            })}
          >
            <Typography
              variant={isLaptopOrBigger ? 'heading2' : 'subtitle'}
              asChild
            >
              <h1>{header}</h1>
            </Typography>
            <Typography
              variant={isLaptopOrBigger ? 'paragraph' : 'body2'}
              className="flex flex-row items-center gap-1 text-neutral-light"
            >
              {trip.originStop?.name}
              <Icons.oneWay height={20} width={20} />
              {trip.destinationStop?.name}
            </Typography>
          </div>

          {trip.legs?.map((leg, index) => {
            const nextLeg = trip.legs?.[index + 1];
            const admissions = leg.id ? admissionsByLegId[leg.id] : [];

            return (
              <LegCard
                key={index}
                leg={leg}
                nextLeg={nextLeg}
                admissions={admissions}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default TripsByDirection;
