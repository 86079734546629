import { createAsyncThunk } from '@/store/utils';
import { getCustomerProfile } from '@/features/auth/authService';
import type { CustomerProfileDTO } from '@/types/dto';
import { createAction } from '@reduxjs/toolkit';
import { getAccessToken } from '@/utils/auth';

export const clearProfile = createAction('profile/clearProfile');

export const getProfile = createAsyncThunk<CustomerProfileDTO | null>(
  'profile/getProfile',
  async () => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      return null;
    }

    try {
      return await getCustomerProfile();
    } catch (error) {
      console.error('Failed to fetch profile:', error);
      return null;
    }
  }
);
