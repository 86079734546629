import { createReducer } from '@reduxjs/toolkit';
import { clearProfile, getProfile } from '@/features/profile/profileActions';
import type { CustomerProfileDTO } from '@/types/dto';

export interface ProfileState {
  current: CustomerProfileDTO | null;
}

const initialState: ProfileState = {
  current: null,
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getProfile.fulfilled, (state, action) => {
      state.current = action.payload;
    })
    .addCase(clearProfile, (state) => {
      state.current = null;
    });
});
