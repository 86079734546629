import type { Flow } from '@/types/booking';
import { useBooking } from '@/hooks/useBooking';
import { useEffect } from 'react';
import type { BookingDTO } from '@/types/dto';
import { useDispatch } from '@/store/utils';
import { getIssuers } from '@/features/configuration/configurationActions';

export const useBookingNonTripOfferIssuers = (flow: Flow) => {
  const dispatch = useDispatch();
  const booking = useBooking(flow);

  useEffect(() => {
    async function getDetails() {
      dispatch(getIssuers(getFulfillments(booking)));
    }

    getDetails();
  }, [booking, dispatch]);
};

const getFulfillments = (booking: BookingDTO | null) => {
  const fulfillments = [];

  for (const nonTripOffer of booking?.nonTripOffers ?? []) {
    for (const admission of nonTripOffer.admissions ?? []) {
      for (const fulfillment of admission.fulfillments ?? []) {
        fulfillments.push(fulfillment);
      }
    }
  }

  return fulfillments;
};
