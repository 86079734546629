import { useBookingTrips } from '@/hooks/useBookingTrips';
import { useCallback, useMemo } from 'react';
import type { BookedTripDTO, BookingDTO } from '@/types/dto';
import { isTimestampInPast } from '@/utils/date-time';

export const useBookingRefundEligibleTrips = (booking: BookingDTO | null) => {
  const { outboundTrips: allOutboundTrips, inboundTrips: allInboundTrips } =
    useBookingTrips(booking);

  const refundEligibleTrips = useCallback((trips: BookedTripDTO[]) => {
    return trips.filter(
      (trip) =>
        trip.legs?.every(
          (leg) =>
            leg.departureTime &&
            !isTimestampInPast(leg.departureTime, 'Europe/Stockholm') // TODO: Get timezone dynamically
        ) &&
        trip.bookedOffers?.some((offer) =>
          offer.admissions?.some(
            ({ status, refundable }) =>
              status !== 'REFUNDED' && refundable === 'YES'
          )
        )
    );
  }, []);

  const refundEligibleOutboundTrips = useMemo(
    () => refundEligibleTrips(allOutboundTrips),
    [refundEligibleTrips, allOutboundTrips]
  );
  const refundEligibleInboundTrips = useMemo(
    () => refundEligibleTrips(allInboundTrips),
    [refundEligibleTrips, allInboundTrips]
  );

  return { refundEligibleInboundTrips, refundEligibleOutboundTrips };
};
