import RefundLegOverview from '@/components/manage/refund/leg/RefundLegOverview';
import { RefundLegSelection } from '@/components/manage/refund/leg/RefundLegSelection';
import RefundModal from '@/components/manage/refund/RefundModal';
import { initiateLegsRefund } from '@/features/manage/manageActions';
import { refundSelectedLegAdmissionsSelector } from '@/features/manage/manageSelectors';
import { useDispatch, useSelector } from '@/store/utils';
import { useCallback, useMemo, type FC } from 'react';

interface RefundLegModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RefundLegModal: FC<RefundLegModalProps> = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const selectedLegAdmissions = useSelector(
    refundSelectedLegAdmissionsSelector
  );

  const selected = useMemo(
    () => Object.keys(selectedLegAdmissions).length,
    [selectedLegAdmissions]
  );

  const handleInitiateRefund = useCallback(async () => {
    await dispatch(initiateLegsRefund()).unwrap();
  }, [dispatch]);

  return (
    <RefundModal
      isOpen={isOpen}
      refundSelection={RefundLegSelection}
      refundOverview={RefundLegOverview}
      selected={selected}
      chooseToRefundText="chooseTripsToRefund"
      refundText="refundTrips"
      onInitiateRefund={handleInitiateRefund}
      onClose={onClose}
    />
  );
};

export default RefundLegModal;
