import type { FC } from 'react';
import type { Components } from '@/types/schemas/agent-schema';
import { Button } from '@/components/primitives/Button';
import { cn } from '@/utils/cn';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { type JourneyDTO } from '@/types/dto';
import { useDispatch } from '@/store/utils';
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { TransText } from '@/i18n/trans/text';
import { format, formatDuration } from '@/utils/date-time';
import currencySymbolMap from '@/utils/currencySymbolMap';

interface JourneyCardProps {
  journey: Components.Schemas.TurnitOsdmTranslatorApiFeaturesOffersModelsSearchOffersResponseJourney;
  warningMessage: string | null;
  onContinue: () => void;
  setSelectedJourney: ActionCreatorWithPayload<JourneyDTO>;
}

function getPrice(amount: number | undefined) {
  if (!amount) {
    return null;
  }

  const [integer, decimal] = amount.toString().split('.');

  return (
    <>
      <Typography variant="heading2">{integer}</Typography>
      {decimal ? (
        <>
          <Typography variant="heading2" className="mr-0.5">
            .
          </Typography>
          <Typography variant="subtitle">{decimal}</Typography>
        </>
      ) : null}
    </>
  );
}

const JourneyCard: FC<JourneyCardProps> = ({
  journey,
  warningMessage,
  onContinue,
  setSelectedJourney,
}) => {
  const dispatch = useDispatch();
  const departureTime =
    journey.departureTime && format(journey.departureTime, 'HH:mm');
  const arrivalTime =
    journey.arrivalTime && format(journey.arrivalTime, 'HH:mm');

  return (
    <div
      className="mb-2 flex w-full flex-row rounded-xl last:mb-0"
      data-testid="journey-card"
    >
      <div
        className={cn(
          'flex-1 rounded-l-xl border border-r-0 border-neutral bg-white px-3 py-2 text-dark',
          {
            'bg-greyscale-100 text-neutral': !!warningMessage,
          }
        )}
      >
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center gap-1">
            <Typography variant="times" asChild>
              <h2>{departureTime}</h2>
            </Typography>
            <Typography variant="times">
              <span>-</span>
            </Typography>
            <Typography variant="times" asChild>
              <h2>{arrivalTime}</h2>
            </Typography>
          </div>
        </div>

        <div className="mt-2 flex flex-row items-center gap-3">
          <div className="flex flex-row items-center gap-1">
            <Icons.time
              height={14}
              width={14}
              className={cn('text-primary', {
                'text-neutral': warningMessage,
              })}
            />
            <Typography variant="body2" className="text-neutral">
              {formatDuration(journey.duration)}
            </Typography>
          </div>
          {journey.transfers ? (
            <div className="flex flex-row items-center gap-1">
              <Icons.infoCircle
                height={14}
                width={14}
                className={cn('text-error-text', {
                  'text-neutral': warningMessage,
                })}
              />
              <Typography className="text-error-text" variant="body2">
                <TransText
                  i18nKey="journeyTransfersCount"
                  values={{ count: journey.transfers }}
                />
              </Typography>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-1">
              <Icons.infoCircle
                height={14}
                width={14}
                className={cn('text-primary', {
                  'text-neutral': warningMessage,
                })}
              />
              <Typography variant="body2" className="text-primary">
                <TransText i18nKey="direct" />
              </Typography>
            </div>
          )}
        </div>
      </div>
      <Button
        className={cn(
          'h-auto w-[117px] flex-col rounded-l-none rounded-r-xl tablet:w-[144px]',
          {
            'border-neutral': !!warningMessage,
          }
        )}
        disabled={!!warningMessage || !journey.lowestPrice}
        data-testid="journey-card-action-button"
        onClick={() => {
          dispatch(setSelectedJourney(journey));
          onContinue();
        }}
      >
        {warningMessage ? (
          <Typography variant="body1" className="text-white">
            {warningMessage}
          </Typography>
        ) : !journey.lowestPrice ? (
          <Typography variant="body1" className="text-white">
            <TransText i18nKey="notAvailable" />
          </Typography>
        ) : (
          <>
            <Typography variant="body2">
              <TransText i18nKey="from" />
            </Typography>
            <div className="flex items-center">
              <div className="flex items-baseline">
                {getPrice(journey.lowestPrice.amount)}
                {journey.lowestPrice.currency && (
                  <Typography variant="subtitle" className="mr-0.5">
                    {currencySymbolMap[journey.lowestPrice.currency]}
                  </Typography>
                )}
              </div>
              <Icons.chevronRight />
            </div>
          </>
        )}
      </Button>
    </div>
  );
};

export default JourneyCard;
