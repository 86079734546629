import React, { type FC, type ReactNode, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { Button } from '@/components/primitives/Button';
import { Typography } from '@/components/primitives/Typography';
import { TransText, type TransTextKeys } from '@/i18n/trans/text';

interface ConfirmModalProps {
  open?: boolean;
  setOpen?: (value: boolean) => void;
  children?: ReactNode;
  title: ReactNode;
  description: ReactNode;
  cancelLabel?: TransTextKeys;
  confirmLabel?: TransTextKeys;
  interactOutside?: boolean;
  onCancel?: () => Promise<void> | void;
  onConfirm?: () => Promise<void> | void;
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  open: controlledOpen,
  setOpen: controlledSetOpen,
  children,
  title,
  description,
  cancelLabel,
  confirmLabel,
  interactOutside = false,
  onCancel,
  onConfirm,
}) => {
  const [unControlledOpen, setUncontrolledOpen] = useState(false);
  const open = controlledOpen ?? unControlledOpen;
  const setOpen = controlledSetOpen ?? setUncontrolledOpen;

  const handleCancel = async () => {
    await onCancel?.();
    setOpen(false);
  };

  const handleConfirm = async () => {
    await onConfirm?.();
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={() => setOpen(true)}>
      {children && <DialogTrigger asChild>{children}</DialogTrigger>}
      <DialogContent
        className="w-5/6 tablet:w-[480px]"
        onInteractOutside={
          interactOutside ? undefined : (event) => event.preventDefault()
        }
      >
        <DialogHeader className="border-none px-6 py-4" showClose={false}>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription asChild>
          <div className="px-6 pb-6 text-dark">{description}</div>
        </DialogDescription>
        <DialogFooter className="h-min items-end gap-2 px-4 tablet:px-8">
          {cancelLabel && (
            <Button
              data-testid="confirm-modal-cancel"
              variant="tertiary"
              className="h-14 w-full rounded-lg tablet:h-10 tablet:w-min"
              onClick={handleCancel}
            >
              <Typography variant="button">
                <TransText i18nKey={cancelLabel} />
              </Typography>
            </Button>
          )}
          {confirmLabel && (
            <Button
              data-testid="confirm-modal-action"
              className="h-14 w-full rounded-lg tablet:h-10 tablet:w-min"
              onClick={handleConfirm}
            >
              <Typography variant="button">
                <TransText i18nKey={confirmLabel} />
              </Typography>
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
