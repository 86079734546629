import getApiInstance from '@/api';
import type {
  PayWithAdyenBodyDTO,
  ValidateAdyenCheckoutPaymentDetailsBodyDTO,
} from '@/types/dto';

export const initiateAdyenCheckout = async (
  bookingId: string,
  body: PayWithAdyenBodyDTO
) => {
  const api = (await getApiInstance()).agentApi;

  return await api.Payments_PayWithAdyenCheckout({ bookingId }, body);
};

export const validateAdyenCheckoutPaymentDetails = async (
  bookingId: string,
  body: ValidateAdyenCheckoutPaymentDetailsBodyDTO
) => {
  const api = (await getApiInstance()).agentApi;

  return await api.Payments_ValidateAdyenCheckoutPaymentDetails(
    { bookingId },
    body
  );
};

export const getBookingPaymentStatus = async (bookingId: string) => {
  const api = (await getApiInstance()).agentApi;
  const response = await api.Payments_GetBookingPaymentStatus({ bookingId });

  return response.data.status;
};
