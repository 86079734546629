import { useBooking } from '@/hooks/useBooking';
import type { ProductRequirements } from '@/types/fieldRequirements';
import { tenantFieldRequirementsMap } from '@/utils/fieldRequirements';
import { isTicketBooking } from '@/utils/booking';
import { Flow } from '@/types/booking';
import { useSelector } from '@/store/utils';
import { tenantSelector } from '@/features/configuration/configurationSelector';

export const useRequiredFormFields = (): ProductRequirements => {
  const booking = useBooking(Flow.purchase);
  const tenant = useSelector(tenantSelector);
  const productType = isTicketBooking(booking) ? 'ticket' : 'travelPass';

  return tenantFieldRequirementsMap[tenant.value][productType];
};
