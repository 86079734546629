import { type FC, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAvailableAncillaries } from '@/hooks/useAvailableAncillaries';
import type { AncillaryValues, CheckoutValues } from '@/utils/zodSchema';
import { Typography } from '@/components/primitives/Typography';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import type { AdditionalOfferItem } from '@/types/offer';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import PassengerOptions from '@/components/purchase/checkout/ancillaries/ancillary-modal/PassengerOptions';
import ShowMoreButton from '@/components/ShowMoreButton';
import { Checkbox } from '@/components/primitives/Checkbox';
import { useTranslation } from '@/i18n';

interface AncillaryOptionsProps {
  passengers: CheckoutValues['passengers'];
}

const AncillaryOptions: FC<AncillaryOptionsProps> = ({ passengers }) => {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<AncillaryValues>();
  const selectedLegId = watch('selectedLegId');
  const selectedAncillaries = watch('selectedAncillaries');

  const currency = useSelector(currencySelector);
  const availableAncillaries = useAvailableAncillaries(selectedLegId);
  const [numberOfAncillariesVisible, setNumberOfAncillariesVisible] =
    useState(5);
  const visibleAncillaries = useMemo(
    () => availableAncillaries.slice(0, numberOfAncillariesVisible),
    [availableAncillaries, numberOfAncillariesVisible]
  );

  const handleAncillaryToggle = useCallback(
    (ancillary: AdditionalOfferItem, checked: boolean) => {
      if (!ancillary.id) {
        return;
      }

      if (checked) {
        setValue(`selectedAncillaries`, [
          ...selectedAncillaries,
          {
            id: ancillary.id,
            additionalOfferId: ancillary.additionalOfferId,
            bookedOfferId: ancillary.bookedOfferId,
            amount: ancillary.price?.amount || 0,
            passengersExternalReferences: passengers.map(
              ({ externalReference }) => externalReference
            ),
          },
        ]);
      } else {
        const filteredAncillaries = selectedAncillaries.filter(
          (selected) => selected?.id !== ancillary.id
        );

        setValue('selectedAncillaries', filteredAncillaries);
      }
    },
    [passengers, selectedAncillaries, setValue]
  );

  return (
    <div className="flex flex-col gap-2" data-testid="ancillary-options-list">
      {!!visibleAncillaries.length && (
        <>
          {visibleAncillaries.map((ancillary, index) => {
            const isActive = selectedAncillaries.some(
              (selectedAncillary) => selectedAncillary?.id === ancillary.id
            );

            return (
              <div
                className="flex flex-col gap-2"
                key={ancillary.id}
                data-testid={`ancillary-option-${index}`}
              >
                <ToggleableRow
                  isActive={isActive}
                  onClick={() => handleAncillaryToggle(ancillary, !isActive)}
                >
                  <div className="flex w-full items-center gap-2">
                    <Checkbox
                      checked={isActive}
                      className="h-4 w-4 flex-shrink-0"
                      aria-label={t('text.selectAncillary')}
                      onCheckedChange={(checked) =>
                        handleAncillaryToggle(ancillary, checked === true)
                      }
                      data-testid={`ancillary-option-${index}-toggle`}
                    />
                    <div className="flex w-full justify-between">
                      <Typography variant="body1-bold">
                        {ancillary.products?.[0]?.description}
                      </Typography>
                      <Typography variant="body2-bold" className="text-primary">
                        {ancillary.price?.amount}
                        {currency.symbol}
                      </Typography>
                    </div>
                  </div>
                </ToggleableRow>
                {isActive ? (
                  <PassengerOptions
                    ancillaryId={ancillary.id!}
                    passengers={passengers}
                  />
                ) : null}
              </div>
            );
          })}
          {availableAncillaries.length > 5 && (
            <ShowMoreButton
              expanded={numberOfAncillariesVisible > 5}
              onShowLessClick={() => setNumberOfAncillariesVisible(5)}
              onShowMoreClick={() =>
                setNumberOfAncillariesVisible(availableAncillaries.length)
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default AncillaryOptions;
