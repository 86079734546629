import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import type { LegDTO } from '@/types/dto';
import { useFormContext } from 'react-hook-form';
import type { AncillaryValues } from '@/utils/zodSchema';
import { FormControl, FormField, FormItem } from '@/components/primitives/Form';
import { TransText } from '@/i18n/trans/text';
import { format, formatTime } from '@/utils/date-time';
import { Radio } from '@/components/primitives/Radio';
import _kebabCase from 'lodash/kebabCase';
import { useTranslation } from '@/i18n';

interface LegOptionProps {
  leg: LegDTO;
}

const LegOption: FC<LegOptionProps> = ({ leg }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<AncillaryValues>();
  const activeLegId = watch('selectedLegId');

  const isActive = leg.id === activeLegId;
  return (
    <FormField
      name="selectedLegId"
      key={leg.id}
      render={({ field }) => (
        <FormItem>
          <FormControl hideLabel label={<TransText i18nKey="selectLeg" />}>
            <ToggleableRow
              isActive={isActive}
              onClick={() => {
                setValue(field.name, leg.id!);
                setValue('selectedAncillaries', []);
              }}
            >
              <Radio
                value={leg.id!}
                id={leg.id!}
                className="h-5 w-5 self-start accent-primary"
                checked={isActive}
                readOnly
                aria-label={t('text.selectLeg')}
                data-testid={`${_kebabCase(leg.originStop?.name)}-${_kebabCase(leg.destinationStop?.name)}-toggle`}
              />
              <div className="flex w-full flex-col">
                <Typography variant="body1-bold">
                  {format(leg.departureTime!, 'EEE, dd MMM yyyy')}
                </Typography>
                <div className="mt-2 flex">
                  <div className="flex flex-col items-center justify-between px-1 py-0.5">
                    <div className="flex h-5 w-5 items-center justify-center">
                      <Icons.bus
                        width={16}
                        height={16}
                        className="text-primary"
                      />
                    </div>
                    <div className="h-full w-0.5 bg-neutral-light" />
                    <div className="flex h-3 w-3 items-center justify-center">
                      <Icons.destination className="text-primary" />
                    </div>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="flex flex-col justify-between gap-1.5">
                      <Typography variant="body2">
                        {leg.originStop?.name}
                      </Typography>
                      <Typography variant="body2">
                        {leg.destinationStop?.name}
                      </Typography>
                    </div>
                    <div className="flex flex-col justify-between gap-1.5">
                      <Typography variant="body2" className="text-neutral">
                        {formatTime(leg.departureTime)}
                      </Typography>
                      <Typography variant="body2" className="text-neutral">
                        {formatTime(leg.arrivalTime)}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </ToggleableRow>
          </FormControl>
        </FormItem>
      )}
    />
  );
};

export default LegOption;
