import { type FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/primitives/Dialog';
import { TransText } from '@/i18n/trans/text';
import type { PayWithAdyenResponseDTO } from '@/types/dto';
import {
  AdyenCheckout,
  Dropin,
  type CoreConfiguration,
} from '@adyen/adyen-web/auto';
import '@adyen/adyen-web/styles/adyen.css';
import '@/styles/adyen-override.css';
import { getBookingPaymentStatus } from '@/features/payment/paymentService';
import { TransAlert } from '@/i18n/trans/alert';
import { toast } from 'react-toastify';
import { Typography } from '@/components/primitives/Typography';
import { ScrollArea } from '@/components/primitives/ScrollArea';
import { getAdyenLocale } from '@/i18n';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPaymentSuccess: () => void;
  paymentSession: PayWithAdyenResponseDTO | null;
  bookingId?: string;
  isRedirectReturn?: boolean;
}

const PaymentModal: FC<PaymentModalProps> = ({
  isOpen,
  onClose,
  onPaymentSuccess,
  paymentSession,
  bookingId,
  isRedirectReturn = false,
}) => {
  const dropinRef = useRef<HTMLDivElement>(null);
  const dropinInstance = useRef<Dropin | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const pollingIntervalRef = useRef<NodeJS.Timeout>();

  const handleClose = useCallback(() => {
    if (!isProcessing) {
      onClose();
    }
  }, [isProcessing, onClose]);

  const startPollingPaymentStatus = useCallback(async () => {
    if (!bookingId) return;

    const checkStatus = async () => {
      try {
        const status = await getBookingPaymentStatus(bookingId);

        if (status === 'paid') {
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
          }
          setIsProcessing(false);
          onPaymentSuccess();
        } else if (status === 'not_paid') {
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
          }
          setIsProcessing(false);
          toast.error(<TransAlert i18nKey="paymentFailed" />);
          onClose();
        }
      } catch (error) {
        toast.error(<TransAlert i18nKey="paymentFailed" />);
        if (pollingIntervalRef.current) {
          clearInterval(pollingIntervalRef.current);
        }
        setIsProcessing(false);
        onClose();
      }
    };

    setIsProcessing(true);
    if (dropinRef.current) {
      dropinRef.current.style.display = 'none';
    }
    await checkStatus();
    pollingIntervalRef.current = setInterval(checkStatus, 2000);
  }, [bookingId, onPaymentSuccess, onClose]);

  const loadAdyen = useCallback(async () => {
    try {
      if (!paymentSession?.session || !paymentSession?.clientKey) {
        console.error('Missing payment session data');
        return;
      }

      const options: CoreConfiguration = {
        locale: getAdyenLocale(),
        clientKey: paymentSession.clientKey,
        environment: paymentSession.isTestEnvironment ? 'test' : 'live',
        session: paymentSession.session,
        onError() {
          toast.error(<TransAlert i18nKey="paymentFailed" />);
          onClose();
        },
        onPaymentCompleted() {
          void startPollingPaymentStatus();
        },
        onPaymentFailed() {
          toast.error(<TransAlert i18nKey="paymentFailed" />);
          onClose();
        },
      };

      const checkout = await AdyenCheckout(options);

      if (dropinRef.current) {
        const dropin = new Dropin(checkout);
        dropinInstance.current = dropin;
        await dropin.mount(dropinRef.current);
      } else {
        console.error('Dropin ref is not available');
      }
    } catch (error) {
      console.error('Error initializing Adyen:', error);
    }
  }, [
    onClose,
    paymentSession?.clientKey,
    paymentSession?.isTestEnvironment,
    paymentSession?.session,
    startPollingPaymentStatus,
  ]);

  useEffect(() => {
    if (isOpen) {
      if (isRedirectReturn) {
        void startPollingPaymentStatus();
      } else if (paymentSession) {
        void loadAdyen();
      }
    }

    return () => {
      if (dropinInstance.current) {
        dropinInstance.current.unmount();
        dropinInstance.current = null;
      }
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
    };
  }, [
    isOpen,
    loadAdyen,
    paymentSession,
    isRedirectReturn,
    startPollingPaymentStatus,
  ]);

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent
        onInteractOutside={
          isProcessing ? (event) => event.preventDefault() : undefined
        }
        onEscapeKeyDown={
          isProcessing ? (event) => event.preventDefault() : undefined
        }
      >
        <DialogHeader showClose={!isProcessing}>
          <DialogTitle>
            <TransText i18nKey="payment" />
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[calc(600px)]">
          <div className="relative min-h-[550px] p-6">
            {!isRedirectReturn && <div ref={dropinRef} id="dropin" />}
            {(isProcessing || isRedirectReturn) && (
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-white">
                <div className="h-8 w-8 animate-spin rounded-full border-4 border-primary border-t-transparent" />
                <Typography
                  variant="paragraph"
                  className="mt-4 text-center text-dark"
                >
                  <TransText i18nKey="yourPaymentIsBeingProcessedPleaseWait" />
                </Typography>
              </div>
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
