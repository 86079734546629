import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { useDispatch, useSelector } from '@/store/utils';
import { CardPrimitiveRoot } from '@/components/primitives/Card';
import { Typography } from '@/components/primitives/Typography';
import {
  clearPurchaseFlowBooking,
  getPurchaseFlowBookingAdditionalOffersSearch,
  resetPurchase,
  updatePurchaseFlowBookingPassengers,
  updatePurchaseFlowBookingPurchaser,
  payWithExternalPayment,
} from '@/features/purchase/purchaseActions';
import type { TransTextKeys } from '@/i18n/trans/text';
import { TransText } from '@/i18n/trans/text';
import AncillaryList from '@/components/purchase/checkout/ancillaries/AncillaryList';
import Purchaser from '@/components/purchase/checkout/purchaser/Purchaser';
import { Button } from '@/components/primitives/Button';
import BookingExpirationTimer from '@/components/purchase/checkout/expiration-timer/BookingExpirationTimer';
import { Icons } from '@/components/icons';
import PassengerList from '@/components/purchase/checkout/passengers/PassengerList';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { type CheckoutValues, createCheckoutSchema } from '@/utils/zodSchema';
import Footer from '@/components/Footer';
import { Loadable } from '@/components/Loadable';
import { checkoutLoadingSelector } from '@/features/loading/loadingSelectors';
import { toast } from 'react-toastify';
import { TransAlert } from '@/i18n/trans/alert';
import type { PassengerDTO, PayWithAdyenResponseDTO } from '@/types/dto';
import useBookingSummaryData from '@/hooks/useBookingSummaryData';
import { useNavigate, useSearchParams } from 'react-router-dom';
import RegularBookingSummary from '@/components/booking-summary/RegularBookingSummary';
import Consents from '@/components/Consents';
import { persistor } from '@/store';
import {
  purchaseFlowBookingPassengersSelector,
  purchaseFlowBookingSelector,
} from '@/features/purchase/purchaseSelectors';
import { Flow } from '@/types/booking';
import {
  consentsSelector,
  countryCodeSelector,
  currencySelector,
} from '@/features/configuration/configurationSelector';
import MobileBookingSummary from '@/components/booking-summary/MobileBookingSummary';
import { STORAGE_KEYS, useStorage } from '@/hooks/useStorage';
import type { PurchaseFlowBookingStorageData } from '@/types/storage';
import { getFulfillmentIds } from '@/utils/admission';
import CancelPurchaseButton from '@/components/purchase/checkout/cancel-purchase/CancelPurchaseButton';
import { useTranslation } from '@/i18n';
import { Form } from '@/components/primitives/Form';
import Divider from '@/components/primitives/Divider';
import { useRequiredFormFields } from '@/hooks/useRequiredFormFields';
import { useBookingNonTripOfferIssuers } from '@/hooks/useBookingNonTripOfferIssuers';
import PaymentModal from '@/components/purchase/checkout/payment-modal/PaymentModal';
import { initiateAdyenCheckout } from '@/features/payment/paymentService';
import { getLocalIpAddress } from '@/utils/location';
import { validateAdyenCheckoutPaymentDetails } from '@/features/payment/paymentService';
import { isLocalDevelopment } from '@/utils/isLocalDev';
import { isTicketBooking } from '@/utils/booking';

const Checkout = () => {
  const dispatch = useDispatch();
  const loading = useSelector(checkoutLoadingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });
  const booking = useSelector(purchaseFlowBookingSelector);
  const passengers = useSelector(purchaseFlowBookingPassengersSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currency = useSelector(currencySelector);
  const consents = useSelector(consentsSelector);
  const requirements = useRequiredFormFields();
  const { setValue: setCheckoutSuccessSessionStorage } =
    useStorage<PurchaseFlowBookingStorageData>(
      sessionStorage,
      STORAGE_KEYS.CHECKOUT_SUCCESS_DATA
    );
  const [paymentSession, setPaymentSession] =
    useState<PayWithAdyenResponseDTO | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [searchParams] = useSearchParams();
  const redirectResult = searchParams.get('redirectResult');
  const [isRedirectReturn, setIsRedirectReturn] = useState(false);
  const countryCode = useSelector(countryCodeSelector);

  useEffect(() => {
    if (booking?.id) {
      dispatch(getPurchaseFlowBookingAdditionalOffersSearch(booking.id));
    }
  }, [booking?.id, dispatch]);

  useBookingNonTripOfferIssuers(Flow.purchase);

  const form = useForm<CheckoutValues>({
    mode: 'onBlur',
    resolver: zodResolver(createCheckoutSchema(t, requirements)),
    defaultValues: {
      passengers: passengers?.map(
        ({
          id,
          externalReference,
          firstName,
          lastName,
          contactInformation,
          age,
        }) => ({
          id,
          externalReference,
          firstName: firstName?.value || '',
          lastName: lastName?.value || '',
          email: contactInformation?.emailAddress?.value || '',
          phone: { number: contactInformation?.phoneNumber?.value || '' },
          age: age || null,
        })
      ),
      purchaser: {
        firstName: '',
        lastName: '',
        email: '',
        phone: { number: '' },
      },
      consents:
        consents?.items?.map(({ id, isMandatory, description, code }) => ({
          isMandatory,
          description,
          checked: false,
          id,
          code,
        })) ?? [],
    },
  });
  const { trigger, getValues } = form;

  const sections: Array<{
    paragraphKey?: TransTextKeys;
    section: ReactNode;
  }> = useMemo(
    () => [
      {
        paragraphKey: 'passengers',
        section: <PassengerList />,
      },
      ...(isTicketBooking(booking)
        ? [
            {
              paragraphKey: 'extras',
              section: <AncillaryList />,
            } as const,
          ]
        : []),
      {
        paragraphKey: 'payer',
        section: <Purchaser />,
      },
      {
        section: <Consents />,
      },
    ],
    [booking]
  );

  const handleClosePaymentModal = useCallback(() => {
    setShowPaymentModal(false);
    setPaymentSession(null);
    setIsRedirectReturn(false);
  }, []);

  const handlePaymentSuccess = useCallback(async () => {
    if (!booking?.id || !booking.code) return;

    try {
      setCheckoutSuccessSessionStorage({
        bookingId: booking.id,
        code: booking.code,
        payerEmail: getValues().purchaser.email,
        fulfillmentIds: getFulfillmentIds(booking),
      });

      dispatch(clearPurchaseFlowBooking());
      dispatch(resetPurchase());
      persistor.purge();

      navigate('/success');
    } catch (e) {
      console.error(e);
      toast.error(<TransAlert i18nKey="paymentFailed" />);
    }
  }, [
    booking,
    dispatch,
    getValues,
    navigate,
    setCheckoutSuccessSessionStorage,
  ]);

  const parsePassengerValuesToPassengerDto = useCallback(
    (): Array<PassengerDTO> =>
      getValues().passengers.map(
        ({ age, firstName, lastName, email, phone, ...rest }) => ({
          ...rest,
          age: age ?? undefined,
          firstName: firstName
            ? { value: firstName, isRequired: false }
            : undefined,
          lastName: lastName
            ? { value: lastName, isRequired: false }
            : undefined,
          contactInformation:
            !email && !phone.number
              ? undefined
              : {
                  isRequired: false,
                  ...(email && {
                    emailAddress: { isRequired: false, value: email },
                  }),
                  ...(phone.number && {
                    phoneNumber: {
                      isRequired: false,
                      value: phone.number,
                    },
                  }),
                },
          isCardsRequired: false,
        })
      ),
    [getValues]
  );

  const summaryData = useBookingSummaryData(
    booking && {
      ...booking,
      passengers: parsePassengerValuesToPassengerDto(),
    },
    ['PREBOOKED', 'CONFIRMED', 'FULFILLED']
  );

  useEffect(() => {
    const validatePayment = async () => {
      if (!booking?.id) return;

      const threeDsResult = searchParams.get('threeDsResult') || undefined;
      const stateData = searchParams.get('stateData') ? null : undefined;

      if (!redirectResult && !threeDsResult && !stateData) {
        return;
      }

      try {
        await validateAdyenCheckoutPaymentDetails(booking?.id, {
          ...(redirectResult && { redirectResult }),
          ...(threeDsResult && { threeDsResult }),
          ...(stateData !== undefined && { stateData }),
        });
        setIsRedirectReturn(true);
        setShowPaymentModal(true);
      } catch (error) {
        console.error('Payment validation error:', error);
        toast.error(<TransAlert i18nKey="paymentFailed" />);
      }
    };

    validatePayment();
  }, [redirectResult, searchParams, booking?.id]);

  const handleValidations = useCallback(async () => {
    const passengersValid = await trigger('passengers');
    const purchaserValid = await trigger('purchaser');
    const consentsAgreedWith = await trigger('consents');

    if (!passengersValid || !purchaserValid) {
      toast.error(<TransAlert i18nKey="mandatoryFieldsMustBeFilled" />);
      return false;
    }

    if (!consentsAgreedWith) {
      toast.error(<TransAlert i18nKey="consentsNotAgreedWith" />);
      return false;
    }

    return true;
  }, [trigger]);

  const handleSubmit = useCallback(async () => {
    if (!booking?.id) {
      return;
    }

    const isValid = await handleValidations();

    if (!isValid) {
      return;
    }

    const { purchaser, passengers } = getValues();

    try {
      await dispatch(updatePurchaseFlowBookingPassengers(passengers)).unwrap();
      await dispatch(updatePurchaseFlowBookingPurchaser(purchaser)).unwrap();

      const { data } = await initiateAdyenCheckout(booking.id, {
        returnUrl: window.location.href,
        countryCode,
        shopperIp: await getLocalIpAddress(),
        firstName: purchaser.firstName || '',
        lastName: purchaser.lastName || '',
        email: purchaser.email || '',
        phoneNumber: purchaser.phone.number || '',
      });

      setPaymentSession(data);
      setShowPaymentModal(true);
    } catch (e) {
      console.error(e);
      toast.error(<TransAlert i18nKey="paymentFailed" />);
    }
  }, [booking?.id, countryCode, dispatch, getValues, handleValidations]);

  const handleExternalPayment = useCallback(async () => {
    if (!booking?.id) {
      return;
    }

    const isValid = await handleValidations();

    if (!isValid) {
      return;
    }

    const { purchaser, passengers } = getValues();

    try {
      await dispatch(updatePurchaseFlowBookingPassengers(passengers)).unwrap();
      await dispatch(updatePurchaseFlowBookingPurchaser(purchaser)).unwrap();

      await dispatch(
        payWithExternalPayment({
          paidAmount: {
            amount: booking.provisionalPrice?.amount || 0,
            currency: currency.name || '',
          },
          transactionId: 'dev-transaction',
          typeId: 'SALES_POINT_PAYMENT_TYPE.EXTERNAL_3RD_PARTY',
        })
      ).unwrap();

      handlePaymentSuccess();
    } catch (e) {
      console.error(e);
      toast.error(<TransAlert i18nKey="paymentFailed" />);
    }
  }, [
    booking?.id,
    booking?.provisionalPrice?.amount,
    currency.name,
    dispatch,
    getValues,
    handlePaymentSuccess,
    handleValidations,
  ]);

  return (
    <Form {...form}>
      <div className="flex items-start gap-8" data-testid="checkout-view">
        <div className="flex w-full flex-1 flex-col gap-4">
          <div className="sticky top-0 z-10 flex justify-between bg-background py-2">
            <Typography variant="heading1" asChild>
              <h1>
                <TransText i18nKey="checkout" />
              </h1>
            </Typography>
            {!isLaptopOrBigger && <BookingExpirationTimer />}
          </div>
          <Loadable loading={loading} overlay>
            {sections.map(({ paragraphKey, section }, key) => (
              <CardPrimitiveRoot key={key}>
                <div className="flex flex-col gap-3 p-4">
                  {paragraphKey && (
                    <>
                      <Typography
                        variant="subtitle"
                        className="flex gap-1 text-dark"
                        asChild
                      >
                        <h2>
                          <span>{key + 1}.</span>
                          <TransText i18nKey={paragraphKey} />
                        </h2>
                      </Typography>
                      <Divider variant="medium" />
                    </>
                  )}
                  {section}
                </div>
              </CardPrimitiveRoot>
            ))}
          </Loadable>
        </div>

        {isLaptopOrBigger && (
          <div className="flex flex-col gap-4">
            <div className="sticky top-0 z-10 flex justify-between bg-background py-2">
              <Typography variant="heading2" asChild>
                <h1>
                  <TransText i18nKey="bookingSummary" />
                </h1>
              </Typography>
              <BookingExpirationTimer />
            </div>
            <RegularBookingSummary {...summaryData} flow={Flow.purchase} />
          </div>
        )}
      </div>
      <Footer
        content={
          !isLaptopOrBigger && (
            <MobileBookingSummary {...summaryData} flow={Flow.purchase} />
          )
        }
        actionButtons={
          <div className="flex w-full flex-col justify-between laptop:flex-row laptop:items-center">
            {isLaptopOrBigger && (
              <div className="flex items-center gap-2">
                <Icons.travelPass
                  height={16}
                  width={16}
                  className="text-primary"
                />
                <Typography variant="subtitle" className="text-dark">
                  <TransText i18nKey="bookingTotal" />:
                </Typography>
                <Typography variant="subtitle">
                  {booking?.provisionalPrice?.amount}
                  {currency.symbol}
                </Typography>
              </div>
            )}
            <div className="flex justify-end gap-2">
              <CancelPurchaseButton />
              {isLocalDevelopment() && (
                <Button
                  size="large"
                  data-testid="confirm-purchase-button-external"
                  onClick={handleExternalPayment}
                  variant="cta"
                  className="gap-1.5 rounded-lg laptop:h-11 laptop:w-auto"
                >
                  <Typography
                    variant={isLaptopOrBigger ? 'body1-bold' : 'subtitle'}
                  >
                    Pay (Dev)
                  </Typography>
                </Button>
              )}
              <Button
                size="large"
                fullWidth
                data-testid="confirm-purchase-button"
                className="gap-1.5 rounded-lg laptop:h-11 laptop:w-auto"
                onClick={handleSubmit}
                aria-label="Pay button"
                variant="cta"
              >
                <Icons.lock />
                <Typography
                  variant={isLaptopOrBigger ? 'body1-bold' : 'subtitle'}
                  className="flex gap-1"
                >
                  <TransText i18nKey="pay" />
                  {isLaptopOrBigger && (
                    <>
                      <span>({booking?.provisionalPrice?.amount}</span>
                      <span>{currency.symbol})</span>
                    </>
                  )}
                </Typography>
              </Button>
            </div>
          </div>
        }
      />
      <PaymentModal
        isOpen={showPaymentModal}
        onClose={handleClosePaymentModal}
        onPaymentSuccess={handlePaymentSuccess}
        paymentSession={paymentSession}
        bookingId={booking?.id}
        isRedirectReturn={isRedirectReturn}
      />
    </Form>
  );
};

export default Checkout;
