import type { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

export interface LoadableProps {
  loading: boolean;
  children: ReactNode;
  overlay?: boolean;
}

export const Loadable: FC<LoadableProps> = ({ loading, children, overlay }) =>
  loading ? (
    overlay ? (
      <>
        {createPortal(
          <>
            <div className="pointer-events-auto fixed inset-0 z-[51] flex items-center justify-center">
              <Spinner />
            </div>
            <div className="pointer-events-auto fixed inset-0 z-[51] bg-gray-500 opacity-50" />
          </>,
          document.body
        )}
        {children}
      </>
    ) : (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    )
  ) : (
    children
  );

const Spinner = () => (
  <div className="h-16 w-16 animate-spin rounded-full border-t-4 border-primary" />
);
