import type { BookedAncillaryDTO } from '@/types/dto';
import type { FC, ReactNode } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { useBookingPassengerIdsByAncillaryId } from '@/hooks/useBookingPassengerIdsByAncillaryId';
import { useFormContext } from 'react-hook-form';
import type { CheckoutValues } from '@/utils/zodSchema';
import { TransText } from '@/i18n/trans/text';
import { getPassengerName } from '@/utils/passenger';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';

interface AncillaryRowProps {
  ancillary: BookedAncillaryDTO;
  icon: ReactNode;
  handleRemoveAncillary: (ancillary: BookedAncillaryDTO) => void;
}

const AncillaryRow: FC<AncillaryRowProps> = ({
  ancillary,
  icon,
  handleRemoveAncillary,
}) => {
  const currency = useSelector(currencySelector);
  const booking = useSelector(purchaseFlowBookingSelector);
  const passengerIdsByAncillaryId =
    useBookingPassengerIdsByAncillaryId(booking);
  const { watch } = useFormContext<CheckoutValues>();
  const passengers = watch('passengers');
  const passengerIds = ancillary.id
    ? passengerIdsByAncillaryId[ancillary.id]
    : [];
  // We are accessing the first element of passengerIds array because generally it contains only single ID per admission.
  // The only admission that could have more than one ID in the array is "compartment" which means that whole room in a vehicle is bought.
  // Since Arenaways wont be using "compartment", we will tackle this part with a separate task.
  const currentPassengerIndex = passengers.findIndex(
    ({ id }) => id === passengerIds[0]
  );

  return (
    <div className="grid grid-cols-[auto,1fr,auto] text-dark tablet:pr-2">
      <div className="flex items-center">{icon}</div>
      <div className="flex items-center gap-2 px-1">
        <Typography variant="body1-bold">
          {ancillary.productDescription}
        </Typography>
        <Typography variant="body2-bold" className="text-primary">
          {ancillary.price?.amount}
          {currency.symbol}
        </Typography>
      </div>
      <Button
        data-testid="ancillary-remove-button"
        variant="ghost"
        attention
        className="h-auto tablet:px-1 tablet:py-0.5"
        onClick={() => handleRemoveAncillary(ancillary)}
      >
        <Icons.removeCircle height={16} width={16} />
        <Typography variant="button">
          <TransText i18nKey="remove" />
        </Typography>
      </Button>
      <div />
      <Typography variant="body2" className="px-1 text-neutral-light">
        {getPassengerName(
          passengers[currentPassengerIndex],
          currentPassengerIndex
        )}
      </Typography>
    </div>
  );
};

export default AncillaryRow;
