import { useCallback } from 'react';
import { downloadTicketDocumentByType } from '@/features/purchase/purchaseService';
import { toast } from 'react-toastify';
import { TransText } from '@/i18n/trans/text';

interface UsePkPassDownloadProps {
  fulfillmentIds?: string[];
  onStart?: () => void;
  onFinish?: () => void;
  bookingNumber?: string;
}

export const usePkPassDownload = ({
  fulfillmentIds,
  onStart,
  onFinish,
  bookingNumber,
}: UsePkPassDownloadProps) => {
  return useCallback(async () => {
    if (!fulfillmentIds?.length) {
      return;
    }

    try {
      onStart?.();
      await Promise.all(
        fulfillmentIds.map(async (id) => {
          try {
            await downloadTicketDocumentByType(id, 'PKPASS', bookingNumber);
          } catch (error) {
            toast.error(<TransText i18nKey="errorDownloadingPkpass" />);
          }
        })
      );
    } catch (error) {
      toast.error(<TransText i18nKey="errorDownloadingPkpass" />);
    } finally {
      onFinish?.();
    }
  }, [fulfillmentIds, onStart, bookingNumber, onFinish]);
};
