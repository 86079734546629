import { createReducer } from '@reduxjs/toolkit';
import {
  clearPurchaseFlowBooking,
  getPurchaseFlowBookingAdditionalOffersSearch,
  getInboundJourneys,
  getNonTripOffers,
  getOutboundJourneys,
  getPurchaseFlowBookingById,
  resetPurchase,
  setActiveStep,
  setSearchFormValues,
  setSelectedInboundJourney,
  setSelectedInboundOfferMap,
  setSelectedOutboundJourney,
  setSelectedOutboundOfferMapByLegId,
  updateSelectedInboundOfferMapByLegId,
  updateSelectedNonTripOffer,
  updateSelectedOutboundOfferMapByLegId,
} from '@/features/purchase/purchaseActions';
import { STEP } from '@/utils/purchase';
import type {
  BookingDTO,
  JourneyDTO,
  NonTripOfferDTO,
  SearchNonTripOffersDTO,
  SearchOffersDTO,
} from '@/types/dto';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import type {
  AdditionalOffersCollection,
  OfferMapByLegId,
} from '@/types/offer';

type SearchOffers = SearchOffersDTO & {
  selectedJourney?: JourneyDTO;
  selectedOfferMapByLegId: OfferMapByLegId;
  additionalOffers: Array<AdditionalOffersCollection>;
};

type SearchNonTripOffers = SearchNonTripOffersDTO & {
  selectedNonTripOffer?: NonTripOfferDTO;
};

type PurchaseState = {
  activeStep: STEP;
  outbound: SearchOffers;
  inbound: SearchOffers;
  nonTripOffers: SearchNonTripOffers;
  searchFormValues?: SearchFormValues;
  booking: BookingDTO | null;
};

const initialState: PurchaseState = {
  activeStep: STEP.PreSearchSubmit,
  outbound: {
    selectedOfferMapByLegId: {},
    additionalOffers: [],
  },
  inbound: {
    selectedOfferMapByLegId: {},
    additionalOffers: [],
  },
  nonTripOffers: {},
  booking: null,
};

export const purchaseReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getOutboundJourneys.fulfilled, (state, action) => {
      state.outbound = { ...state.outbound, ...action.payload };
    })
    .addCase(getInboundJourneys.fulfilled, (state, action) => {
      state.inbound = { ...state.inbound, ...action.payload };
    })
    .addCase(getNonTripOffers.fulfilled, (state, action) => {
      state.nonTripOffers = { ...state.nonTripOffers, ...action.payload };
    })
    .addCase(setActiveStep, (state, action) => {
      state.activeStep = action.payload;
    })
    .addCase(setSelectedOutboundJourney, (state, action) => {
      state.outbound.selectedJourney = action.payload;
    })
    .addCase(setSelectedOutboundOfferMapByLegId, (state, action) => {
      state.outbound.selectedOfferMapByLegId = action.payload;
    })
    .addCase(updateSelectedOutboundOfferMapByLegId, (state, action) => {
      state.outbound.selectedOfferMapByLegId[action.payload.legId] =
        action.payload.offer;
    })
    .addCase(updateSelectedNonTripOffer, (state, action) => {
      state.nonTripOffers.selectedNonTripOffer = action.payload;
    })
    .addCase(setSelectedInboundJourney, (state, action) => {
      state.inbound.selectedJourney = action.payload;
    })
    .addCase(setSelectedInboundOfferMap, (state, action) => {
      state.inbound.selectedOfferMapByLegId = action.payload;
    })
    .addCase(updateSelectedInboundOfferMapByLegId, (state, action) => {
      state.inbound.selectedOfferMapByLegId[action.payload.legId] =
        action.payload.offer;
    })
    .addCase(resetPurchase, (state, action) => {
      Object.assign(state, {
        ...initialState,
        activeStep: action.payload?.startStep ?? initialState.activeStep,
      });
    })
    .addCase(
      getPurchaseFlowBookingAdditionalOffersSearch.fulfilled,
      (state, action) => {
        state.outbound.additionalOffers =
          action.payload.outboundAdditionalOffers;
        state.inbound.additionalOffers = action.payload.inboundAdditionalOffers;
      }
    )
    .addCase(setSearchFormValues, (state, action) => {
      state.searchFormValues = action.payload;
    })
    .addCase(getPurchaseFlowBookingById.fulfilled, (state, action) => {
      state.booking = action.payload;
    })
    .addCase(clearPurchaseFlowBooking, (state) => {
      state.booking = null;
    });
});
