import { type FC, useCallback } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';
import { deletePurchaseFlowBooking } from '@/features/purchase/purchaseActions';
import { TransAlert } from '@/i18n/trans/alert';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from '@/store/utils';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';
import { persistor } from '@/store';
import ConfirmModal from '@/components/ConfirmModal';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';

const CancelPurchaseButton: FC = () => {
  const dispatch = useDispatch();
  const booking = useSelector(purchaseFlowBookingSelector);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const handleCancelPurchase = useCallback(async () => {
    if (!booking?.id) {
      return;
    }

    try {
      await dispatch(deletePurchaseFlowBooking(booking.id)).unwrap();
      persistor.purge();
    } catch {
      toast.error(<TransAlert i18nKey="purchaseCancellationFailed" />);
    }
  }, [booking?.id, dispatch]);

  return (
    <ConfirmModal
      title={<TransText i18nKey="cancelPurchase" />}
      description={
        <Typography variant="paragraph" className="text-dark">
          <TransText i18nKey="areYouSureYouWantToCancelThePurchase" />
        </Typography>
      }
      cancelLabel="dismiss"
      confirmLabel="confirm"
      onConfirm={handleCancelPurchase}
    >
      <Button
        size="large"
        fullWidth
        data-testid="cancel-purchase-button"
        className="gap-1.5 rounded-lg laptop:h-11 laptop:w-auto"
        variant="tertiary"
      >
        <Icons.cancel />
        <Typography variant={isLaptopOrBigger ? 'button' : 'subtitle'}>
          <TransText i18nKey="cancel" />
        </Typography>
      </Button>
    </ConfirmModal>
  );
};

export default CancelPurchaseButton;
