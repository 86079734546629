import { type FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import TravelPassDetails from '@/components/manage/travel-passes/TravelPassDetails';
import { BackgroundThemeProvider } from '@/context/BackgroundThemeContextProvider';
import { useBookingNonTripOfferDetails } from '@/hooks/useBookingNonTripOfferDetails';
import { Flow } from '@/types/booking';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';

interface TravelPassDetailsProps {}

const TravelPasses: FC<TravelPassDetailsProps> = () => {
  const { travelPass, multiRide } = useBookingNonTripOfferDetails(
    Flow.manageBooking
  );
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  return (
    <div className="flex flex-1 flex-col">
      <Typography
        variant={isLaptopOrBigger ? 'heading2' : 'subtitle'}
        className="mb-7"
        asChild
      >
        <h1>
          <TransText i18nKey="bookedTravelPasses" />
        </h1>
      </Typography>
      <BackgroundThemeProvider backgroundTheme="white">
        <div className="flex flex-col gap-3">
          {[...travelPass, ...multiRide].map((data, key) => (
            <TravelPassDetails data={data} key={key} />
          ))}
        </div>
      </BackgroundThemeProvider>
    </div>
  );
};

export default TravelPasses;
