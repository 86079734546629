import { type FC, useCallback } from 'react';
import { useDispatch, useSelector } from '@/store/utils';
import Divider from '@/components/primitives/Divider';
import LegSection from '@/components/purchase/checkout/ancillaries/LegSection';
import AncillaryModal from '@/components/purchase/checkout/ancillaries/ancillary-modal/AncillaryModal';
import { Typography } from '@/components/primitives/Typography';
import type { BookedAncillaryDTO, LegDTO } from '@/types/dto';
import { useBookingTripLegs } from '@/hooks/useBookingTripLegs';
import { TransText } from '@/i18n/trans/text';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';
import {
  getPurchaseFlowBookingById,
  removePurchaseFlowBookingAncillary,
} from '@/features/purchase/purchaseActions';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';

interface AncillaryListProps {}

const AncillaryList: FC<AncillaryListProps> = () => {
  const dispatch = useDispatch();
  const booking = useSelector(purchaseFlowBookingSelector);
  const { outboundLegs, inboundLegs } = useBookingTripLegs(booking);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const handleRemoveAncillary = useCallback(
    async (ancillary: BookedAncillaryDTO) => {
      if (!ancillary.id || !ancillary.bookedOfferId) {
        return;
      }

      await dispatch(
        removePurchaseFlowBookingAncillary({
          ancillaryId: ancillary.id,
          bookedOfferId: ancillary.bookedOfferId,
        })
      ).unwrap();
      await dispatch(getPurchaseFlowBookingById(booking?.id)).unwrap();
    },
    [booking, dispatch]
  );

  const renderLegWithChosenAncillaries = useCallback(
    (leg: LegDTO, index: number) => (
      <LegSection
        leg={leg}
        handleRemoveAncillary={handleRemoveAncillary}
        key={index}
      />
    ),
    [handleRemoveAncillary]
  );

  return (
    <div className="flex min-w-mobile flex-col gap-3">
      <div className="flex flex-col text-dark laptop:flex-row laptop:gap-3">
        <div className="flex flex-col laptop:min-w-[45%]">
          <Typography variant="body1-bold" className="text-dark" asChild>
            <h3>
              <TransText i18nKey="outboundTrip" />
            </h3>
          </Typography>
          <div className="flex flex-col gap-4">
            {outboundLegs.map(renderLegWithChosenAncillaries)}
          </div>
        </div>
        {inboundLegs.length ? (
          <>
            <Divider
              className="my-3 h-auto max-w-[1px]"
              orientation={isLaptopOrBigger ? 'vertical' : 'horizontal'}
              variant="medium"
            />
            <div className="flex flex-col laptop:min-w-[50%] laptop:pl-6">
              <Typography variant="body1-bold" className="text-dark" asChild>
                <h3>
                  <TransText i18nKey="returnTrip" />
                </h3>
              </Typography>
              <div className="flex flex-col gap-4">
                {inboundLegs.map(renderLegWithChosenAncillaries)}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <AncillaryModal />
    </div>
  );
};

export default AncillaryList;
