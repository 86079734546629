import * as React from 'react';
import { useThemedColors } from '@/hooks/useThemedColors';
import { cn } from '@/utils/cn';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { typographyVariants } from '@/components/primitives/Typography';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary:
          'border border-primary bg-primary text-white hover:border-primary-medium hover:bg-primary-medium active:border-primary-dark active:bg-primary-dark disabled:bg-neutral disabled:text-neutral-light',
        secondary:
          'border border-primary bg-primary-light text-primary hover:bg-white active:bg-primary-pale disabled:bg-neutral-pale disabled:text-neutral',
        destructive:
          'border border-error-border bg-error-surface text-error-text hover:bg-error-surface-hover disabled:bg-neutral-pale disabled:text-neutral',
        tertiary:
          'border border-primary bg-white text-primary hover:bg-primary-pale active:bg-primary-light',
        cta: 'border border-secondary bg-secondary text-secondary-contrast hover:bg-secondary-medium active:bg-secondary-dark disabled:bg-neutral disabled:text-neutral-light',
        ghost: 'gap-1.5',
        icon: 'aspect-square rounded-full p-2',
      },
      size: {
        large: cn(typographyVariants({ variant: 'subtitle' }), 'h-14'),
        medium: cn(typographyVariants({ variant: 'body1-bold' }), 'h-12'),
        regular: cn(typographyVariants({ variant: 'button' }), 'h-10'),
        small: cn(typographyVariants({ variant: 'body2' }), 'h-8'),
      },
      fullWidth: {
        true: 'w-full',
        false: 'w-fit',
      },
      attention: {
        true: null,
        false: null,
      },
      strong: {
        true: typographyVariants({ variant: 'body2-bold' }),
        false: '',
      },
    },
    compoundVariants: [
      {
        variant: ['primary', 'secondary', 'destructive', 'tertiary', 'cta'],
        class:
          '[&:has(>:first-child)]:pl-5 [&:has(>:last-child)]:pr-5 [&:not(:has(>:first-child))]:pl-6 [&:not(:has(>:last-child))]:pr-6',
      },
      {
        variant: ['primary', 'secondary', 'destructive', 'tertiary', 'cta'],
        size: 'small',
        class:
          '[&:has(>:first-child)]:pl-3 [&:has(>:last-child)]:pr-3 [&:not(:has(>:first-child))]:pl-4 [&:not(:has(>:last-child))]:pr-4',
      },
      {
        variant: ['ghost'],
        attention: true,
        class:
          '!text-error-text data-[state=open]:!text-error-text hover:!text-error-border active:!text-error-text',
      },
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'regular',
      fullWidth: false,
      attention: false,
      strong: false,
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'destructive'
    | 'tertiary'
    | 'ghost'
    | 'icon'
    | 'cta';
  size?: 'large' | 'medium' | 'regular' | 'small';
  asChild?: boolean;
  borderless?: boolean;
  fullWidth?: boolean;
  attention?: boolean;
  strong?: boolean;
  ['data-testid']: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'regular',
      asChild = false,
      borderless = false,
      fullWidth = false,
      attention = false,
      strong = false,
      ...props
    },
    ref
  ) => {
    const { getButtonColors } = useThemedColors();
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, fullWidth, attention, strong }),
          variant === 'icon' && getButtonColors(variant),
          variant === 'ghost' && getButtonColors(variant),
          getButtonColors(),
          borderless && 'border-none',
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);

Button.displayName = 'Button';

export { buttonVariants };
