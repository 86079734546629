import { type BookingSummaryData } from '@/hooks/useBookingSummaryData';
import TripDescription from '@/components/booking-summary/TripDescription';
import NonTripOfferDescription from '@/components/booking-summary/NonTripOfferDescription';
import BookingItems from '@/components/booking-summary/BookingItems';
import type { FC } from 'react';
import type { Flow } from '@/types/booking';

interface SummaryContentProps extends BookingSummaryData {
  flow: Flow;
}

const SummaryContent: FC<SummaryContentProps> = ({
  inboundTickets,
  inboundTrips,
  outboundTickets,
  outboundTrips,
  ancillaries,
  nonTripOffers,
  nonTripOfferFees,
  tripOfferFees,
  flow,
}) => {
  return (
    <>
      {!!outboundTrips.length && (
        <TripDescription
          trips={outboundTrips}
          flow={flow}
          direction="outbound"
        />
      )}
      {!!inboundTrips.length && (
        <TripDescription trips={inboundTrips} flow={flow} direction="inbound" />
      )}
      {!!nonTripOffers.length && <NonTripOfferDescription flow={flow} />}
      <BookingItems
        outboundTickets={outboundTickets}
        inboundTickets={inboundTickets}
        ancillaries={ancillaries}
        nonTripOfferFees={nonTripOfferFees}
        nonTripOffers={nonTripOffers}
        tripOfferFees={tripOfferFees}
      />
    </>
  );
};

export default SummaryContent;
