import { manageFlowBookingAdmissionsByIdSelector } from '@/features/manage/manageSelectors';
import { useSelector } from '@/store/utils';
import { Flow } from '@/types/booking';
import { type BookedTripDTO } from '@/types/dto';
import { useMemo } from 'react';

export const useBookingNotExchangedTrips = (
  trips: Array<BookedTripDTO>,
  flow: Flow
) => {
  const admissionsByLegId = useSelector(
    manageFlowBookingAdmissionsByIdSelector
  );

  return useMemo(
    () =>
      flow === Flow.purchase
        ? trips
        : trips.filter((trip) =>
            trip.legs?.every(
              (leg) =>
                leg.id &&
                admissionsByLegId[leg.id]?.some(
                  (admission) => admission.status !== 'EXCHANGED'
                )
            )
          ),
    [admissionsByLegId, flow, trips]
  );
};
