import { isLightBackground } from '@/utils/color';
import { cn } from '@/utils/cn';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';

type Theme = {
  globalStyles: {
    focus: string;
  };
  button: {
    ghost: {
      text: string;
      hover: string;
      active: string;
      open: string;
    };
    icon: {
      text: string;
      hover: string;
      active: string;
    };
  };
  checkbox: {
    border: string;
    hover: string;
    checked: string;
    checkedHover: string;
    disabled: string;
    checkedDisabled: string;
    indeterminate: string;
    indeterminateHover: string;
    indeterminateDisabled: string;
    label: string;
    labelDisabled: string;
  };
  radio: {
    border: string;
    hover: string;
    checked: string;
    disabled: string;
    checkedDisabled: string;
    label: string;
    labelDisabled: string;
  };
  divider: {
    regular: string;
    medium: string;
    strong: string;
  };
  link: {
    text: string;
    hover: string;
    active: string;
  };
};

const lightTheme: Theme = {
  globalStyles: {
    focus: 'focus-visible:outline-tint',
  },
  button: {
    ghost: {
      text: 'text-primary',
      hover: 'hover:text-tint',
      active: 'active:text-dark',
      open: 'data-[state=open]:text-tint',
    },
    icon: {
      text: 'text-primary',
      hover: 'hover:bg-secondary-pale hover:text-primary-dark',
      active: 'active:bg-secondary-light active:text-primary',
    },
  },
  checkbox: {
    border: 'border-neutral',
    hover: 'hover:border-primary-dark hover:bg-secondary-pale',
    checked:
      'data-[state=checked]:bg-secondary data-[state=checked]:text-secondary-contrast',
    checkedHover: 'data-[state=checked]:hover:bg-secondary-medium',
    disabled:
      'disabled:border-neutral-light disabled:bg-greyscale-100 disabled:hover:bg-greyscale-100',
    checkedDisabled:
      'data-[state=checked]:disabled:border-neutral-light data-[state=checked]:disabled:bg-neutral data-[state=checked]:disabled:text-neutral-light',
    indeterminate:
      'data-[state=indeterminate]:bg-secondary data-[state=indeterminate]:text-secondary-contrast',
    indeterminateHover: 'data-[state=indeterminate]:hover:bg-secondary-medium',
    indeterminateDisabled:
      'data-[state=indeterminate]:disabled:border-neutral-light data-[state=indeterminate]:disabled:bg-neutral data-[state=indeterminate]:disabled:text-neutral-light',
    label: 'text-dark',
    labelDisabled: 'peer-disabled:text-neutral',
  },
  radio: {
    border: 'border-neutral',
    hover: 'hover:border-primary-dark hover:bg-secondary-pale',
    checked:
      'checked:border-neutral checked:bg-secondary checked:hover:bg-secondary checked:hover:border-neutral',
    disabled: 'disabled:border-neutral-light disabled:bg-greyscale-100',
    checkedDisabled:
      'checked:disabled:border-neutral-light checked:disabled:bg-neutral',
    label: 'text-dark',
    labelDisabled: 'peer-disabled:text-neutral',
  },
  divider: {
    regular: 'border-neutral-pale',
    medium: 'border-neutral-light',
    strong: 'border-primary',
  },
  link: {
    text: 'text-dark',
    hover: 'hover:text-tint',
    active: 'active:text-dark',
  },
};

const darkTheme: Theme = {
  globalStyles: {
    focus: 'focus-visible:outline-accent',
  },
  button: {
    ghost: {
      text: 'text-white',
      hover: 'hover:text-accent-light',
      active: 'active:text-accent',
      open: 'data-[state=open]:text-accent',
    },
    icon: {
      text: 'text-white',
      hover: 'hover:bg-primary-dark hover:text-accent-light',
      active: 'active:bg-primary active:text-accent',
    },
  },
  checkbox: {
    border: 'border-accent',
    hover: 'hover:border-accent-light hover:bg-primary-dark',
    checked:
      'data-[state=checked]:bg-secondary data-[state=checked]:text-secondary-contrast',
    checkedHover: 'data-[state=checked]:hover:bg-secondary-medium',
    disabled:
      'disabled:border-neutral-medium disabled:bg-dark disabled:hover:bg-dark',
    checkedDisabled:
      'data-[state=checked]:disabled:border-neutral-light data-[state=checked]:disabled:bg-neutral data-[state=checked]:disabled:text-neutral-light',
    indeterminate:
      'data-[state=indeterminate]:bg-accent data-[state=indeterminate]:text-white',
    indeterminateHover: 'data-[state=indeterminate]:hover:bg-accent',
    indeterminateDisabled:
      'data-[state=indeterminate]:disabled:border-neutral-light data-[state=indeterminate]:disabled:bg-neutral data-[state=indeterminate]:disabled:text-neutral-light',
    label: 'text-white hover:text-white',
    labelDisabled: 'peer-disabled:text-neutral-light',
  },
  radio: {
    border: 'border-accent',
    hover:
      'hover:border-accent-light group-hover:border-accent-light hover:bg-primary-dark hover:disabled:border-neutral-medium group-hover:disabled:border-neutral-medium',
    checked: 'checked:border-accent checked:bg-primary hover:bg-primary',
    disabled: 'disabled:border-neutral-medium disabled:bg-dark',
    checkedDisabled:
      'checked:disabled:border-neutral-medium checked:disabled:bg-neutral',
    label: 'text-white hover:text-white',
    labelDisabled: 'peer-disabled:text-neutral-light',
  },
  divider: {
    regular: 'border-neutral',
    medium: 'border-neutral-medium',
    strong: 'border-neutral-pale',
  },
  link: {
    text: 'text-white',
    hover: 'hover:text-accent-light',
    active: 'active:text-accent',
  },
};

export const useThemedColors = () => {
  const backgroundTheme = useBackgroundTheme();
  const isLight = isLightBackground(backgroundTheme);
  const styles = isLight ? lightTheme : darkTheme;

  return {
    getGlobalStyles: () => ({
      focus: styles.globalStyles.focus,
    }),
    getButtonColors: (variant?: 'ghost' | 'icon') => {
      if (variant === 'ghost') {
        return cn(
          styles.button.ghost.text,
          styles.button.ghost.hover,
          styles.button.ghost.active,
          styles.button.ghost.open,
          styles.globalStyles.focus
        );
      }
      if (variant === 'icon') {
        return cn(
          styles.button.icon.text,
          styles.button.icon.hover,
          styles.button.icon.active,
          styles.globalStyles.focus
        );
      }
      return cn(styles.globalStyles.focus);
    },
    getCheckboxColors: () =>
      cn(
        styles.checkbox.border,
        styles.checkbox.hover,
        styles.checkbox.checked,
        styles.checkbox.checkedHover,
        styles.checkbox.disabled,
        styles.checkbox.checkedDisabled,
        styles.checkbox.indeterminate,
        styles.checkbox.indeterminateHover,
        styles.checkbox.indeterminateDisabled
      ),
    getCheckboxLabelColors: () =>
      cn(styles.checkbox.label, styles.checkbox.labelDisabled),
    getRadioColors: () =>
      cn(
        styles.radio.border,
        styles.radio.hover,
        styles.radio.checked,
        styles.radio.disabled,
        styles.radio.checkedDisabled
      ),
    getRadioLabelColors: () =>
      cn(styles.radio.label, styles.radio.labelDisabled),
    getDividerColors: (variant: keyof Theme['divider'] = 'regular') =>
      cn(styles.divider[variant]),
    getLinkColors: () => {
      return cn(
        styles.link.text,
        styles.link.hover,
        styles.link.active,
        styles.globalStyles.focus
      );
    },
  };
};
