import { useContext } from 'react';
import { BackgroundThemeContext } from '@/context/BackgroundThemeContextProvider';
import { useSelector } from '@/store/utils';
import { getTenantBrand } from '@/utils/color';

export const useBackgroundTheme = () => {
  const tenant = useSelector((state) => state.configuration.tenant);
  const { backgroundTheme: globalBackgroundTheme } = getTenantBrand(tenant);
  const localBackgroundTheme = useContext(BackgroundThemeContext);

  return localBackgroundTheme ?? globalBackgroundTheme;
};
