import { InputField, type InputFieldProps } from '@/components/form/InputField';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';
import React, { useCallback, useState } from 'react';
import { useTranslation } from '@/i18n';
import { type FieldValues, type Path } from 'react-hook-form';

const PasswordField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
>({
  label,
  ...props
}: InputFieldProps<TFieldValues, TName>) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { t } = useTranslation();

  const togglePasswordVisibility = useCallback(
    () => setPasswordVisible((current) => !current),
    []
  );

  return (
    <InputField
      {...props}
      type={passwordVisible ? 'text' : 'password'}
      placeholder={t('text.enterYourPassword')}
      label={label ?? <TransText i18nKey="password" />}
      endAdornment={
        passwordVisible ? (
          <Icons.eye
            onClick={togglePasswordVisibility}
            className="h-4 w-4 hover:cursor-pointer"
          />
        ) : (
          <Icons.eyeOff
            onClick={togglePasswordVisibility}
            className="h-4 w-4 hover:cursor-pointer"
          />
        )
      }
    />
  );
};

export default PasswordField;
