import type { FC, ReactNode } from 'react';

interface CenteredLayoutProps {
  children: ReactNode;
}

export const CenteredLayout: FC<CenteredLayoutProps> = ({ children }) => (
  <div className="flex h-full w-full flex-col tablet:items-center tablet:justify-center">
    {children}
  </div>
);
