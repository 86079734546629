import { createSelector, selector } from '@/store/utils';
import { type AdmissionDTO, type LegDTO } from '@/types/dto';

const selectManageFlowBooking = selector((state) => state.manage.booking);
export const manageFlowBookingSelector = createSelector(
  selectManageFlowBooking
);

const selectManageFlowBookingAdmissionsById = selector(
  (state) =>
    state.manage.booking?.bookedTrips?.reduce<
      Record<NonNullable<LegDTO['id']>, Array<AdmissionDTO>>
    >((acc, { bookedOffers }) => {
      bookedOffers
        ?.flatMap(({ admissions }) => admissions || [])
        .forEach((admission) => {
          admission.coveredLegIds?.forEach(
            (legId) => (acc[legId] = [...(acc[legId] ?? []), admission])
          );
        });

      return acc;
    }, {}) ?? {}
);
export const manageFlowBookingAdmissionsByIdSelector = createSelector(
  selectManageFlowBookingAdmissionsById
);

const selectRefundSelectedLegAdmissions = selector(
  (state) => state.manage.refund.selectedLegAdmissions
);
export const refundSelectedLegAdmissionsSelector = createSelector(
  selectRefundSelectedLegAdmissions
);

const selectRefundDetails = selector((state) => state.manage.refund.details);
export const refundDetailsSelector = createSelector(selectRefundDetails);

const selectRefundSelectedNonTripAdmissions = selector(
  (state) => state.manage.refund.selectedNonTripAdmissions
);
export const refundSelectedNonTripAdmissionsSelector = createSelector(
  selectRefundSelectedNonTripAdmissions
);
