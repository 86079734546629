import { createAsyncThunk } from '@/store/utils';
import getApiInstance from '@/api';
import currencySymbolMap from '@/utils/currencySymbolMap';
import type { CurrencyConfiguration } from '@/types/configuration';
import type { TenantOption } from '@/types/tenant';
import { setTenantBrand, setTenantFavicon } from '@/utils/color';
import type { ConsentsResponseDto, FulfillmentDTO } from '@/types/dto';
import type { TravelAccountDTO, TravelPassDetails } from '@/types/manage';

interface FetchTravelPassDetailsPayload extends TravelPassDetails {
  controlNumber: NonNullable<TravelPassDetails['controlNumber']>;
  issuer: NonNullable<TravelPassDetails['issuer']>;
}

export const setTenant = createAsyncThunk<TenantOption, TenantOption>(
  'configuration/setTenant',
  (tenant) => {
    setTenantBrand(tenant);
    setTenantFavicon(tenant);

    return tenant;
  }
);

export const getConfiguration = createAsyncThunk<{
  currency: CurrencyConfiguration;
  countryCode: string;
}>('configuration/getConfiguration', async () => {
  const api = (await getApiInstance()).agentApi;
  const response = (
    await api.PointOfSaleConfiguration_GetSalesPointConfiguration()
  ).data;

  return {
    currency: {
      name: response.currencies?.[0] ?? null,
      symbol: response.currencies?.[0]
        ? currencySymbolMap[response.currencies[0]]
        : null,
    },
    countryCode: response.countryCode ?? 'US',
  };
});

export const getConsents = createAsyncThunk<ConsentsResponseDto>(
  'configuration/getConsents',
  async () => {
    const api = (await getApiInstance()).agentApi;

    return (await api.Consents_GetConsents()).data;
  }
);

export const getIssuers = createAsyncThunk<
  Record<string, TravelAccountDTO>,
  Array<FulfillmentDTO>
>('configuration/getIssuers', async (fulfillments) => {
  const api = (await getApiInstance()).agentApi;
  const travelPassDetailsPayloads = fulfillments.filter(
    (item): item is FetchTravelPassDetailsPayload =>
      Boolean(item.controlNumber && item.issuer)
  );

  const travelAccounts = await Promise.all(
    travelPassDetailsPayloads.map(({ controlNumber, issuer }) =>
      api.TravelAccounts_GetTravelAccount({
        Issuer: issuer,
        TravelAccount: controlNumber,
        Embed: 'ALL',
        cancelledIncluded: true,
      })
    )
  );

  return travelAccounts.reduce(
    (acc, travelAccount, index) => {
      if (travelAccount.data.travelAccount) {
        acc[travelPassDetailsPayloads[index].issuer] =
          travelAccount.data.travelAccount;
      }

      return acc;
    },
    {} as Record<string, TravelAccountDTO>
  );
});
