import * as React from 'react';
import { useRef } from 'react';
import * as RPNInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import { Button } from '@/components/primitives/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/primitives/Command';
import { Input } from '@/components/primitives/Input';
import { cn } from '@/utils/cn';
import {
  PopoverContent,
  PopoverPrimitiveRoot,
  PopoverTrigger,
} from '@/components/primitives/Popover';
import { Icons } from '@/components/icons';
import { useState } from 'react';
import { TransText } from '@/i18n/trans/text';
import { STORAGE_KEYS, useStorage } from '@/hooks/useStorage';
import { getTenantConfiguration } from '@/utils/tenant';
import { useSelector } from '@/store/utils';
import { tenantSelector } from '@/features/configuration/configurationSelector';

type PhoneInputProps = Omit<
  React.ComponentProps<'input'>,
  'onChange' | 'value' | 'ref'
> &
  Omit<RPNInput.Props<typeof RPNInput.default>, 'onChange'> & {
    onChange?: (value: RPNInput.Value) => void;
  };

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
  React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
    ({ className, onChange, ...props }, ref) => {
      const tenant = useSelector(tenantSelector);
      const { value: defaultCountry, setValue: setDefaultCountry } =
        useStorage<RPNInput.Country>(
          localStorage,
          STORAGE_KEYS.PHONE_PREFIX_COUNTRY
        );

      const tenantDefaultCountry = getTenantConfiguration(
        tenant.value
      ).defaultCountry;

      return (
        <RPNInput.default
          ref={ref}
          className="grid grid-cols-[auto,1fr] gap-2"
          flagComponent={FlagComponent}
          countrySelectComponent={CountrySelect}
          countrySelectProps={{ className, setDefaultCountry }}
          inputComponent={InputComponent}
          numberInputProps={{ className }}
          smartCaret={false}
          international
          defaultCountry={defaultCountry ?? tenantDefaultCountry}
          onChange={(value) => onChange?.(value || ('' as RPNInput.Value))}
          {...props}
        />
      );
    }
  );
PhoneInput.displayName = 'PhoneInput';

const InputComponent = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<'input'>
>(({ className, disabled, ...props }, ref) => (
  <Input
    classNames={{ input: className }}
    disabled={disabled || !props.value}
    {...props}
    ref={ref}
  />
));
InputComponent.displayName = 'InputComponent';

type CountryEntry = { label: string; value: RPNInput.Country | undefined };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  options: Array<CountryEntry>;
  className?: string;
  onChange: (country: RPNInput.Country) => void;
  setDefaultCountry: (country: RPNInput.Country) => void;
};

const CountrySelect = ({
  disabled,
  value: selectedCountry,
  options: countryList,
  className,
  onChange,
  setDefaultCountry,
}: CountrySelectProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const Component = open ? Icons.chevronUp : Icons.chevronDown;

  return (
    <PopoverPrimitiveRoot open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          type="button"
          className={cn(
            'gap-1 border-neutral-medium bg-transparent text-dark focus-within:border-primary focus-within:ring-1 focus-within:ring-primary hover:border-primary-medium hover:bg-transparent focus:z-10 active:bg-transparent',
            className
          )}
          disabled={disabled}
          data-testid="phone-number-code"
        >
          <FlagComponent
            country={selectedCountry}
            countryName={selectedCountry}
          />
          <Component className={cn(disabled ? 'hidden' : 'opacity-100')} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command className="text-dark">
          <CommandInput
            onValueChange={() => setTimeout(() => ref.current?.scroll(0, 0))}
          />
          <CommandList ref={ref}>
            <CommandEmpty>
              <TransText i18nKey="noCountryFound" />
            </CommandEmpty>
            <CommandGroup>
              {countryList.map(({ value, label }) =>
                value ? (
                  <CountrySelectOption
                    key={value}
                    country={value}
                    countryName={label}
                    selectedCountry={selectedCountry}
                    onChange={onChange}
                    setDefaultCountry={setDefaultCountry}
                  />
                ) : null
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </PopoverPrimitiveRoot>
  );
};

interface CountrySelectOptionProps extends RPNInput.FlagProps {
  selectedCountry: RPNInput.Country;
  onChange: (country: RPNInput.Country) => void;
  setDefaultCountry: (country: RPNInput.Country) => void;
}

const CountrySelectOption = ({
  country,
  countryName,
  selectedCountry,
  onChange,
  setDefaultCountry,
}: CountrySelectOptionProps) => {
  const handleChange = () => {
    setDefaultCountry(country);
    onChange(country);
  };

  return (
    <CommandItem className="gap-2 text-dark" onSelect={handleChange}>
      <FlagComponent country={country} countryName={countryName} />
      <span className="flex-1 text-sm">{countryName}</span>
      <span className="text-sm">{`+${RPNInput.getCountryCallingCode(country)}`}</span>
      <Icons.check
        className={cn(
          'ml-auto size-4',
          country === selectedCountry ? 'opacity-100' : 'opacity-0'
        )}
      />
    </CommandItem>
  );
};

const FlagComponent = ({ country, countryName }: RPNInput.FlagProps) => {
  const Flag = flags[country];

  return (
    <span className="bg-foreground/20 flex h-4 w-6 overflow-hidden rounded-sm">
      {Flag && <Flag title={countryName} />}
    </span>
  );
};

export { PhoneInput };
