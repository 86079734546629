import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { formatDay, formatYear } from '@/utils/date-time';
import type { TravelPassAccountDTO } from '@/types/dto';
import { cn } from '@/utils/cn';

interface TravelPassAccountValidityProps {
  validFrom: TravelPassAccountDTO['validFrom'];
  validUntil: TravelPassAccountDTO['validUntil'];
  className?: string;
  isRefunded?: boolean;
}

const TravelPassAccountValidity: FC<TravelPassAccountValidityProps> = ({
  validFrom,
  validUntil,
  className,
  isRefunded,
}) => {
  return (
    <div className={cn('flex w-full flex-col gap-1', className)}>
      <div
        className="flex items-center gap-2"
        data-testid="travel-pass-valid-from-label"
      >
        <Typography
          variant="body2"
          className="w-[70px] text-nowrap text-neutral"
        >
          <TransText i18nKey="validFrom" />:
        </Typography>
        <div className="flex gap-1" data-testid="travel-pass-valid-from-value">
          <Typography
            variant="body2-bold"
            className={cn('text-nowrap', {
              'text-neutral': isRefunded,
            })}
          >
            {formatDay(validFrom)},
          </Typography>
          <Typography
            variant="body2"
            className={cn({
              'text-neutral': isRefunded,
            })}
          >
            {formatYear(validFrom)}
          </Typography>
        </div>
      </div>

      <div
        className="flex items-center gap-2"
        data-testid="travel-pass-valid-to-label"
      >
        <Typography
          variant="body2"
          className="w-[70px] text-nowrap text-neutral"
        >
          <TransText i18nKey="validTo" />:
        </Typography>
        <div className="flex gap-1" data-testid="travel-pass-valid-to-value">
          <Typography
            variant="body2-bold"
            className={cn('text-nowrap', {
              'text-neutral line-through': isRefunded,
            })}
          >
            {formatDay(validUntil)},
          </Typography>
          <Typography
            variant="body2"
            className={cn({
              'text-neutral line-through': isRefunded,
            })}
          >
            {formatYear(validUntil)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default TravelPassAccountValidity;
