import { cn } from '@/utils/cn';
import type { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface PageLayoutProps {
  children: ReactNode;
}

export const PageLayout: FC<PageLayoutProps> = ({ children }) => {
  const location = useLocation();
  const isAccountPage = location.pathname.startsWith('/account');

  return (
    <div
      className={cn('w-full', {
        'flex flex-1': isAccountPage,
      })}
    >
      {children}
    </div>
  );
};
