import { createSelector, selector } from '@/store/utils';

const selectCurrency = selector((state) => state.configuration.currency);
export const currencySelector = createSelector(selectCurrency);

const selectTenant = selector((state) => state.configuration.tenant);
export const tenantSelector = createSelector(selectTenant);

const selectCountryCode = selector((state) => state.configuration.countryCode);
export const countryCodeSelector = createSelector(selectCountryCode);

const selectConsents = selector((state) => state.configuration.consents);
export const consentsSelector = createSelector(selectConsents);

const selectIssuers = selector((state) => state.configuration.issuers);
export const issuersSelector = createSelector(selectIssuers);
