import TripsByDirection from '@/components/manage/tickets/TripsByDirection';
import { TransText } from '@/i18n/trans/text';
import Divider from '@/components/primitives/Divider';
import type { FC } from 'react';
import { useBookingTrips } from '@/hooks/useBookingTrips';
import { useSelector } from '@/store/utils';
import { manageFlowBookingSelector } from '@/features/manage/manageSelectors';

interface BookedTripsDetailsProps {}

const Tickets: FC<BookedTripsDetailsProps> = () => {
  const booking = useSelector(manageFlowBookingSelector);
  const { outboundTrips, inboundTrips } = useBookingTrips(booking);

  return (
    <div className="flex flex-1 flex-col">
      <TripsByDirection
        trips={outboundTrips}
        header={
          <TransText
            i18nKey="outboundTrips"
            values={{ count: outboundTrips.length }}
          />
        }
      />
      {!!inboundTrips.length && (
        <>
          <Divider className="my-4" grow={false} />
          <TripsByDirection
            trips={inboundTrips}
            header={
              <TransText
                i18nKey="returnTrip"
                values={{ count: inboundTrips.length }}
              />
            }
          />
        </>
      )}
    </div>
  );
};

export default Tickets;
