import type { I18nLanguage } from '@/i18n';
import type { Country } from 'react-phone-number-input';

export const TENANT_OPTIONS = [
  {
    value: 'turnit',
    label: 'Turnit',
  },
  {
    value: 'arenaways',
    label: 'Arenaways',
  },
] as const;

export const getTenantConfiguration = (
  tenant?: string
): {
  language: I18nLanguage;
  defaultCountry: Country;
} => {
  const selectedTenant = tenant ?? import.meta.env.VITE_APP_TENANT;

  switch (selectedTenant) {
    case 'turnit':
      return {
        language: 'en',
        defaultCountry: 'EE',
      };
    case 'arenaways':
      return {
        language: 'it',
        defaultCountry: 'IT',
      };

    default:
      return {
        language: 'en',
        defaultCountry: 'US',
      };
  }
};
