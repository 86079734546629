import type { FC } from 'react';
import { localeConfigurations, useTranslation } from '@/i18n';
import { useTranslation as useWidgetTranslation } from '@turnit-ride-ui/webshop-search-widget/widget/i18n';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import _toUpper from 'lodash/toUpper';
import { PopoverItem } from '@/components/primitives/Popover';

interface LanguageSelectContentProps {}

const LanguageSelectContent: FC<LanguageSelectContentProps> = () => {
  const {
    i18n: { changeLanguage, language: currentLanguage },
  } = useTranslation();
  const {
    i18n: { changeLanguage: changeWidgetLanguage },
  } = useWidgetTranslation();

  return Object.entries(localeConfigurations).map(([code, config], key) => (
    <PopoverItem
      key={key}
      active={code === currentLanguage}
      onClick={() => {
        changeLanguage(code);
        changeWidgetLanguage(code);
      }}
      data-testid={`language-select-choose-${code}`}
    >
      <Typography variant="body1">{config.label}</Typography>
      <div className="flex items-center gap-2">
        {code === currentLanguage && (
          <Icons.checkCircle
            height={16}
            width={16}
            className="text-success-text"
          />
        )}
        {_toUpper(code)}
      </div>
    </PopoverItem>
  ));
};

export default LanguageSelectContent;
