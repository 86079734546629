import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  createSignupMethodSchema,
  type SignupMethodValues,
} from '@/utils/zodSchema';
import { Button } from '@/components/primitives/Button';
import { InputField } from '@/components/form/InputField';
import { Form } from '@/components/primitives/Form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@/components/primitives/Typography';
import { CardContent, CardPrimitiveRoot } from '@/components/primitives/Card';
import { TransText } from '@/i18n/trans/text';
import SignupForm from '@/routes/signup/SignupForm';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { CenteredLayout } from '@/components/layouts/CenteredLayout';
import { withAuthGuard } from '@/hoc/withAuthGuard';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';
import { isLightBackground } from '@/utils/color';
import { cn } from '@/utils/cn';

const defaultValues: SignupMethodValues = {
  email: '',
};

const SignUpComponent = () => {
  const backgroundTheme = useBackgroundTheme();
  const isLight = isLightBackground(backgroundTheme);
  const navigate = useNavigate();
  const [step, setStep] = useState<'email' | 'form'>('email');
  const [email, setEmail] = useState('');
  const { t } = useTranslation();
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const form = useForm<SignupMethodValues>({
    mode: 'onBlur',
    resolver: zodResolver(createSignupMethodSchema(t)),
    defaultValues,
  });

  const onSubmit = useCallback(async (data: SignupMethodValues) => {
    setEmail(data.email);
    setStep('form');
  }, []);

  if (step === 'form') {
    return <SignupForm email={email} />;
  }

  return (
    <CenteredLayout>
      <div className="mx-auto flex w-full max-w-[410px] flex-col gap-8 p-4 tablet:mb-auto tablet:mt-auto laptop:p-0">
        <Typography variant={isLaptopOrBigger ? 'title' : 'mobile-title'}>
          <TransText i18nKey="createAnAccount" />
        </Typography>

        <CardPrimitiveRoot className="rounded-xl">
          <CardContent className="flex flex-col gap-6">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className="space-y-4"
              >
                <InputField
                  control={form.control}
                  name="email"
                  label="Email"
                  className={isLaptopOrBigger ? 'h-8' : 'h-12'}
                  placeholder={t('text.enterYourEmailAddress')}
                  type="email"
                  autoCapitalize="none"
                  autoComplete="email"
                  autoCorrect="off"
                  required
                />
                <Button
                  type="submit"
                  data-testid="continue-with-email"
                  variant="primary"
                  className="h-12 w-full"
                >
                  <TransText i18nKey="continueWithEmail" />
                </Button>
              </form>
            </Form>
          </CardContent>
        </CardPrimitiveRoot>

        <div className="flex items-center gap-3 self-center">
          <Typography variant="body1">
            <TransText i18nKey="alreadyHaveAnAccount" />
          </Typography>
          <Button
            variant="ghost"
            className={cn('p-0', isLight ? 'text-primary' : 'text-accent')}
            onClick={() => navigate('/login')}
            data-testid="signup-to-login"
          >
            <Typography variant="button">
              <TransText i18nKey="logIn" />
            </Typography>
          </Button>
        </div>
      </div>
    </CenteredLayout>
  );
};

const SignUp = withAuthGuard('loginOrSignup')(SignUpComponent);

export default SignUp;
