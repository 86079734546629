const assertEnvVariable = (name: string) => {
  const value = import.meta.env[name];
  if (!value) {
    throw new Error(`Environment variable ${name} is not defined`);
  }
  return value;
};

export function assertEnvVars() {
  assertEnvVariable('VITE_APP_TENANT');
  assertEnvVariable('VITE_APP_PROXY_TARGET');
}
