import { createAsyncThunk } from '@/store/utils';
import getApiInstance from '@/api';
import { createAction } from '@reduxjs/toolkit';
import type { STEP } from '@/utils/purchase';
import type {
  AdditionalOfferPartDTO,
  BookingDTO,
  CreateBookingResponseDTO,
  ErrorDTO,
  JourneyDTO,
  NonTripOfferDTO,
  NonTripOfferSearchRequestBodyDTO,
  OfferDTO,
  OfferSearchRequestBodyDTO,
  PayWithExternalPaymentRequestBodyDTO,
  RemoveAncillaryParametersDTO,
  SearchNonTripOffersDTO,
  SearchOffersDTO,
} from '@/types/dto';
import type { SearchFormValues } from '@turnit-ride-ui/webshop-search-widget/widget';
import type {
  AdditionalOfferItem,
  AdditionalOffersCollection,
  OfferMapByLegId,
  OfferMapItem,
} from '@/types/offer';
import type { AncillaryValues, CheckoutValues } from '@/utils/zodSchema';
import type { AxiosResponse } from 'openapi-client-axios';
import {
  createBooking,
  fetchBooking,
} from '@/features/purchase/purchaseService';
import _partition from 'lodash/partition';

export const getOutboundJourneys = createAsyncThunk<
  SearchOffersDTO | null,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>(
  'purchase/getOutboundJourneys',
  async (payload, { dispatch }) => await dispatch(getJourneys(payload)).unwrap()
);

export const getInboundJourneys = createAsyncThunk<
  SearchOffersDTO | null,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>(
  'purchase/getInboundJourneys',
  async (payload, { dispatch }) => await dispatch(getJourneys(payload)).unwrap()
);

export const getJourneys = createAsyncThunk<
  SearchOffersDTO | null,
  Omit<
    OfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>('purchase/getJourneys', async (payload, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const {
    configuration: {
      currency: { name: currency },
    },
  } = getState();

  if (!currency) {
    return null;
  }

  return (
    await api.Offers_FindOffers(null, {
      currency,
      promotionCodes: [],
      corporateCodes: [],
      ...payload,
    })
  ).data;
});

export const getNonTripOffers = createAsyncThunk<
  SearchNonTripOffersDTO | null,
  Omit<
    NonTripOfferSearchRequestBodyDTO,
    'currency' | 'promotionCodes' | 'corporateCodes'
  >
>('purchase/getNonTripOffers', async (payload, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const {
    configuration: {
      currency: { name: currency },
    },
  } = getState();

  if (!currency) {
    return null;
  }

  return (
    await api.Offers_FindNonTripOffers(null, {
      currency,
      promotionCodes: [],
      corporateCodes: [],
      ...payload,
    })
  ).data;
});

export const updateSelectedNonTripOffer = createAction<NonTripOfferDTO>(
  'purchase/updateSelectedNonTripOffer'
);

export const setActiveStep = createAction<STEP>('purchase/setActiveStep');

export const setSelectedOutboundJourney = createAction<JourneyDTO>(
  'purchase/setSelectedOutboundJourney'
);

export const setSelectedInboundJourney = createAction<JourneyDTO>(
  'purchase/setSelectedInboundJourney'
);

export const setSelectedOutboundOfferMapByLegId = createAction<OfferMapByLegId>(
  'purchase/setSelectedOutboundOfferMapByLegId'
);

export const updateSelectedOutboundOfferMapByLegId = createAction<OfferMapItem>(
  'purchase/updateSelectedOutboundOfferMapLegId'
);

export const setSelectedInboundOfferMap = createAction<OfferMapByLegId>(
  'purchase/setSelectedInboundOfferMap'
);

export const updateSelectedInboundOfferMapByLegId = createAction<OfferMapItem>(
  'purchase/updateSelectedInboundOfferMapLegId'
);

export const resetPurchase = createAction<{ startStep: STEP } | undefined>(
  'purchase/resetPurchase'
);

export const getPurchaseFlowBookingAdditionalOffersSearch = createAsyncThunk<
  {
    outboundAdditionalOffers: Array<AdditionalOffersCollection>;
    inboundAdditionalOffers: Array<AdditionalOffersCollection>;
  },
  string
>(
  'purchase/getPurchaseFlowBookingAdditionalOffersSearch',
  async (bookingId, { getState }) => {
    const api = (await getApiInstance()).agentApi;
    const booking = getState().purchase.booking;

    const [outboundBookedTrips, inboundBookedTrips] = _partition(
      booking?.bookedTrips,
      'isOutbound'
    );

    const outboundOfferIds =
      outboundBookedTrips
        .flatMap((bookedTrip) =>
          bookedTrip.bookedOffers?.map((bookedOffer) => bookedOffer.id)
        )
        .filter((id): id is string => Boolean(id)) || [];

    const inboundOfferIds =
      inboundBookedTrips
        .flatMap((bookedTrip) =>
          bookedTrip.bookedOffers?.map((bookedOffer) => bookedOffer.id)
        )
        .filter((id): id is string => Boolean(id)) || [];

    const responseItems = (
      await api.BookedOffers_GetAdditionalOffersSearch(
        {
          bookingId,
        },
        {
          bookedOfferIds: [...outboundOfferIds, ...inboundOfferIds],
        }
      )
    ).data.items;

    const mergeOfferParts = ({
      existingParts,
      newParts,
      bookedOfferId,
      additionalOfferId,
    }: {
      existingParts?: Array<AdditionalOfferItem>;
      newParts?: Array<AdditionalOfferPartDTO>;
      bookedOfferId: string;
      additionalOfferId?: string;
    }): Array<AdditionalOfferItem> =>
      additionalOfferId
        ? [
            ...(existingParts || []),
            ...(newParts || []).map((offer) => ({
              ...offer,
              bookedOfferId,
              additionalOfferId,
            })),
          ]
        : [];

    const additionalOfferCollectionByOfferId =
      responseItems?.reduce<Record<string, AdditionalOffersCollection>>(
        (
          acc,
          {
            bookedOfferId,
            additionalOfferId,
            ancillaryOfferParts,
            reservationOfferParts,
            admissionOfferParts,
          }
        ) => {
          return !bookedOfferId
            ? acc
            : {
                ...acc,
                [bookedOfferId]: {
                  ancillaryOfferParts: mergeOfferParts({
                    existingParts: acc[bookedOfferId]?.ancillaryOfferParts,
                    newParts: ancillaryOfferParts,
                    bookedOfferId,
                    additionalOfferId,
                  }),
                  reservationOfferParts: mergeOfferParts({
                    existingParts: acc[bookedOfferId]?.reservationOfferParts,
                    newParts: reservationOfferParts,
                    bookedOfferId,
                    additionalOfferId,
                  }),
                  admissionOfferParts: mergeOfferParts({
                    existingParts: acc[bookedOfferId]?.admissionOfferParts,
                    newParts: admissionOfferParts,
                    bookedOfferId,
                    additionalOfferId,
                  }),
                },
              };
        },
        {}
      ) || {};

    return {
      outboundAdditionalOffers: outboundOfferIds.map(
        (id) => additionalOfferCollectionByOfferId[id]
      ),
      inboundAdditionalOffers: inboundOfferIds.map(
        (id) => additionalOfferCollectionByOfferId[id]
      ),
    };
  }
);

export const setSearchFormValues = createAction<SearchFormValues>(
  'purchase/setSearchFormValues'
);

export const payWithExternalPayment = createAsyncThunk<
  unknown,
  PayWithExternalPaymentRequestBodyDTO
>('purchase/payWithExternalPayment', async (data, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const booking = getState().purchase.booking;

  if (!booking?.id) {
    return;
  }

  return await api.Payments_PayWithExternalPayment(
    { bookingId: booking.id },
    data
  );
});

export const updatePurchaseFlowBookingPassengers = createAsyncThunk<
  object,
  CheckoutValues['passengers']
>(
  'purchase/updatePurchaseFlowBookingPassengers',
  async (passengers, { getState }) => {
    const api = (await getApiInstance()).agentApi;
    const bookingId = getState().purchase.booking?.id;

    if (!bookingId) {
      return;
    }

    const responses = [];

    for (const { id: passengerId, ...rest } of passengers) {
      const response = await api.Bookings_UpdatePassenger(
        { bookingId, passengerId },
        rest
      );
      responses.push(response);
    }

    return responses;
  }
);

export const updatePurchaseFlowBookingPurchaser = createAsyncThunk<
  object,
  CheckoutValues['purchaser']
>(
  'purchase/updatePurchaseFlowBookingPurchaser',
  async (purchaser, { getState }) => {
    const api = (await getApiInstance()).agentApi;
    const bookingId = getState().purchase.booking?.id;

    if (!bookingId) {
      return;
    }

    return await api.Bookings_UpdatePurchaser({ bookingId }, purchaser);
  }
);

export const addPurchaseFlowBookingAncillary = createAsyncThunk<
  Array<AxiosResponse<ErrorDTO>> | void,
  AncillaryValues
>('purchase/addPurchaseFlowBookingAncillary', async (data, { getState }) => {
  const api = (await getApiInstance()).agentApi;
  const bookingId = getState().purchase.booking?.id;

  // TODO: Refactor to single request once this task is completed: https://youtrack.tsolutions.co/issue/BR-49676
  const failedResults = [];

  if (!bookingId) {
    return;
  }
  for (const selectedAncillary of data.selectedAncillaries || []) {
    if (!selectedAncillary) {
      continue;
    }

    const {
      bookedOfferId,
      id,
      additionalOfferId: offerId,
      passengersExternalReferences: passengerRefs,
    } = selectedAncillary;

    const response = await api.BookedOffers_AddAncillary(
      { bookingId, bookedOfferId },
      {
        ancillaryId: id,
        offerId,
        passengerRefs: passengerRefs.filter(Boolean) as unknown as [
          string,
          ...string[],
        ],
      }
    );

    if (response.status !== 204) {
      failedResults.push(response);
    }
  }

  if (failedResults.length) {
    return failedResults;
  }
});

export const removePurchaseFlowBookingAncillary = createAsyncThunk<
  object,
  Omit<RemoveAncillaryParametersDTO, 'bookingId'>
>(
  'purchase/removePurchaseFlowBookingAncillary',
  async (pathParams, { getState }) => {
    const api = (await getApiInstance()).agentApi;
    const bookingId = getState().purchase.booking?.id;

    if (!bookingId) {
      return;
    }

    return (
      await api.BookedOffers_DeleteAncillary({
        bookingId,
        ...pathParams,
      })
    ).data;
  }
);

export const createPurchaseFlowTripOfferBooking = createAsyncThunk<
  CreateBookingResponseDTO,
  SearchFormValues['passengers']
>(
  'purchase/createPurchaseFlowTripOfferBooking',
  async (passengers, { getState }) => {
    const { outbound, inbound } = getState().purchase;
    const passengerExternalReferences = passengers.map(
      ({ externalReference }) => externalReference!
    );

    const offers = [
      ...Object.values(outbound.selectedOfferMapByLegId),
      ...Object.values(inbound.selectedOfferMapByLegId),
    ]
      .filter(
        (
          offer
        ): offer is Omit<OfferDTO, 'id'> & {
          id: NonNullable<OfferDTO['id']>;
        } => !!offer.id
      )
      .map(({ id }) => ({ id, passengerExternalReferences }));

    return createBooking(offers, passengers);
  }
);

export const createPurchaseFlowNonTripOfferBooking = createAsyncThunk<
  CreateBookingResponseDTO,
  SearchFormValues['passengers']
>(
  'purchase/createPurchaseFlowNonTripOfferBooking',
  async (passengers, { getState }) => {
    const { nonTripOffers } = getState().purchase;
    const passengerExternalReferences = passengers
      .map(({ externalReference }) => externalReference)
      .filter((item): item is string => Boolean(item));
    const offers = nonTripOffers.selectedNonTripOffer?.id
      ? [
          {
            id: nonTripOffers.selectedNonTripOffer?.id,
            passengerExternalReferences,
          },
        ]
      : [];

    return createBooking(offers, passengers);
  }
);

export const getPurchaseFlowBookingById = createAsyncThunk<
  BookingDTO,
  string | null | undefined
>('purchase/getPurchaseFlowBookingById', fetchBooking);

export const clearPurchaseFlowBooking = createAction(
  'purchase/clearPurchaseFlowBooking'
);

export const deletePurchaseFlowBooking = createAsyncThunk<
  void,
  NonNullable<BookingDTO['id']>
>('purchase/deletePurchaseFlowBooking', async (bookingId, { dispatch }) => {
  const api = (await getApiInstance()).agentApi;

  await api.Bookings_DeleteBooking({ bookingId });

  dispatch(clearPurchaseFlowBooking());
  dispatch(resetPurchase());
});
