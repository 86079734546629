import { createReducer } from '@reduxjs/toolkit';
import {
  getConsents,
  getConfiguration,
  getIssuers,
  setTenant,
} from '@/features/configuration/configurationActions';
import type { CurrencyConfiguration } from '@/types/configuration';
import type { TenantOption } from '@/types/tenant';
import { TENANT_OPTIONS } from '@/utils/tenant';
import type { ConsentsResponseDto } from '@/types/dto';
import { type TravelAccountDTO } from '@/types/manage';

type ConfigurationState = {
  tenant: TenantOption;
  currency: CurrencyConfiguration;
  consents?: ConsentsResponseDto;
  issuers: Record<string, TravelAccountDTO>;
  countryCode: string;
};

const initialState: ConfigurationState = {
  tenant: TENANT_OPTIONS[0],
  currency: {
    name: null,
    symbol: null,
  },
  issuers: {},
  countryCode: 'US',
};

export const configurationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setTenant.fulfilled, (state, action) => {
      state.tenant = action.payload;
    })
    .addCase(getConfiguration.fulfilled, (state, action) => {
      state.currency = action.payload.currency;
      state.countryCode = action.payload.countryCode;
    })
    .addCase(getConsents.fulfilled, (state, action) => {
      state.consents = action.payload;
    })
    .addCase(getIssuers.fulfilled, (state, action) => {
      state.issuers = action.payload;
    });
});
