import { type FC, useCallback, useEffect } from 'react';
import { usePurchaseSteps } from '@/hooks/usePurchaseSteps';
import { cn } from '@/utils/cn';
import { STEP } from '@/utils/purchase';
import { SearchWidgetWrapper } from '@/components/search-widget';
import { SearchWidgetProvider } from '@/context/SearchWidgetContextProvider';
import { useDispatch, useSelector } from '@/store/utils';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';
import {
  getPurchaseFlowBookingById,
  setActiveStep,
} from '@/features/purchase/purchaseActions';
import { persistor } from '@/store';
import { PersistGate } from 'redux-persist/integration/react';
import { usePageTitle } from '@/hooks/usePageTitle';

interface PurchaseProps {}

const Purchase: FC<PurchaseProps> = () => {
  const dispatch = useDispatch();
  const { steps, activeStep } = usePurchaseSteps();
  const purchaseFlowBooking = useSelector(purchaseFlowBookingSelector);
  usePageTitle('purchase');

  const handlePurchaseBookingFetching = useCallback(async () => {
    try {
      await dispatch(
        getPurchaseFlowBookingById(purchaseFlowBooking?.id)
      ).unwrap();
      dispatch(setActiveStep(STEP.Checkout));
    } catch {
      persistor.purge();
    }
  }, [dispatch, purchaseFlowBooking?.id]);

  useEffect(() => {
    if (
      purchaseFlowBooking?.id &&
      Object.keys(purchaseFlowBooking).length === 1
    ) {
      handlePurchaseBookingFetching();
    }
  }, [handlePurchaseBookingFetching, purchaseFlowBooking]);

  return (
    <PersistGate loading={null} persistor={persistor}>
      <SearchWidgetProvider>
        <div className="sticky top-0 z-50 bg-background">
          {STEP.Checkout !== activeStep && <SearchWidgetWrapper />}
        </div>
        <div
          className={cn(
            'mx-auto w-full max-w-laptop p-4 pb-24 laptop:p-5 laptop:pb-28',
            {
              ['pb-36 pt-0 tablet:pb-40']: activeStep === STEP.Checkout, // search widget is hidden and content can be closer to header, booking summary on top of footer takes extra space so additional bottom padding is needed
              ['pb-4 tablet:pb-4']: activeStep === STEP.OutboundJourney, // footer is hidden for outbound journey so bottom padding not needed
              ['flex h-full flex-col p-0 laptop:h-fit laptop:gap-6']: [
                STEP.InboundOffer,
                STEP.OutboundOffer,
                STEP.NonTripOffer,
              ].includes(activeStep), // white card should be everywhere on mobile
              ['laptop:w-[800px]']: activeStep === STEP.NonTripOffer, // card should be small
            }
          )}
        >
          {steps[activeStep]}
        </div>
      </SearchWidgetProvider>
    </PersistGate>
  );
};

export default Purchase;
