import React, { type FC } from 'react';
import { Icons } from '@/components/icons';
import {
  SheetRoot,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/primitives/Sheet';
import type { NavigationItem } from '@/types/navigation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cn } from '@/utils/cn';
import MobileLanguageSelect from '@/components/header/language-select/MobileLanguageSelect';
import { Button } from '@/components/primitives/Button';
import MobileTenantSelect from '@/components/header/tenant-select/MobileTenantSelect';
import { isLocalDevelopment } from '@/utils/isLocalDev';
import { useSelector } from '@/store/utils';
import { tenantSelector } from '@/features/configuration/configurationSelector';

type MobileNavMenuProps = {
  menuItems: Array<NavigationItem>;
};

const MobileNavMenu: FC<MobileNavMenuProps> = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedTenant = useSelector(tenantSelector);
  const TenantIcon = Icons[selectedTenant.value];

  return (
    <div
      className="flex w-full items-center justify-between gap-2 tablet:px-0"
      data-testid="navbar-mobile"
    >
      <Link to="/" aria-label="Hero">
        <TenantIcon />
      </Link>
      <div className="flex h-full items-center gap-2">
        {isLocalDevelopment() && (
          <>
            <MobileTenantSelect />
            <Button data-testid="my-account-button" variant="ghost">
              <Icons.user onClick={() => navigate('/login')} />
            </Button>
          </>
        )}
        <MobileLanguageSelect />
        <SheetRoot>
          <SheetTrigger
            className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-tint focus-visible:ring-offset-2"
            aria-label="Open navigation menu"
            asChild
          >
            <Button variant="ghost" data-testid="mobile-nav-open-button">
              <Icons.burgerMenu width={38} height={38} />
            </Button>
          </SheetTrigger>
          <SheetContent className="flex w-[250px] flex-col p-0" showClose>
            <SheetTitle className="sr-only">Mobile navigation menu</SheetTitle>
            <SheetDescription className="sr-only">
              Mobile navigation
            </SheetDescription>
            <SheetHeader className="border-b border-neutral-pale p-4 pt-4">
              <TenantIcon />
            </SheetHeader>
            <nav>
              {menuItems.map((item) => (
                <SheetClose asChild key={item.path}>
                  <Link
                    to={item.path}
                    onClick={item.onMenuItemClick}
                    className={cn(
                      'mx-4 my-3 block text-sm transition duration-300 hover:text-tint focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-tint focus-visible:ring-offset-2',
                      { 'text-tint': item.path === location.pathname }
                    )}
                  >
                    {item.name}
                  </Link>
                </SheetClose>
              ))}
            </nav>
          </SheetContent>
        </SheetRoot>
      </div>
    </div>
  );
};

export default MobileNavMenu;
