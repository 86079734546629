export const getLocalIpAddress = async (): Promise<string> => {
  try {
    const peerConnection = new RTCPeerConnection({
      iceServers: [{ urls: 'stun:stun.l.google.com:19302' }],
    });

    peerConnection.createDataChannel('');
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);

    return new Promise((resolve) => {
      let hasResolved = false;

      peerConnection.onicecandidate = (event) => {
        if (hasResolved) return;

        if (!event.candidate) {
          peerConnection.close();
          if (!hasResolved) {
            hasResolved = true;
            resolve('0.0.0.0');
          }
          return;
        }

        const ipMatch = event.candidate.candidate.match(
          /([0-9]{1,3}(\.[0-9]{1,3}){3})/
        );
        if (ipMatch && !ipMatch[1].startsWith('127.')) {
          peerConnection.close();
          if (!hasResolved) {
            hasResolved = true;
            resolve(ipMatch[1]);
          }
        }
      };

      setTimeout(() => {
        peerConnection.close();
        if (!hasResolved) {
          hasResolved = true;
          resolve('0.0.0.0');
        }
      }, 5000);
    });
  } catch (error) {
    console.error('Error getting local IP:', error);
    return '0.0.0.0';
  }
};
