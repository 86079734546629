import * as React from 'react';
import {
  Link as RouterLink,
  type LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { useThemedColors } from '@/hooks/useThemedColors';
import { cn } from '@/utils/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import { typographyVariants } from '@/components/primitives/Typography';
import { isLightBackground } from '@/utils/color';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';

const linkVariants = cva(
  'inline-flex items-center justify-center gap-1.5 whitespace-nowrap rounded-md transition-colors focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed',
  {
    variants: {
      size: {
        large: cn(typographyVariants({ variant: 'subtitle' })),
        medium: cn(typographyVariants({ variant: 'body1-bold' })),
        regular: cn(typographyVariants({ variant: 'button' })),
        small: cn(typographyVariants({ variant: 'body2' })),
      },
    },
    defaultVariants: {
      size: 'regular',
    },
  }
);

export interface LinkProps
  extends RouterLinkProps,
    VariantProps<typeof linkVariants> {
  asChild?: boolean;
  active?: boolean;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { className, size = 'regular', active = false, children, ...props },
    ref
  ) => {
    const { getLinkColors } = useThemedColors();
    const backgroundTheme = useBackgroundTheme();

    return (
      <RouterLink
        ref={ref}
        className={cn(
          linkVariants({ size }),
          getLinkColors(),
          active &&
            (isLightBackground(backgroundTheme) ? 'text-tint' : 'text-accent'),
          className
        )}
        {...props}
      >
        {children}
      </RouterLink>
    );
  }
);

Link.displayName = 'Link';
