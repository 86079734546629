import axios from 'axios';
import getApiInstance from '@/api';
import type {
  CreateCustomerProfileRequestBodyDTO,
  CustomerProfileDetailsResponseDTO,
  CustomerProfileDTO,
  LoginRequestBodyDTO,
  LoginResponseDTO,
  LogoutRequestBodyDTO,
} from '@/types/dto';

const BASE_URL = `${import.meta.env.VITE_APP_PROXY_TARGET}`;

export const authenticateUser = async (
  body: LoginRequestBodyDTO
): Promise<LoginResponseDTO> => {
  const response = await axios.post(`${BASE_URL}/auth/token`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  return response.data;
};

export const registerUser = async (
  body: CreateCustomerProfileRequestBodyDTO
): Promise<CustomerProfileDetailsResponseDTO> => {
  const response = await axios.post(`${BASE_URL}/auth/register`, body);
  return response.data;
};

export const getCustomerProfile = async (): Promise<CustomerProfileDTO> => {
  const api = (await getApiInstance()).agentApi;
  return (await api.CustomerProfile_GetCustomerProfileDetails()).data;
};

export const revokeToken = async (
  body: LogoutRequestBodyDTO
): Promise<void> => {
  await axios.post(`${BASE_URL}/auth/revoke`, body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};
