import React, { type FC } from 'react';
import { TENANT_OPTIONS } from '@/utils/tenant';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import type { TenantOption } from '@/types/tenant';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/primitives/Dialog';
import { Button } from '@/components/primitives/Button';
import { useDispatch, useSelector } from '@/store/utils';
import { setTenant } from '@/features/configuration/configurationActions';
import { tenantSelector } from '@/features/configuration/configurationSelector';

const MobileTenantSelect: FC = () => {
  const selectedTenant = useSelector(tenantSelector);
  const dispatch = useDispatch();

  const handleTenantChange = (tenant: TenantOption) => {
    dispatch(setTenant(tenant));
  };

  return (
    <Dialog>
      <DialogTrigger className="flex items-center" asChild>
        <Button variant="ghost" data-testid="tenant-select-trigger-mobile">
          <Icons.powerSocket />
        </Button>
      </DialogTrigger>
      <DialogContent className="gap-0 p-0">
        <DialogHeader className="text-lef border-none p-4">
          <DialogTitle>Select tenant</DialogTitle>
          <DialogDescription hidden>Select tenant</DialogDescription>
        </DialogHeader>
        <div className="flex flex-col">
          {TENANT_OPTIONS.map((option) => (
            <Button
              key={option.value}
              variant="ghost"
              className="flex h-14 w-full items-center px-4"
              onClick={() => handleTenantChange(option)}
              data-testid={`tenant-select-mobile-item-${option.value}`}
            >
              <Typography variant="body1">{option.label}</Typography>
              {option.value === selectedTenant.value && (
                <Icons.checkCircle
                  height={16}
                  width={16}
                  className="text-success-text"
                />
              )}
            </Button>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MobileTenantSelect;
