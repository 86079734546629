import { Icons } from '@/components/icons';
import { LegSelectionCard } from '@/components/manage/refund/leg/LegSelectionCard';
import { LegPassengerSelectionCard } from '@/components/manage/refund/leg/LegPassengerSelectionCard';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import type {
  AdmissionDTO,
  BookedTripDTO,
  PlaceAllocationDTO,
} from '@/types/dto';
import React, {
  type FC,
  type ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { getPlaceIconName } from '@/utils/icon';
import { useDispatch, useSelector } from '@/store/utils';
import Divider from '@/components/primitives/Divider';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';
import {
  manageFlowBookingAdmissionsByIdSelector,
  manageFlowBookingSelector,
  refundSelectedLegAdmissionsSelector,
} from '@/features/manage/manageSelectors';
import {
  toggleRefundLegAdmission,
  toggleRefundLeg,
} from '@/features/manage/manageActions';

interface RefundLegSelectionProps {}

export const RefundLegSelection: FC<RefundLegSelectionProps> = () => {
  const dispatch = useDispatch();
  const booking = useSelector(manageFlowBookingSelector);
  const selectedLegAdmissions = useSelector(
    refundSelectedLegAdmissionsSelector
  );
  const admissionsByLegId = useSelector(
    manageFlowBookingAdmissionsByIdSelector
  );
  const [expandedLegs, setExpandedLegs] = useState<Set<string>>(new Set());
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips(booking);

  const toggleLegExpansion = useCallback((legId: string) => {
    setExpandedLegs((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(legId)) {
        newSet.delete(legId);
      } else {
        newSet.add(legId);
      }
      return newSet;
    });
  }, []);

  const isRefunded = useCallback(
    (legId: string, admissionId?: string) => {
      const admission = admissionsByLegId[legId]?.find(
        (adm) => adm.id === admissionId
      );
      return admission?.status === 'REFUNDED';
    },
    [admissionsByLegId]
  );

  const isSelected = useCallback(
    (legId: string, admissionId?: string) => {
      const legAdmissions = selectedLegAdmissions[legId] || [];
      return admissionId
        ? legAdmissions.some((admission) => admission.id === admissionId)
        : legAdmissions.length === admissionsByLegId[legId]?.length;
    },
    [selectedLegAdmissions, admissionsByLegId]
  );

  const isLegIndeterminate = useCallback(
    (legId: string) => {
      const selectedCount = selectedLegAdmissions[legId]?.length || 0;
      const totalCount =
        admissionsByLegId[legId]?.filter(
          (admission) => admission.status !== 'REFUNDED'
        ).length || 0;
      return selectedCount > 0 && selectedCount < totalCount;
    },
    [selectedLegAdmissions, admissionsByLegId]
  );

  const passengerMap = useMemo(
    () =>
      new Map(
        booking?.passengers?.map((passenger) => [passenger.id, passenger]) ?? []
      ),
    [booking]
  );

  const getAdmissionsPlaceAllocations = useCallback(
    (
      admissions: Array<AdmissionDTO>
    ): Array<PlaceAllocationDTO & { passengerIds: Array<string> }> => {
      return admissions.flatMap((admission) =>
        (admission.reservations || []).flatMap((reservation) => {
          if (reservation.placeAllocations) {
            return [
              {
                ...reservation.placeAllocations,
                passengerIds: admission.passengerIds || [],
              },
            ];
          }
          return [];
        })
      );
    },
    []
  );

  const renderTrip = useCallback(
    (trip: BookedTripDTO) => (
      <div key={trip.id} className="mb-4 flex flex-col gap-2">
        {trip.legs?.map((leg, index) => {
          const legId = leg.id;

          if (!legId) {
            return null;
          }

          const isLegSelected = isSelected(legId);
          const isLegIndeterminateState = isLegIndeterminate(legId);
          const isLegExpanded =
            expandedLegs.has(legId) || isLegIndeterminateState || isLegSelected;

          const nonRefundedAdmissions = admissionsByLegId[legId]?.filter(
            (admission) => !isRefunded(legId, admission.id)
          );

          if (nonRefundedAdmissions?.length === 0) {
            return null;
          }

          return (
            <React.Fragment key={legId}>
              <LegSelectionCard
                leg={leg}
                isSelected={isLegSelected}
                indeterminate={isLegIndeterminateState}
                onSelect={() => {
                  dispatch(
                    toggleRefundLeg(
                      legId,
                      selectedLegAdmissions,
                      admissionsByLegId[legId] || []
                    )
                  );
                  toggleLegExpansion(legId);
                }}
                refundable={nonRefundedAdmissions[0]?.refundable ?? 'NO'}
                productSummary={
                  nonRefundedAdmissions[0]?.productSummary ||
                  nonRefundedAdmissions[0]?.summary ||
                  ''
                }
              />
              {isLegExpanded && (
                <div className="ml-6">
                  <Typography variant="body2" className="mb-2 mt-3">
                    <TransText i18nKey="selectPassengersToRefund" />
                  </Typography>

                  <div className="flex flex-col gap-2">
                    {nonRefundedAdmissions.map((admission) => {
                      const placeAllocations = getAdmissionsPlaceAllocations([
                        admission,
                      ]);
                      const passengerId = admission.passengerIds?.[0];
                      const passenger = passengerId
                        ? passengerMap.get(passengerId)
                        : undefined;

                      if (!passenger) return null;

                      const passengerPlaceAllocation = placeAllocations[0];
                      const seats = passengerPlaceAllocation?.reservedPlaces
                        ?.map((rp) => rp.placeNumber)
                        .filter(Boolean)
                        .join(', ');
                      const carriages = passengerPlaceAllocation?.reservedPlaces
                        ?.map((rp) => rp.coachNumber)
                        .filter(Boolean)
                        .join(', ');
                      const placeIcon = getPlaceIconName(
                        passengerPlaceAllocation
                      );

                      return (
                        <LegPassengerSelectionCard
                          key={admission.id}
                          passenger={{
                            firstName: passenger.firstName?.value ?? '',
                            lastName: passenger.lastName?.value ?? '',
                            age: passenger.age,
                            seats: seats,
                            carriages: carriages,
                          }}
                          ptMode={leg.ptMode}
                          placeIcon={placeIcon}
                          isSecondLevelItem
                          isChecked={isSelected(legId, admission.id)}
                          onToggle={() =>
                            dispatch(
                              toggleRefundLegAdmission(
                                legId,
                                selectedLegAdmissions,
                                admission
                              )
                            )
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              {trip.legs && index < trip.legs.length - 1 && (
                <div className="flex items-center gap-1.5">
                  <Icons.transfer height={16} width={16} />
                  <Typography variant="body2" className="my-3">
                    <TransText i18nKey="transfer" />
                  </Typography>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    ),
    [
      isSelected,
      isLegIndeterminate,
      expandedLegs,
      admissionsByLegId,
      isRefunded,
      dispatch,
      selectedLegAdmissions,
      toggleLegExpansion,
      getAdmissionsPlaceAllocations,
      passengerMap,
    ]
  );

  const renderTripSelections = useCallback(
    () => (
      <>
        {[
          {
            trips: refundEligibleOutboundTrips,
            title: (
              <TransText
                i18nKey="outboundTrips"
                values={{ count: refundEligibleOutboundTrips.length }}
              />
            ),
          },
          {
            trips: refundEligibleInboundTrips,
            title: (
              <TransText
                i18nKey="returnTrips"
                values={{ count: refundEligibleInboundTrips.length }}
              />
            ),
          },
        ].map(
          (
            selection: {
              trips: BookedTripDTO[];
              title: ReactNode;
            },
            index: number
          ) =>
            selection.trips.length > 0 ? (
              <React.Fragment key={index}>
                {index > 0 &&
                  !!refundEligibleInboundTrips.length &&
                  !!refundEligibleOutboundTrips.length && (
                    <Divider className="my-4" variant="medium" />
                  )}
                <div>
                  <Typography variant="body1-bold" className="mb-3">
                    {selection.title}
                  </Typography>
                  {selection.trips.map(renderTrip)}
                </div>
              </React.Fragment>
            ) : null
        )}
      </>
    ),
    [refundEligibleOutboundTrips, refundEligibleInboundTrips, renderTrip]
  );

  return renderTripSelections();
};
