import { createContext, useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAccessToken,
  getRefreshToken,
  getTokenExpiry,
  setTokens,
  clearTokens,
} from '@/utils/auth';
import type {
  CreateCustomerProfileRequestBodyDTO,
  LoginResponseDTO,
} from '@/types/dto';
import {
  authenticateUser,
  revokeToken,
  registerUser,
} from '@/features/auth/authService';
import { clearProfile, getProfile } from '@/features/profile/profileActions';
import type { LoginValues } from '@/utils/zodSchema';
import { useDispatch } from '@/store/utils';

export interface AuthContextType {
  isAuthenticated: boolean;
  isLoading: boolean;
  logout: () => Promise<void>;
  loginWithPassword: (values: LoginValues) => Promise<LoginResponseDTO>;
  registerWithPassword: (
    values: CreateCustomerProfileRequestBodyDTO
  ) => Promise<void>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLoginSuccess = useCallback(
    async (response: LoginResponseDTO) => {
      setTokens(
        response.access_token,
        response.refresh_token,
        response.expires_in
      );
      await dispatch(getProfile()).unwrap();
    },
    [dispatch]
  );

  const logout = useCallback(async () => {
    try {
      const currentAccessToken = getAccessToken();
      const currentRefreshToken = getRefreshToken();

      if (currentAccessToken) {
        await revokeToken({
          token: currentAccessToken,
          token_type: 'access_token',
        });
      }
      if (currentRefreshToken) {
        await revokeToken({
          token: currentRefreshToken,
          token_type: 'refresh_token',
        });
      }

      clearTokens();
      dispatch(clearProfile());
      navigate('/login');
    } catch (error) {
      clearTokens();
      dispatch(clearProfile());
      navigate('/login');
    }
  }, [navigate, dispatch]);

  const refreshAccessToken = useCallback(async () => {
    const currentRefreshToken = getRefreshToken();
    if (!currentRefreshToken) {
      throw new Error('No refresh token available');
    }

    try {
      const response = await authenticateUser({
        grant_type: 'refresh_token',
        refresh_token: currentRefreshToken,
      });

      if (!response.access_token || !response.expires_in) {
        throw new Error('Invalid token response');
      }

      setTokens(
        response.access_token,
        response.refresh_token,
        response.expires_in
      );
    } catch (error) {
      await logout();
      throw error;
    }
  }, [logout]);

  const loginWithPassword = useCallback(
    async (values: LoginValues): Promise<LoginResponseDTO> => {
      const response = await authenticateUser({
        grant_type: 'password',
        username: values.email,
        password: values.password,
      });

      await handleLoginSuccess(response);
      return response;
    },
    [handleLoginSuccess]
  );

  const registerWithPassword = useCallback(
    async (values: CreateCustomerProfileRequestBodyDTO): Promise<void> => {
      await registerUser({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        password: values.password,
        consents: values.consents,
        isMemberOfLoyaltyProgram: false,
      });
    },
    []
  );

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const currentTokenExpiry = getTokenExpiry();
    const currentRefreshToken = getRefreshToken();

    if (!currentTokenExpiry || !currentRefreshToken) return;

    const timeUntilExpiry = currentTokenExpiry - Date.now();
    const refreshBuffer = Math.min(timeUntilExpiry * 0.25, 5 * 60 * 1000);

    const refreshTimeout = setTimeout(async () => {
      await refreshAccessToken();
    }, timeUntilExpiry - refreshBuffer);

    return () => clearTimeout(refreshTimeout);
  }, [refreshAccessToken]);

  const value = {
    isAuthenticated: !!getAccessToken(),
    isLoading,
    logout,
    loginWithPassword,
    registerWithPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
