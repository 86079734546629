import { useContext } from 'react';
import { SearchWidgetContext } from '@/context/SearchWidgetContextProvider';

export const useSearchWidget = () => {
  const context = useContext(SearchWidgetContext);

  if (context === undefined) {
    throw new Error(
      'useSearchWidget must be used within a SearchWidgetProvider'
    );
  }

  return context;
};
