import type { FC } from 'react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icons } from '@/components/icons';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from '@/components/primitives/NavigationMenu';
import type { NavigationItem } from '@/types/navigation';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import RegularLanguageSelect from '@/components/header/language-select/RegularLanguageSelect';
import { Link } from '@/components/primitives/Link';
import { Button } from '@/components/primitives/Button';
import RegularTenantSelect from '@/components/header/tenant-select/RegularTenantSelect';
import { isLocalDevelopment } from '@/utils/isLocalDev';
import { useAuth } from '@/hooks/useAuth';
import { getDisplayName } from '@/utils/profile';
import { profileDataSelector } from '@/features/profile/profileSelectors';
import { useSelector } from '@/store/utils';
import { tenantSelector } from '@/features/configuration/configurationSelector';
import { TransText } from '@/i18n/trans/text';

type RegularNavMenuProps = {
  menuItems: Array<NavigationItem>;
};

const RegularNavMenu: FC<RegularNavMenuProps> = ({ menuItems }) => {
  const { isAuthenticated } = useAuth();
  const profile = useSelector(profileDataSelector);
  const displayName = getDisplayName(profile);
  const location = useLocation();
  const navigate = useNavigate();
  const selectedTenant = useSelector(tenantSelector);
  const TenantIcon = Icons[selectedTenant.value];

  return (
    <div
      className="mx-auto flex w-full items-center justify-between"
      data-testid="navbar-regular"
    >
      <div className="flex items-center gap-6">
        <Link to="/" aria-label="Hero">
          <TenantIcon />
        </Link>
        <nav className="flex items-center">
          <NavigationMenu>
            <NavigationMenuList className="flex items-center gap-4">
              {menuItems.map((item, index) => (
                <React.Fragment key={item.path}>
                  <NavigationMenuItem>
                    <Link
                      to={item.path}
                      onClick={item.onMenuItemClick}
                      active={location.pathname === item.path}
                    >
                      <Typography variant="button" asChild>
                        {item.name}
                      </Typography>
                    </Link>
                  </NavigationMenuItem>
                  {index < menuItems.length - 1 && (
                    <Divider
                      orientation="vertical"
                      className="h-4"
                      variant="strong"
                    />
                  )}
                </React.Fragment>
              ))}
            </NavigationMenuList>
          </NavigationMenu>
        </nav>
      </div>
      <div className="flex items-center gap-4">
        {isLocalDevelopment() && (
          <>
            <RegularTenantSelect />
            <Divider orientation="vertical" className="h-4" variant="strong" />
            <Link
              to={isAuthenticated ? '/account' : '/login'}
              data-testid="my-account-button"
              className="flex gap-2"
            >
              <Icons.user />

              <Typography variant="button">
                {isAuthenticated ? displayName : <TransText i18nKey="logIn" />}
              </Typography>
            </Link>
            <Divider orientation="vertical" className="h-4" variant="strong" />
          </>
        )}
        <RegularLanguageSelect />
      </div>
    </div>
  );
};

export default RegularNavMenu;
