import React, { type Dispatch, type FC, type SetStateAction } from 'react';
import { Button } from '@/components/primitives/Button';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';
import { useFormContext } from 'react-hook-form';
import type { AncillaryValues } from '@/utils/zodSchema';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import Divider from '@/components/primitives/Divider';

interface MobileAncillaryModalFooterProps {
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
  totalPrice: number;
}

const MobileAncillaryModalFooter: FC<MobileAncillaryModalFooterProps> = ({
  setIsOverlayOpened,
  totalPrice,
}) => {
  const currency = useSelector(currencySelector);
  const { watch } = useFormContext<AncillaryValues>();
  const selectedLegId = watch('selectedLegId');
  const selectedAncillaries = watch('selectedAncillaries');

  return (
    <div className="fixed bottom-0 flex w-full flex-col bg-white">
      <Divider />
      <div className="flex gap-2 px-4 pb-2 pt-6">
        <Icons.travelPass height={20} width={20} className="text-primary" />
        <Typography variant="body1" className="text-neutral">
          <TransText i18nKey="totalPrice" />:
        </Typography>
        <Typography variant="subtitle" className="text-primary">
          {totalPrice}
          {currency.symbol}
        </Typography>
      </div>
      <div className="flex w-full items-center gap-2 self-end p-3">
        <Button
          variant="tertiary"
          data-testid="ancillary-selection-cancel-button"
          className="w-1/2 rounded-lg"
          type="button"
          onClick={() => setIsOverlayOpened(false)}
        >
          <Typography variant="button">
            <TransText i18nKey="cancel" />
          </Typography>
        </Button>
        <Button
          data-testid="ancillary-selection-submit-button"
          className="w-1/2 gap-2 rounded-lg p-4"
          disabled={!selectedLegId || selectedAncillaries.length === 0}
          type="submit"
        >
          <Icons.check />
          <Typography variant="button">
            <TransText i18nKey={selectedLegId ? 'confirm' : 'selectLeg'} />
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default MobileAncillaryModalFooter;
