import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { STEP } from '@/utils/purchase';
import {
  createPurchaseFlowNonTripOfferBooking,
  createPurchaseFlowTripOfferBooking,
  getPurchaseFlowBookingById,
  setActiveStep,
  setSelectedInboundJourney,
  setSelectedInboundOfferMap,
  setSelectedOutboundJourney,
  setSelectedOutboundOfferMapByLegId,
  updateSelectedInboundOfferMapByLegId,
  updateSelectedNonTripOffer,
  updateSelectedOutboundOfferMapByLegId,
} from '@/features/purchase/purchaseActions';
import JourneyList from '@/routes/purchase/JourneyList';
import JourneyDetails from '@/routes/purchase/JourneyDetails';
import {
  activeStepSelector,
  inboundDataSelector,
  nonTripOffersSelector,
  outboundDataSelector,
  searchFormValuesSelector,
  selectedInboundOfferMapSelector,
  selectedOutboundOfferMapSelector,
} from '@/features/purchase/purchaseSelectors';
import { useDispatch, useSelector } from '@/store/utils';
import Checkout from '@/routes/purchase/Checkout';
import {
  inboundJourneysLoadingSelector,
  outboundJourneysLoadingSelector,
  purchaseFlowBookingFetchingLoadingSelector,
} from '@/features/loading/loadingSelectors';
import type { CreateBookingResponseDTO, JourneyDTO } from '@/types/dto';
import { TransText } from '@/i18n/trans/text';
import NonTripOfferList from '@/routes/purchase/NonTripOfferList';
import { toast } from 'react-toastify';
import type { ApiErrorResponse } from '@/types/api';
import type { OfferMapByLegId } from '@/types/offer';
import { Loadable } from '@/components/Loadable';
import { Typography } from '@/components/primitives/Typography';

export const usePurchaseSteps = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(activeStepSelector);
  const currentSearchFormValues = useSelector(searchFormValuesSelector);
  const isBookingLoading = useSelector(
    purchaseFlowBookingFetchingLoadingSelector
  );

  const outboundData = useSelector(outboundDataSelector);
  const areOutboundJourneysLoading = useSelector(
    outboundJourneysLoadingSelector
  );

  const inboundData = useSelector(inboundDataSelector);
  const areInboundJourneysLoading = useSelector(inboundJourneysLoadingSelector);

  const nonTripOffers = useSelector(nonTripOffersSelector);

  const getDefaultOfferMap = useCallback(
    (journey: JourneyDTO) =>
      journey?.trips?.reduce<OfferMapByLegId>(
        (tripsAcc, { legs, offers }) => ({
          ...tripsAcc,
          ...legs?.reduce<OfferMapByLegId>((legsAcc, { id }) => {
            if (!id) {
              return legsAcc;
            }

            const firstMatchingOffer = offers?.find(({ coveredLegIds }) =>
              coveredLegIds?.includes(id)
            );

            return firstMatchingOffer
              ? { ...legsAcc, [id]: firstMatchingOffer }
              : legsAcc;
          }, {}),
        }),
        {}
      ) ?? {},
    []
  );

  useEffect(() => {
    if (outboundData.selectedJourney) {
      dispatch(
        setSelectedOutboundOfferMapByLegId(
          getDefaultOfferMap(outboundData.selectedJourney)
        )
      );
    }
  }, [dispatch, getDefaultOfferMap, outboundData.selectedJourney]);

  useEffect(() => {
    if (inboundData.selectedJourney) {
      dispatch(
        setSelectedInboundOfferMap(
          getDefaultOfferMap(inboundData.selectedJourney)
        )
      );
    }
  }, [dispatch, getDefaultOfferMap, inboundData.selectedJourney]);

  useEffect(() => {
    if (nonTripOffers.nonTripOffers) {
      dispatch(updateSelectedNonTripOffer(nonTripOffers.nonTripOffers[0]));
    }
  }, [dispatch, nonTripOffers.nonTripOffers]);

  const handleBookingCreationError = useCallback((error: ApiErrorResponse) => {
    const reason = error.response?.data.errors?.[0]?.message;

    toast.error(
      <div className="flex gap-1">
        <TransText i18nKey="failedToCreateBooking" />
        {reason && <Typography>{reason}</Typography>}
      </div>
    );
  }, []);

  const fetchBookingById = useCallback(
    async (createBookingResponse: CreateBookingResponseDTO) => {
      const id = createBookingResponse.booking?.id;

      if (id) {
        await dispatch(getPurchaseFlowBookingById(id)).unwrap();
        dispatch(setActiveStep(STEP.Checkout));
      }
    },
    [dispatch]
  );

  const handleCreatingAndFetchingTripOfferBooking = useCallback(async () => {
    try {
      const booking = await dispatch(
        createPurchaseFlowTripOfferBooking(
          currentSearchFormValues?.passengers ?? []
        )
      ).unwrap();

      await fetchBookingById(booking);
    } catch (e) {
      handleBookingCreationError(e as ApiErrorResponse);
    }
  }, [
    currentSearchFormValues?.passengers,
    dispatch,
    fetchBookingById,
    handleBookingCreationError,
  ]);

  const handleCreatingAndFetchingNonTripOfferBooking = useCallback(async () => {
    try {
      const booking = await dispatch(
        createPurchaseFlowNonTripOfferBooking(
          currentSearchFormValues?.passengers ?? []
        )
      ).unwrap();

      await fetchBookingById(booking);
    } catch (e) {
      handleBookingCreationError(e as ApiErrorResponse);
    }
  }, [
    currentSearchFormValues?.passengers,
    dispatch,
    fetchBookingById,
    handleBookingCreationError,
  ]);

  const steps: Record<STEP, ReactNode> = useMemo(
    () => ({
      [STEP.PreSearchSubmit]: (
        <Loadable loading={isBookingLoading}>
          <div />
        </Loadable>
      ),
      [STEP.OutboundJourney]: (
        <JourneyList
          {...outboundData}
          title={<TransText i18nKey="selectOutboundTrip" />}
          setSelectedJourney={setSelectedOutboundJourney}
          onContinue={() => dispatch(setActiveStep(STEP.OutboundOffer))}
          loading={areOutboundJourneysLoading}
        />
      ),
      [STEP.OutboundOffer]: (
        <JourneyDetails
          title={<TransText i18nKey="selectOutboundFare" />}
          selectedJourney={outboundData.selectedJourney}
          updateSelectedOfferMap={updateSelectedOutboundOfferMapByLegId}
          selectedOfferMapByLegIdSelector={selectedOutboundOfferMapSelector}
          onContinue={() => {
            if (inboundData.journeys) {
              return dispatch(setActiveStep(STEP.InboundJourney));
            }

            handleCreatingAndFetchingTripOfferBooking();
          }}
          onGoBack={() => dispatch(setActiveStep(STEP.OutboundJourney))}
        />
      ),
      [STEP.InboundJourney]: (
        <JourneyList
          {...inboundData}
          title={<TransText i18nKey="selectReturnTrip" />}
          setSelectedJourney={setSelectedInboundJourney}
          onContinue={() => dispatch(setActiveStep(STEP.InboundOffer))}
          onGoBack={() => dispatch(setActiveStep(STEP.OutboundOffer))}
          loading={areInboundJourneysLoading}
        />
      ),
      [STEP.InboundOffer]: (
        <JourneyDetails
          title={<TransText i18nKey="selectReturnFare" />}
          selectedJourney={inboundData.selectedJourney}
          updateSelectedOfferMap={updateSelectedInboundOfferMapByLegId}
          selectedOfferMapByLegIdSelector={selectedInboundOfferMapSelector}
          onContinue={handleCreatingAndFetchingTripOfferBooking}
          onGoBack={() => dispatch(setActiveStep(STEP.InboundJourney))}
        />
      ),
      [STEP.NonTripOffer]: (
        <NonTripOfferList
          {...nonTripOffers}
          onContinue={handleCreatingAndFetchingNonTripOfferBooking}
        />
      ),
      [STEP.Checkout]: <Checkout />,
    }),
    [
      isBookingLoading,
      outboundData,
      areOutboundJourneysLoading,
      inboundData,
      areInboundJourneysLoading,
      handleCreatingAndFetchingTripOfferBooking,
      nonTripOffers,
      handleCreatingAndFetchingNonTripOfferBooking,
      dispatch,
    ]
  );

  return {
    steps,
    activeStep,
  };
};
