import { tenantSelector } from '@/features/configuration/configurationSelector';
import { useTranslation } from '@/i18n';
import { useSelector } from '@/store/utils';
import { useEffect } from 'react';

type TitleType = 'purchase' | 'manageBooking' | 'login';

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const usePageTitle = (type: TitleType) => {
  const { t, ready } = useTranslation();
  const tenant = useSelector(tenantSelector);

  useEffect(() => {
    if (!ready) {
      return;
    }

    const getTitle = (translatedText: string) =>
      `${capitalizeFirstLetter(tenant.value)} | ${translatedText}`;

    switch (type) {
      case 'purchase': {
        document.title = getTitle(t('text.purchase'));
        return;
      }
      case 'manageBooking':
        document.title = getTitle(t('text.manageBooking'));
        return;
      case 'login':
        document.title = getTitle(t('text.logIn'));
        return;
      default:
        document.title = getTitle(t('text.purchase'));
    }
  }, [type, t, ready, tenant]);
};
