import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { Icons } from '@/components/icons';
import { Button } from '@/components/primitives/Button';
import { TransText } from '@/i18n/trans/text';

interface ShowMoreButtonProps {
  expanded: boolean;
  onShowMoreClick: () => void;
  onShowLessClick: () => void;
}

const ShowMoreButton: FC<ShowMoreButtonProps> = ({
  expanded,
  onShowMoreClick,
  onShowLessClick,
}) => (
  <Button
    variant="ghost"
    data-testid="read-more-button"
    className="px-0 py-1"
    onClick={expanded ? onShowLessClick : onShowMoreClick}
    type="button"
  >
    <Typography variant="button" className="flex items-center gap-1">
      {expanded ? (
        <>
          <TransText i18nKey="showLess" />
          <Icons.chevronUp height={16} width={16} />
        </>
      ) : (
        <>
          <TransText i18nKey="showMore" />
          <Icons.chevronDown height={16} width={16} />
        </>
      )}
    </Typography>
  </Button>
);

export default ShowMoreButton;
