import type { CheckoutValues } from '@/utils/zodSchema';
import isEmpty from 'lodash/isEmpty';
import { Fragment, type ReactNode } from 'react';
import { TransText } from '@/i18n/trans/text';

export const getPassengerName = (
  { firstName, lastName }: Partial<CheckoutValues['passengers'][number]>,
  index: number
) => {
  const fullName = [firstName, lastName].filter(Boolean).join(' ');

  return isEmpty(fullName) ? (
    <TransText i18nKey="passengerIndex" values={{ count: index + 1 }} />
  ) : (
    fullName
  );
};

export const getPassengerOtherDetails = ({
  age,
}: CheckoutValues['passengers'][number]) => {
  const details: Array<ReactNode> = [];

  if (age) {
    details.push(
      <TransText i18nKey="numberOfYearsOld" values={{ count: age }} />
    );
  }

  return details.map((detail, index) => (
    <Fragment key={index}>
      {index > 0 && ', '}
      {detail}
    </Fragment>
  ));
};
