import { useEffect, useRef, useState, type FC, type MouseEvent } from 'react';
import {
  Typography,
  type TypographyVariant,
} from '@/components/primitives/Typography';
import { Button } from '@/components/primitives/Button';
import { cn } from '@/utils/cn';
import { TransText } from '@/i18n/trans/text';
import { Icons } from '@/components/icons';

interface ExpandableTextProps {
  text: string | undefined;
  variant?: TypographyVariant;
  maxLines?: 1 | 2 | 3 | 4 | 5 | 6 | 'none';
  className?: string;
  isExpanded?: boolean;
  setIsExpanded?: (value: boolean) => void;
}

const ExpandableText: FC<ExpandableTextProps> = ({
  text,
  variant = 'body2',
  maxLines = 2,
  className,
  isExpanded: isControlledExpanded,
  setIsExpanded: setControlledIsExpanded,
}) => {
  const [isUncontrolledExpanded, setIsUncontrolledExpanded] = useState(false);
  const [isClamped, setIsClamped] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  const isExpanded = isControlledExpanded ?? isUncontrolledExpanded;
  const setIsExpanded = setControlledIsExpanded ?? setIsUncontrolledExpanded;

  const handleExpandedClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const checkClamping = () => {
      if (!textRef.current) return;
      const { scrollHeight, clientHeight } = textRef.current;
      setIsClamped(scrollHeight - 1 > clientHeight); // - 1 is a fix for Firefox
    };

    checkClamping();

    window.addEventListener('resize', checkClamping);
    return () => {
      window.removeEventListener('resize', checkClamping);
    };
  }, [text, isExpanded]);

  if (!text) {
    return null;
  }

  return (
    <div className={className}>
      <div
        ref={textRef}
        style={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: isExpanded ? 'none' : maxLines,
        }}
        className="transition-all duration-300"
      >
        <Typography variant={variant}>{text}</Typography>
      </div>
      {isClamped && (
        <Button
          variant="ghost"
          data-testid="read-more-button"
          className={cn('h-auto px-0 py-0', {
            'mb-3': isExpanded,
          })}
          onClick={handleExpandedClick}
        >
          <Typography variant={variant} className="flex items-center gap-1">
            <TransText i18nKey="readMore" />
            <Icons.chevronDown height={12} width={12} />
          </Typography>
        </Button>
      )}
      {isExpanded && (
        <Button
          variant="ghost"
          data-testid="read-more-button"
          className={cn('h-auto px-0 py-0', {
            'mb-3': isExpanded,
          })}
          onClick={handleExpandedClick}
        >
          <Typography variant={variant} className="flex items-center gap-1">
            <TransText i18nKey="showLess" />
            <Icons.chevronUp height={12} width={12} />
          </Typography>
        </Button>
      )}
    </div>
  );
};

export default ExpandableText;
