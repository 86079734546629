import type { FC } from 'react';
import type { AdmissionDTO, BookedTripDTO } from '@/types/dto';
import HighlightedRow from '@/components/booking-summary/HighlightedRow';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { isSameDay } from 'date-fns';
import {
  formatDateWithDayOfWeek,
  formatDay,
  formatTime,
} from '@/utils/date-time';
import { cn } from '@/utils/cn';
import { getPtModeIconName } from '@/utils/icon';
import { TransportationTypeIcon } from '@/components/icons/TransportationTypeIcon';
import { useBookingNotExchangedTrips } from '@/hooks/useBookingNotExchangedTrips';
import { type Flow } from '@/types/booking';

interface TripDescriptionProps {
  trips: Array<BookedTripDTO>;
  direction: 'outbound' | 'inbound';
  flow: Flow;
}

const TripDescription: FC<TripDescriptionProps> = ({
  trips,
  flow,
  direction,
}) => {
  const notExchangedTrips = useBookingNotExchangedTrips(trips, flow);

  const doesEveryAdmissionHaveStatus = (
    tripIndex: number,
    legIndex: number,
    status: AdmissionDTO['status']
  ) => {
    const bookedOffer = notExchangedTrips[tripIndex].bookedOffers?.[legIndex];
    if (!bookedOffer) return false;

    return bookedOffer.admissions?.every(
      (admission) => admission.status === status
    );
  };

  return (
    <div>
      <HighlightedRow
        className={cn('px-3', { ['rounded-t-lg']: direction === 'outbound' })}
      >
        <div className="flex flex-row gap-2">
          {/* FIXME: transportReturn and transportFrom default icon sizes dont match */}
          {direction === 'outbound' ? (
            <Icons.transportFrom className="h-5 w-5 text-primary" />
          ) : (
            <Icons.transportReturn className="h-5 w-5 text-primary" />
          )}
          <Typography variant="body1-bold">
            <TransText
              i18nKey={
                direction === 'outbound' ? 'outboundTrip' : 'inboundTrip'
              }
            />
          </Typography>
        </div>
        <Typography variant="body2" className="text-neutral">
          {formatDateWithDayOfWeek(notExchangedTrips[0].departureTime)}
        </Typography>
      </HighlightedRow>
      {notExchangedTrips.map((trip, tripIndex) =>
        trip.legs?.map((leg, legIndex) => {
          const isExchanged = doesEveryAdmissionHaveStatus(
            tripIndex,
            legIndex,
            'EXCHANGED'
          );
          const isRefunded = doesEveryAdmissionHaveStatus(
            tripIndex,
            legIndex,
            'REFUNDED'
          );

          if (isExchanged) {
            return null;
          }

          return (
            <div className="flex flex-row gap-2 px-4 py-3" key={legIndex}>
              <div className="flex w-fit flex-col">
                <div className="h-4 w-4">
                  <TransportationTypeIcon
                    ptModeIcon={getPtModeIconName(leg.ptMode)}
                    className={cn('mx-auto text-primary', {
                      'text-neutral': isRefunded,
                    })}
                  />
                </div>
                <div className="mx-auto h-full w-0.5 bg-neutral-light" />
                <div className="h-4 w-4">
                  <Icons.destination
                    height={12}
                    width={12}
                    className={cn('mx-auto text-primary', {
                      'text-neutral': isRefunded,
                    })}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <Typography
                    variant="body2-bold"
                    className={cn('text-dark', {
                      'text-neutral line-through decoration-error-border':
                        isRefunded,
                    })}
                  >
                    {leg.originStop?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={cn('text-dark', {
                      'text-neutral line-through decoration-error-border':
                        isRefunded,
                    })}
                  >
                    {formatTime(leg.departureTime)}
                  </Typography>
                </div>
                <div className="flex flex-row justify-between">
                  <Typography
                    variant="body2-bold"
                    className={cn('text-dark', {
                      'text-neutral line-through decoration-error-border':
                        isRefunded,
                    })}
                  >
                    {leg.destinationStop?.name}
                  </Typography>
                  <div className="flex gap-2">
                    {leg.departureTime &&
                      leg.arrivalTime &&
                      !isSameDay(leg.departureTime, leg.arrivalTime) && (
                        <Typography
                          variant="body2"
                          className={cn('text-dark', {
                            'text-neutral line-through decoration-error-border':
                              isRefunded,
                          })}
                        >
                          {formatDay(leg.arrivalTime)}
                        </Typography>
                      )}
                    <Typography
                      variant="body2"
                      className={cn('text-dark', {
                        'text-neutral line-through decoration-error-border':
                          isRefunded,
                      })}
                    >
                      {formatTime(leg.arrivalTime)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default TripDescription;
