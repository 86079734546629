import { combineReducers } from '@reduxjs/toolkit';
import { loadingReducer } from '@/features/loading/loadingReducer';
import { configurationReducer } from '@/features/configuration/configurationReducer';
import { purchaseReducer } from '@/features/purchase/purchaseReducer';
import { manageReducer } from '@/features/manage/manageReducer';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createFilter } from 'redux-persist-transform-filter';
import { profileReducer } from '@/features/profile/profileReducer';

const rootReducer = combineReducers({
  purchase: purchaseReducer,
  loading: loadingReducer,
  configuration: configurationReducer,
  manage: manageReducer,
  profile: profileReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const persistedReducer = persistReducer<RootState>(
  {
    key: 'root',
    storage,
    whitelist: ['purchase'],
    transforms: [createFilter('purchase', ['booking.id'])],
    stateReconciler: autoMergeLevel2,
  },
  rootReducer
);
