import { createLoadingSelector } from '@/store/utils';
import {
  addPurchaseFlowBookingAncillary,
  createPurchaseFlowNonTripOfferBooking,
  createPurchaseFlowTripOfferBooking,
  deletePurchaseFlowBooking,
  getInboundJourneys,
  getNonTripOffers,
  getOutboundJourneys,
  getPurchaseFlowBookingById,
  payWithExternalPayment,
  removePurchaseFlowBookingAncillary,
  updatePurchaseFlowBookingPassengers,
  updatePurchaseFlowBookingPurchaser,
} from '@/features/purchase/purchaseActions';
import {
  deleteRefundOffer,
  getManageFlowBookingByNumberAndEmail,
  initiateLegsRefund,
  initiateNonTripOffersRefund,
} from '@/features/manage/manageActions';
import {
  getConsents,
  getConfiguration,
} from '@/features/configuration/configurationActions';

export const purchaseFlowBookingFetchingLoadingSelector = createLoadingSelector(
  getPurchaseFlowBookingById
);

export const outboundJourneysLoadingSelector =
  createLoadingSelector(getOutboundJourneys);

export const inboundJourneysLoadingSelector =
  createLoadingSelector(getInboundJourneys);

export const nonTripOffersLoadingSelector =
  createLoadingSelector(getNonTripOffers);

export const purchaseFlowBookingCreationLoadingSelector = createLoadingSelector(
  createPurchaseFlowTripOfferBooking,
  createPurchaseFlowNonTripOfferBooking,
  getPurchaseFlowBookingById
);

export const addAncillaryLoadingSelector = createLoadingSelector(
  addPurchaseFlowBookingAncillary
);

export const checkoutLoadingSelector = createLoadingSelector(
  addPurchaseFlowBookingAncillary,
  removePurchaseFlowBookingAncillary,
  getPurchaseFlowBookingById,
  payWithExternalPayment,
  deletePurchaseFlowBooking,
  updatePurchaseFlowBookingPassengers,
  updatePurchaseFlowBookingPurchaser
);

export const manageBookingLoadingSelector = createLoadingSelector(
  getManageFlowBookingByNumberAndEmail
);

export const refundLoadingSelector = createLoadingSelector(
  initiateLegsRefund,
  initiateNonTripOffersRefund,
  deleteRefundOffer
);

export const configurationLoadingSelector = createLoadingSelector(
  getConfiguration,
  getConsents
);
