import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import PassengerForm from '@/components/purchase/checkout/passengers/PassengerForm';
import type { CheckoutValues } from '@/utils/zodSchema';

interface PassengerListProps {}

const PassengerList: FC<PassengerListProps> = () => {
  const { control } = useFormContext<CheckoutValues>();
  const { fields } = useFieldArray({
    control,
    name: 'passengers',
  });

  return fields.map(({ id }, index) => (
    <PassengerForm key={id} index={index} />
  ));
};

export default PassengerList;
