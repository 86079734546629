import type { FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { formatDay, formatYear } from '@/utils/date-time';
import type { MultiRideAccountDTO } from '@/types/dto';
import { cn } from '@/utils/cn';

interface MultiRideAccountValidityProps {
  validFrom: MultiRideAccountDTO['validFrom'];
  validUntil: MultiRideAccountDTO['validUntil'];
  balance: MultiRideAccountDTO['balance'];
  isRefunded?: boolean;
}

const MultiRideAccountValidity: FC<MultiRideAccountValidityProps> = ({
  validFrom,
  validUntil,
  balance,
  isRefunded,
}) => {
  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-col gap-1">
        <div
          className="flex items-center gap-2"
          data-testid="multi-ride-valid-from-label"
        >
          <Typography
            variant="body2"
            className="w-[70px] text-nowrap text-neutral"
          >
            <TransText i18nKey="validFrom" />:
          </Typography>
          <div className="flex gap-1" data-testid="multi-ride-valid-from-value">
            <Typography
              variant="body2-bold"
              className={cn('text-nowrap', {
                'text-neutral': isRefunded,
              })}
            >
              {formatDay(validFrom)},
            </Typography>
            <Typography
              variant="body2"
              className={cn({
                'text-neutral': isRefunded,
              })}
            >
              {formatYear(validFrom)}
            </Typography>
          </div>
        </div>
        <div
          className="flex items-center gap-2"
          data-testid="multi-ride-valid-to-label"
        >
          <Typography
            variant="body2"
            className="w-[70px] text-nowrap text-neutral"
          >
            <TransText i18nKey="validTo" />:
          </Typography>
          <div className="flex gap-1" data-testid="multi-ride-valid-to-value">
            <Typography
              variant="body2-bold"
              className={cn('text-nowrap', {
                'text-neutral line-through': isRefunded,
              })}
            >
              {formatDay(validUntil)},
            </Typography>
            <Typography
              variant="body2"
              className={cn({
                'text-neutral line-through': isRefunded,
              })}
            >
              {formatYear(validUntil)}
            </Typography>
          </div>
        </div>
      </div>
      <div
        className="flex items-center gap-1.5"
        data-testid="multi-ride-remaining-uses-label"
      >
        <Typography variant="body2" className="text-nowrap text-neutral">
          <TransText i18nKey="remainingUses" />:
        </Typography>
        <div
          data-testid="multi-ride-remaining-uses-value"
          className="flex items-center"
        >
          <Typography
            variant="body2-bold"
            className={cn('text-nowrap', {
              'text-neutral line-through': isRefunded,
            })}
          >
            {balance.remaining}
          </Typography>
          <Typography
            variant="body2"
            className={cn('text-nowrap', {
              'text-neutral line-through': isRefunded,
            })}
          >
            /{balance.total}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default MultiRideAccountValidity;
