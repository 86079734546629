import type { Dispatch, FC, SetStateAction } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import { Button } from '@/components/primitives/Button';
import { Icons } from '@/components/icons';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';
import { useFormContext } from 'react-hook-form';
import Divider from '@/components/primitives/Divider';
import type { AncillaryValues } from '@/utils/zodSchema';

interface RegularAncillaryModalFooterProps {
  totalPrice: number;
  setIsOverlayOpened: Dispatch<SetStateAction<boolean>>;
}

const RegularAncillaryModalFooter: FC<RegularAncillaryModalFooterProps> = ({
  totalPrice,
  setIsOverlayOpened,
}) => {
  const currency = useSelector(currencySelector);
  const { watch } = useFormContext<AncillaryValues>();
  const selectedLegId = watch('selectedLegId');
  const selectedAncillaries = watch('selectedAncillaries');

  return (
    <div className="w-full">
      <Divider variant="medium" />
      <div className="flex w-full items-center justify-between px-6 py-3">
        <div className="flex gap-2">
          <Icons.travelPass height={20} width={20} className="text-primary" />
          <Typography variant="body1" className="text-neutral">
            <TransText i18nKey="totalPrice" />:
          </Typography>
          <Typography variant="subtitle" className="text-primary">
            {totalPrice}
            {currency.symbol}
          </Typography>
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="tertiary"
            data-testid="ancillary-selection-cancel-button"
            className="w-1/2 rounded-lg laptop:w-auto"
            onClick={() => setIsOverlayOpened(false)}
            type="button"
          >
            <Typography variant="button">
              <TransText i18nKey="cancel" />
            </Typography>
          </Button>
          <Button
            data-testid="ancillary-selection-submit-button"
            className="w-1/2 gap-2 rounded-lg p-4 laptop:w-auto"
            disabled={!selectedLegId || selectedAncillaries.length === 0}
            type="submit"
          >
            <Icons.check height={16} width={16} />
            <Typography variant="button">
              <TransText i18nKey={selectedLegId ? 'confirm' : 'selectLeg'} />
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RegularAncillaryModalFooter;
