import * as React from 'react';
import type { ReactNode } from 'react';
import type * as LabelPrimitive from '@radix-ui/react-label';
import { Slot } from '@radix-ui/react-slot';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';

import { cn } from '@/utils/cn';
import { Label } from '@/components/primitives/Label';
import { typographyVariants } from '@/components/primitives/Typography';
import _kebabCase from 'lodash/kebabCase';
import { TransText } from '@/i18n/trans/text';

const Form = FormProvider;

interface FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
}

const FormFieldContext = React.createContext<FormFieldContextValue | null>(
  null
);

const useFormFieldIds = (name: string) => {
  return React.useMemo(
    () => ({
      formItemId: `${name}-form-item`,
      formDescriptionId: `${name}-form-description`,
      formMessageId: `${name}-form-message`,
    }),
    [name]
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const { getFieldState, formState } = useFormContext();

  if (!fieldContext) {
    throw new Error('useFormField must be used within a FormField component');
  }

  const fieldState = getFieldState(fieldContext.name, formState);
  const ids = useFormFieldIds(fieldContext.name);

  return {
    name: fieldContext.name,
    ...ids,
    ...fieldState,
  };
};

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return <div ref={ref} className={className} {...props} />;
});
FormItem.displayName = 'FormItem';

interface FormLabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> {
  required?: boolean;
}

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  FormLabelProps
>(({ className, children, required, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(
        typographyVariants({
          variant: 'body2',
          className: 'text-neutral',
        }),
        className,
        {
          ['text-error-text']: Boolean(error),
        }
      )}
      htmlFor={formItemId}
      {...props}
    >
      {children}
      {!required && (
        <span className="ml-0.5">
          (<TransText i18nKey="optional" />)
        </span>
      )}
    </Label>
  );
});
FormLabel.displayName = 'FormLabel';

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot> & {
    label: ReactNode;
    hideLabel?: boolean;
    required?: boolean;
  }
>(({ label, hideLabel, required, ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId, name } =
    useFormField();

  return (
    <>
      <FormLabel
        className={cn('mb-1.5', { 'sr-only': hideLabel })}
        required={required}
      >
        {label}
      </FormLabel>
      <Slot
        ref={ref}
        id={formItemId}
        aria-describedby={
          !error ? formDescriptionId : `${formDescriptionId} ${formMessageId}`
        }
        aria-invalid={!!error}
        {...props}
      />
      <FormMessage
        className="mt-1"
        data-testid={`${_kebabCase(name)}-input-error`}
      />
    </>
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn(
        typographyVariants({
          variant: 'label',
          className: 'text-neutral-light',
        }),
        className
      )}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn(
        typographyVariants({
          variant: 'label',
          className: 'text-neutral',
        }),
        className,
        { 'text-error-text': Boolean(error) }
      )}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = 'FormMessage';

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
};
