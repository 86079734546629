import { issuersSelector } from '@/features/configuration/configurationSelector';
import { useBooking } from '@/hooks/useBooking';
import { useSelector } from '@/store/utils';
import type { Flow } from '@/types/booking';
import type {
  BookingDTO,
  MultiRideAccountDTO,
  ReductionCardAccountDTO,
  TravelPassAccountDTO,
} from '@/types/dto';
import type {
  DecoratedTravelPassDetails,
  PartitionedTravelPassDetails,
  TravelAccountDTO,
} from '@/types/manage';
import { useMemo } from 'react';

export const useBookingNonTripOfferDetails = (flow: Flow) => {
  const booking = useBooking(flow);
  const issuers = useSelector(issuersSelector);

  return useMemo(
    () => partitionByAccountType(prepareNonTripOffers(booking, issuers)),
    [booking, issuers]
  );
};

const prepareNonTripOffers = (
  booking: BookingDTO | null,
  issuers: Record<string, TravelAccountDTO>
): Array<DecoratedTravelPassDetails> => {
  const result = [];

  for (const nonTripOffer of booking?.nonTripOffers || []) {
    for (const admission of nonTripOffer.admissions || []) {
      for (const fulfillment of admission.fulfillments || []) {
        result.push({
          id: admission.id,
          productDescriptiveTexts: admission.productDescriptiveTexts,
          shortDescription:
            admission.productDescriptiveTexts?.[0].shortDescription,
          productDescription: admission.productDescription,
          exchangeable: admission.exchangeable,
          refundable: admission.refundable,
          controlNumber: fulfillment.controlNumber,
          issuer: fulfillment.issuer,
          price: admission.price,
          bookingPassengers: booking?.passengers ?? [],
          admissionPassengerIds: admission.passengerIds,
          validFrom: admission.validFrom,
          validUntil: admission.validUntil,
          status: admission.status,
          travelAccount: fulfillment.issuer
            ? issuers[fulfillment.issuer]
            : undefined,
        });
      }
    }
  }

  return result;
};

const partitionByAccountType = (
  details: Array<DecoratedTravelPassDetails>
): PartitionedTravelPassDetails => {
  const multiRide = [];
  const travelPass = [];
  const reductionCard = [];

  for (const item of details) {
    switch (item.travelAccount?.objectType) {
      case 'MultiRideAccount':
        multiRide.push(
          item as unknown as DecoratedTravelPassDetails<MultiRideAccountDTO>
        );
        break;
      case 'TravelPassAccount':
        travelPass.push(
          item as unknown as DecoratedTravelPassDetails<TravelPassAccountDTO>
        );
        break;
      case 'ReductionCardAccount':
        reductionCard.push(
          item as unknown as DecoratedTravelPassDetails<ReductionCardAccountDTO>
        );
        break;
      default:
        break;
    }
  }

  return {
    multiRide,
    travelPass,
    reductionCard,
  };
};
