import * as React from 'react';

import { cn } from '@/utils/cn';
import { typographyVariants } from '@/components/primitives/Typography';
import type { ReactNode } from 'react';
import _kebabCase from 'lodash/kebabCase';

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'className'> {
  endAdornment?: ReactNode;
  classNames?: { wrapper?: string; input?: string };
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ classNames, type, endAdornment, ...props }, ref) => {
    return (
      <div
        className={cn('relative flex h-10 items-center', classNames?.wrapper)}
      >
        <input
          type={type}
          className={cn(
            typographyVariants({ variant: 'body1' }),
            'flex h-full w-full rounded-sm border border-neutral-medium bg-white px-3 py-2 text-dark file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral hover:border-primary-medium focus-visible:border-primary focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-primary disabled:cursor-not-allowed disabled:border-neutral-light disabled:bg-greyscale-100 disabled:placeholder:text-neutral-medium',
            classNames?.input
          )}
          ref={ref}
          data-testid={`${_kebabCase(props.name)}-input-field`}
          {...props}
        />
        {endAdornment && <div className="absolute right-2">{endAdornment}</div>}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
