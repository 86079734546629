import getApiInstance from '@/api';
import { type BookingDTO } from '@/types/dto';

export const fetchInitiateRefund = async (
  bookingId: BookingDTO['id'],
  fulfillmentIds: Array<string>
) => {
  if (!bookingId) {
    return null;
  }

  const api = (await getApiInstance()).agentApi;
  const response = await api.RefundOffers_InitiateRefund(
    { bookingId },
    {
      fulfillmentIds: [fulfillmentIds[0], ...fulfillmentIds.slice(1)],
    }
  );

  return response.data;
};
