import { createReducer } from '@reduxjs/toolkit';
import {
  clearManageFlowBooking,
  deleteRefundOffer,
  getManageFlowBookingById,
  getManageFlowBookingByNumberAndEmail,
  initiateLegsRefund,
  resetRefundSelection,
  toggleRefundLegAdmission,
  toggleRefundLeg,
  toggleRefundNonTripAdmission,
  initiateNonTripOffersRefund,
} from '@/features/manage/manageActions';
import type { AdmissionDTO, BookingDTO, RefundOffersDTO } from '@/types/dto';

type ManageBookingState = {
  refund: {
    selectedLegAdmissions: { [legId: string]: Array<AdmissionDTO> };
    selectedNonTripAdmissions: { [admissionId: string]: AdmissionDTO };
    details: RefundOffersDTO | null;
  };
  booking: BookingDTO | null;
};

const initialState: ManageBookingState = {
  refund: {
    selectedLegAdmissions: {},
    selectedNonTripAdmissions: {},
    details: null,
  },
  booking: null,
};

export const manageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(toggleRefundLeg, (state, action) => {
      const { legId, admissions } = action.payload;
      if (admissions.length > 0) {
        state.refund.selectedLegAdmissions[legId] = admissions;
      } else {
        state.refund.selectedLegAdmissions = Object.fromEntries(
          Object.entries(state.refund.selectedLegAdmissions).filter(
            ([key]) => key !== legId
          )
        );
      }
    })
    .addCase(toggleRefundLegAdmission, (state, action) => {
      const { legId, admissions } = action.payload;
      if (admissions.length > 0) {
        state.refund.selectedLegAdmissions[legId] = admissions;
      } else {
        state.refund.selectedLegAdmissions = Object.fromEntries(
          Object.entries(state.refund.selectedLegAdmissions).filter(
            ([key]) => key !== legId
          )
        );
      }
    })
    .addCase(toggleRefundNonTripAdmission, (state, action) => {
      const admissionId = action.payload.id;

      if (!admissionId) {
        return;
      }

      if (state.refund.selectedNonTripAdmissions[admissionId]) {
        state.refund.selectedNonTripAdmissions = Object.fromEntries(
          Object.entries(state.refund.selectedNonTripAdmissions).filter(
            ([key]) => key !== admissionId
          )
        );
      } else {
        state.refund.selectedNonTripAdmissions[admissionId] = action.payload;
      }
    })
    .addCase(resetRefundSelection, (state) => {
      state.refund.selectedLegAdmissions = {};
      state.refund.selectedNonTripAdmissions = {};
      state.refund.details = null;
    })
    .addCase(initiateLegsRefund.fulfilled, (state, action) => {
      state.refund.details = action.payload;
    })
    .addCase(initiateNonTripOffersRefund.fulfilled, (state, action) => {
      state.refund.details = action.payload;
    })
    .addCase(deleteRefundOffer.fulfilled, (state) => {
      state.refund.details = null;
    })
    .addCase(
      getManageFlowBookingByNumberAndEmail.fulfilled,
      (state, action) => {
        state.booking = action.payload;
      }
    )
    .addCase(getManageFlowBookingById.fulfilled, (state, action) => {
      state.booking = action.payload;
    })
    .addCase(clearManageFlowBooking, (state) => {
      Object.assign(state, {
        ...initialState,
      });
    });
});
