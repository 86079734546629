import type {
  ProductRequirementsMap,
  BaseFieldsRequirements,
} from '@/types/fieldRequirements';
import type { TenantOption } from '@/types/tenant';

const baseRequirements: BaseFieldsRequirements = {
  firstName: { required: true },
  lastName: { required: true },
  email: { required: true },
  phone: { required: true },
};

const turnitFieldRequirements = {
  ticket: {
    passenger: baseRequirements,
    purchaser: baseRequirements,
  },
  travelPass: {
    passenger: baseRequirements,
    purchaser: baseRequirements,
  },
};

const arenawaysFieldRequirements = {
  ticket: {
    passenger: {
      ...baseRequirements,
      phone: { required: false },
    },
    purchaser: baseRequirements,
  },
  travelPass: {
    passenger: baseRequirements,
    purchaser: baseRequirements,
  },
};

export const tenantFieldRequirementsMap: Record<
  TenantOption['value'],
  ProductRequirementsMap
> = {
  turnit: turnitFieldRequirements,
  arenaways: arenawaysFieldRequirements,
};
