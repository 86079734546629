import { STORAGE_KEYS } from '@/hooks/useStorage';

export const getAccessToken = () => {
  const token = sessionStorage.getItem(STORAGE_KEYS.ACCESS_TOKEN);
  if (!token) return null;
  try {
    return JSON.parse(token);
  } catch {
    return null;
  }
};

export const getRefreshToken = () => {
  const token = sessionStorage.getItem(STORAGE_KEYS.REFRESH_TOKEN);
  if (!token) return null;
  try {
    return JSON.parse(token);
  } catch {
    return null;
  }
};

export const getTokenExpiry = () => {
  const expiry = sessionStorage.getItem(STORAGE_KEYS.TOKEN_EXPIRY);
  if (!expiry) return null;
  try {
    return JSON.parse(expiry);
  } catch {
    return null;
  }
};

export const setAccessToken = (token: string | null) => {
  if (token === null) {
    sessionStorage.removeItem(STORAGE_KEYS.ACCESS_TOKEN);
  } else {
    sessionStorage.setItem(STORAGE_KEYS.ACCESS_TOKEN, JSON.stringify(token));
  }
};

export const setRefreshToken = (token: string | null) => {
  if (token === null) {
    sessionStorage.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  } else {
    sessionStorage.setItem(STORAGE_KEYS.REFRESH_TOKEN, JSON.stringify(token));
  }
};

export const setTokenExpiry = (expiry: number | null) => {
  if (expiry === null) {
    sessionStorage.removeItem(STORAGE_KEYS.TOKEN_EXPIRY);
  } else {
    sessionStorage.setItem(STORAGE_KEYS.TOKEN_EXPIRY, JSON.stringify(expiry));
  }
};

export const setTokens = (
  accessToken?: string,
  refreshToken?: string,
  expiresIn?: number
) => {
  if (accessToken) {
    setAccessToken(accessToken);
  }
  if (refreshToken) {
    setRefreshToken(refreshToken);
  }
  if (expiresIn) {
    setTokenExpiry(Date.now() + expiresIn * 1000);
  }
};

export const clearTokens = () => {
  setAccessToken(null);
  setRefreshToken(null);
  setTokenExpiry(null);
};
