import type { CustomerProfileDetailsResponseDTO } from '@/types/dto';

export const getDisplayName = (
  profile?: Pick<
    CustomerProfileDetailsResponseDTO,
    'firstName' | 'lastName' | 'email'
  > | null
) => {
  if (!profile) return;

  const { firstName, lastName, email } = profile;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName || lastName) {
    const firstInitial = firstName?.[0] || '';
    const lastInitial = lastName?.[0] || '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  }

  if (!email) return '';

  const [localPart] = email.split('@');
  const parts = localPart.split(/[._-]/);
  return parts
    .slice(0, 2)
    .map((part) => part[0])
    .join('')
    .toUpperCase();
};
