import NonTripOfferValidity from '@/components/manage/travel-passes/NonTripOfferValidity';
import { Checkbox } from '@/components/primitives/Checkbox';
import Divider from '@/components/primitives/Divider';
import { Typography } from '@/components/primitives/Typography';
import Refundability from '@/components/purchase/card/Refundability';
import type { MultiRideAccountDTO, TravelPassAccountDTO } from '@/types/dto';
import { type DecoratedTravelPassDetails } from '@/types/manage';
import { cn } from '@/utils/cn';
import { getDisplayName } from '@/utils/profile';
import type { FC } from 'react';

interface NonTripOfferSelectionCardProps {
  nonTripOffer: DecoratedTravelPassDetails<
    MultiRideAccountDTO | TravelPassAccountDTO
  >;
  isSelected: boolean;
  onSelect: () => void;
}

export const NonTripOfferSelectionCard: FC<NonTripOfferSelectionCardProps> = ({
  nonTripOffer,
  isSelected,
  onSelect,
}) => {
  const isRefundable = nonTripOffer.refundable === 'YES';
  const shortDescription =
    nonTripOffer.productDescriptiveTexts?.[0].shortDescription;
  const zone =
    nonTripOffer.travelAccount?.regionalConstraint?.regionalValidities[0].zone;
  const origin = zone?.entryStation?.name;
  const destination = zone?.terminalStation?.name;
  const fullName = getDisplayName(nonTripOffer.travelAccount?.holder);

  const handleToggle = () => {
    if (isRefundable) {
      onSelect();
    }
  };

  return (
    <div
      className={cn(
        'cursor-pointer rounded-sm border border-neutral-light p-3',
        'laptop:hover:border-primary-medium laptop:hover:bg-primary-pale',
        {
          'border-primary bg-primary-light': isSelected && isRefundable,
          'cursor-not-allowed bg-greyscale-100 laptop:hover:border-neutral-light laptop:hover:bg-greyscale-100':
            !isRefundable,
        }
      )}
      onClick={handleToggle}
      data-state={isSelected ? 'checked' : 'unchecked'}
    >
      <div className="mb-2 flex items-center gap-3">
        <Checkbox
          checked={isSelected}
          onClick={handleToggle}
          disabled={!isRefundable}
        />
        <Typography
          variant="subtitle"
          className={cn({ 'text-neutral': !isRefundable })}
        >
          {shortDescription}
        </Typography>
      </div>
      <div className="pl-7">
        <div
          className={cn(
            'mb-4 flex flex-row flex-wrap items-center text-primary',
            { 'text-neutral': !isRefundable }
          )}
        >
          <Typography variant="body1-bold" className="min-w-fit">
            {origin}
          </Typography>
          <div className="mx-1.5 shrink-0">—</div>
          <Typography variant="body1-bold" className="min-w-fit">
            {destination}
          </Typography>
        </div>
        <NonTripOfferValidity data={nonTripOffer} />
      </div>
      <Divider className="mb-3 mt-4" />
      <div className="flex justify-between">
        <Typography
          variant="body1-bold"
          className={cn({ 'text-neutral': !isRefundable })}
        >
          {fullName}
        </Typography>
        <Refundability condition={isRefundable} />
      </div>
    </div>
  );
};
