import { ProtectedRoute } from '@/components/ProtectedRoute';
import { PublicRoute } from '@/components/PublicRoute';
import type { ComponentType, PropsWithChildren } from 'react';

type RouteType = 'protected' | 'loginOrSignup';

export const withAuthGuard = (type: RouteType) => {
  return <P extends object>(Component: ComponentType<P>) => {
    const WithAuthGuard = (props: PropsWithChildren<P>) => {
      return type === 'protected' ? (
        <ProtectedRoute>
          <Component {...props} />
        </ProtectedRoute>
      ) : (
        <PublicRoute>
          <Component {...props} />
        </PublicRoute>
      );
    };

    WithAuthGuard.displayName = `withAuthGuard(${Component.displayName || Component.name || 'Component'})`;
    return WithAuthGuard;
  };
};
