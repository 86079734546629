import type { BackgroundTheme, BrandColors, HSLString } from '@/types/color';
import type { TenantOption } from '@/types/tenant';

export const TURNIT_BRAND_COLORS: BrandColors = {
  dark: '#010845',
  primary: '#003FBF',
  secondary: '#003FBF',
  secondaryContrast: '#FFFFFF',
  tint: '#8619FF',
  accent: '#FFF250',
};

export const ARENAWAYS_BRAND_COLORS: BrandColors = {
  dark: '#040065',
  primary: '#17136C',
  secondary: '#FFBA2A',
  secondaryContrast: '#040065',
  tint: '#005EC2',
  accent: '#F5A501',
};

export const setGlobalStyleProperties = (
  styleProperties: Record<string, string>,
  backgroundTheme: BackgroundTheme
) => {
  Object.entries(styleProperties).forEach(([key, value]) => {
    document.documentElement.style.setProperty(`--${key}`, value);
  });

  if (isLightBackground(backgroundTheme)) {
    document.body.classList.remove('text-white');
    document.body.classList.add('text-dark');
  } else {
    document.body.classList.remove('text-dark');
    document.body.classList.add('text-white');
  }
};

export const hexToHSL = (hex: string): HSLString => {
  // Convert hex to RGB
  const r: number = parseInt(hex.slice(1, 3), 16) / 255;
  const g: number = parseInt(hex.slice(3, 5), 16) / 255;
  const b: number = parseInt(hex.slice(5, 7), 16) / 255;

  // Find min and max RGB components
  const cmin: number = Math.min(r, g, b);
  const cmax: number = Math.max(r, g, b);
  const delta: number = cmax - cmin;

  let h: number, s: number, l: number;

  // Calculate hue
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Convert to percentages
  s = s * 100;
  l = l * 100;

  return `${Math.round(h)}, ${Math.round(s)}%, ${Math.round(l)}%`;
};

export const createBrandColorTint = (
  hex: string,
  lightnessChange: number,
  lightnessMode: 'add' | 'subtract' | 'set' = 'set',
  saturationSubtractionAmount?: number
): HSLString => {
  const hslString = hexToHSL(hex);
  const [h, s, l] = hslString.match(/\d+/g)!.map(Number);
  let newColorLightness: number;

  const newColorSaturation: number =
    saturationSubtractionAmount !== undefined
      ? Math.max(0, Math.min(100, s - saturationSubtractionAmount))
      : s;

  switch (lightnessMode) {
    case 'add':
      newColorLightness = Math.min(100, l + lightnessChange);
      break;
    case 'subtract':
      newColorLightness = Math.max(0, l - lightnessChange);
      break;
    case 'set':
    default:
      newColorLightness = Math.max(0, Math.min(100, lightnessChange));
      break;
  }

  return `${Math.round(h)}, ${Math.round(newColorSaturation)}%, ${Math.round(newColorLightness)}%`;
};

export const getCalculatedStyleProperties = (
  brandColors: BrandColors
): Record<string, HSLString> => ({
  primary: hexToHSL(brandColors.primary),
  'primary-dark': createBrandColorTint(brandColors.primary, 15, 'subtract'),
  'primary-medium': createBrandColorTint(brandColors.primary, 5, 'add', 10),
  'primary-light': createBrandColorTint(brandColors.primary, 95, 'set'),
  'primary-pale': createBrandColorTint(brandColors.primary, 97, 'set'),

  secondary: hexToHSL(brandColors.secondary),
  'secondary-contrast': hexToHSL(brandColors.secondaryContrast),
  'secondary-dark': createBrandColorTint(brandColors.secondary, 15, 'subtract'),
  'secondary-medium': createBrandColorTint(brandColors.secondary, 5, 'add', 10),
  'secondary-light': createBrandColorTint(brandColors.secondary, 95, 'set'),
  'secondary-pale': createBrandColorTint(brandColors.secondary, 97, 'set'),

  tint: hexToHSL(brandColors.tint),
  'tint-light': createBrandColorTint(brandColors.tint, 95, 'set'),
  'tint-pale': createBrandColorTint(brandColors.tint, 97, 'set'),

  accent: hexToHSL(brandColors.accent),
  'accent-light': createBrandColorTint(brandColors.accent, 73, 'set'),
  'accent-pale': createBrandColorTint(brandColors.accent, 83, 'set'),

  neutral: createBrandColorTint(brandColors.dark, 45, 'set', 75),
  'neutral-medium': createBrandColorTint(brandColors.dark, 60, 'set', 80),
  'neutral-light': createBrandColorTint(brandColors.dark, 80, 'set', 80),
  'neutral-pale': createBrandColorTint(brandColors.dark, 93, 'set', 75),

  'greyscale-800': hexToHSL('#474747'),
  'greyscale-100': hexToHSL('#F9F9F9'),

  'success-border': hexToHSL('#008E4C'),
  'success-text': hexToHSL('#007941'),
  'success-surface': hexToHSL('#EBF6F1'),
  'success-surface-hover': hexToHSL('#E0F1EA'),

  'warning-border': hexToHSL('#E57A00'),
  'warning-surface': hexToHSL('#FDF4EB'),
  'warning-surface-hover': hexToHSL('#FCEFE0'),

  'error-border': hexToHSL('#E00004'),
  'error-text': hexToHSL('#BE0003'),
  'error-surface': hexToHSL('#FDEBEB'),
  'error-surface-hover': hexToHSL('#FBE0E1'),

  'info-border': hexToHSL('#006DCC'),
  'info-text': hexToHSL('#005DAD'),
  'info-surface': hexToHSL('#EBF3FB'),
  'info-surface-hover': hexToHSL('#E0EDF9'),

  dark: hexToHSL(brandColors.dark),
});

export const getBackgroundColor = (
  backgroundTheme: BackgroundTheme,
  appColors: Record<string, HSLString>
): HSLString => {
  switch (backgroundTheme) {
    case 'dark':
      return appColors.dark;
    case 'pale':
      return appColors['neutral-pale'];
    case 'light':
      return appColors['primary-light'];
    case 'tint':
      return appColors['tint-light'];
    case 'white':
    default:
      return hexToHSL('#FFFFFF');
  }
};

export const isLightBackground = (
  backgroundTheme: BackgroundTheme
): boolean => {
  switch (backgroundTheme) {
    case 'dark':
      return false;
    case 'white':
    case 'pale':
    case 'light':
    case 'tint':
    default:
      return true;
  }
};

export const getTenantBrand = (
  tenant: TenantOption
): { brandColors: BrandColors; backgroundTheme: BackgroundTheme } => {
  switch (tenant.value) {
    case 'arenaways':
      return {
        brandColors: ARENAWAYS_BRAND_COLORS,
        backgroundTheme: 'white',
      };
    case 'turnit':
    default:
      return {
        brandColors: TURNIT_BRAND_COLORS,
        backgroundTheme: 'dark',
      };
  }
};

export const setTenantBrand = (tenant: TenantOption) => {
  const { brandColors, backgroundTheme } = getTenantBrand(tenant);

  const calculatedStyleProperties = getCalculatedStyleProperties(brandColors);
  const newBackgroundColor = getBackgroundColor(
    backgroundTheme,
    calculatedStyleProperties
  );

  setGlobalStyleProperties(
    {
      ...calculatedStyleProperties,
      background: newBackgroundColor,
      radius: '8px',
    },
    backgroundTheme
  );
};

export const setTenantFavicon = (tenant: TenantOption) => {
  const newFavicon = document.createElement('link');

  newFavicon.rel = 'icon';
  newFavicon.type = 'image/svg+xml';
  newFavicon.href = `/${tenant.value}.png`;

  document.head.appendChild(newFavicon);
};
