import { useAuth } from '@/hooks/useAuth';
import type { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const PublicRoute = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated && !location.pathname.includes('/signup/confirmation')) {
    return <Navigate to="/account" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
