import type { FC } from 'react';
import {
  PopoverContent,
  PopoverPrimitiveRoot,
  PopoverTrigger,
} from '@/components/primitives/Popover';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import {
  type I18nLanguage,
  localeConfigurations,
  useTranslation,
} from '@/i18n';
import LanguageSelectContent from '@/components/header/language-select/LanguageSelectContent';
import { Button } from '@/components/primitives/Button';

interface RegularLanguageSelectProps {}

const RegularLanguageSelect: FC<RegularLanguageSelectProps> = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <PopoverPrimitiveRoot>
      <PopoverTrigger asChild>
        <Button
          data-testid="language-select-trigger"
          variant="ghost"
          className="group flex gap-2"
        >
          <Icons.globe height={24} width={24} />
          <Typography variant="button">
            {localeConfigurations[language as I18nLanguage].label}
          </Typography>
          <Icons.chevronDown
            height={16}
            width={16}
            className="transition-transform duration-200 group-data-[state=open]:rotate-180"
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-40 overflow-hidden rounded-md p-0"
        align="end"
      >
        <LanguageSelectContent />
      </PopoverContent>
    </PopoverPrimitiveRoot>
  );
};

export default RegularLanguageSelect;
