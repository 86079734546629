import { CardPrimitiveRoot } from '@/components/primitives/Card';
import { Icons } from '@/components/icons';
import { Loadable } from '@/components/Loadable';
import { Button } from '@/components/primitives/Button';
import { Typography } from '@/components/primitives/Typography';
import Footer from '@/components/Footer';
import NonTripOfferCard from '@/components/purchase/NonTripOfferCard';
import {
  nonTripOffersLoadingSelector,
  purchaseFlowBookingCreationLoadingSelector,
} from '@/features/loading/loadingSelectors';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import type { NonTripOfferDTO, SearchNonTripOffersDTO } from '@/types/dto';
import { getNonTripOfferWarning, getWarningsByTripId } from '@/utils/warning';
import type { FC } from 'react';

interface NonTripOfferListProps extends SearchNonTripOffersDTO {
  selectedNonTripOffer?: NonTripOfferDTO;
  onContinue: () => void;
}

const NonTripOfferList: FC<NonTripOfferListProps> = ({
  nonTripOffers,
  warnings,
  selectedNonTripOffer,
  onContinue,
}) => {
  const loadingNonTripOffers = useSelector(nonTripOffersLoadingSelector);
  const loadingBooking = useSelector(
    purchaseFlowBookingCreationLoadingSelector
  );
  const warningsByTripId = getWarningsByTripId(warnings?.warnings);

  return (
    <Loadable loading={loadingNonTripOffers || loadingBooking}>
      <Typography className="p-4 laptop:p-0" variant="heading1" asChild>
        <h1>{<TransText i18nKey="selectTravelPass" />}</h1>
      </Typography>
      {nonTripOffers?.length ? (
        <CardPrimitiveRoot className="flex-1 rounded-none pb-20 laptop:flex-none laptop:rounded-lg laptop:pb-0">
          <div className="grid w-full grid-cols-1 gap-2 p-4 laptop:grid-cols-2">
            {nonTripOffers.map((nonTripOffer, index) => (
              <NonTripOfferCard
                key={nonTripOffer.id ?? index}
                nonTripOffer={nonTripOffer}
                isSelected={Boolean(
                  selectedNonTripOffer?.id === nonTripOffer.id
                )}
                warningMessage={getNonTripOfferWarning(
                  nonTripOffer,
                  warningsByTripId
                )}
              />
            ))}
          </div>
        </CardPrimitiveRoot>
      ) : (
        <Typography data-testid="no-trips-found-message">
          <TransText i18nKey="noTravelPassesFound" />.
        </Typography>
      )}
      <Footer
        actionButtons={
          <Button
            fullWidth
            size="large"
            data-testid="footer-continue-button"
            className="rounded-lg laptop:h-11 laptop:w-auto"
            disabled={!selectedNonTripOffer}
            onClick={onContinue}
            variant="cta"
          >
            <Typography variant="subtitle" className="self-center">
              <TransText i18nKey="continue" />
            </Typography>
            <Icons.arrowRight />
          </Button>
        }
      />
    </Loadable>
  );
};

export default NonTripOfferList;
