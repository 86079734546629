import type { BookingDTO } from '@/types/dto';
import { isTravelPassBooking } from '@/utils/booking';

export const getFulfillmentIds = (booking: BookingDTO): string[] => {
  const isTravelPass = isTravelPassBooking(booking);

  if (isTravelPass) {
    return (
      booking.nonTripOffers?.flatMap(
        (offer) =>
          offer.admissions?.flatMap(
            (admission) =>
              admission.fulfillments
                ?.map((fulfillment) => fulfillment.id)
                .filter((id): id is string => id !== undefined) ?? []
          ) ?? []
      ) ?? []
    );
  }

  return (
    booking.bookedTrips?.flatMap(
      (trip) =>
        trip.bookedOffers?.flatMap(
          (offer) =>
            offer.admissions?.flatMap(
              (admission) =>
                admission.fulfillments
                  ?.map((fulfillment) => fulfillment.id)
                  .filter((id): id is string => id !== undefined) ?? []
            ) ?? []
        ) ?? []
    ) ?? []
  ).filter((id): id is string => id !== undefined);
};
