import Divider from '@/components/primitives/Divider';
import { Input } from '@/components/primitives/Input';
import Changeability from '@/components/purchase/card/Changeability';
import Refundability from '@/components/purchase/card/Refundability';
import SelectedToggle from '@/components/purchase/card/SelectedToggle';
import { updateSelectedNonTripOffer } from '@/features/purchase/purchaseActions';
import { useDispatch } from '@/store/utils';
import type { NonTripOfferDTO } from '@/types/dto';
import { cn } from '@/utils/cn';
import { useState, type FC } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import ExpandableText from '@/components/ExpandableText';
import currencySymbolMap from '@/utils/currencySymbolMap';

interface NonTripOfferCardProps {
  nonTripOffer: NonTripOfferDTO;
  isSelected: boolean;
  warningMessage: string | null;
}

const NonTripOfferCard: FC<NonTripOfferCardProps> = ({
  nonTripOffer,
  isSelected,
  warningMessage,
}) => {
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const condition = nonTripOffer.admissionOfferParts?.[0].conditions?.[0];
  const minimalPrice = nonTripOffer.offerSummary?.minimalPrice;

  return (
    <div
      className={cn(
        'flex w-full flex-col justify-between rounded-lg border border-primary hover:cursor-pointer hover:bg-primary/20',
        {
          'bg-primary/10 ring ring-primary': isSelected,
          'text-neutral-light': warningMessage,
          'laptop:max-h-[171px]': !isExpanded,
        }
      )}
      onClick={() => dispatch(updateSelectedNonTripOffer(nonTripOffer))}
    >
      <div id="content" className="flex h-full flex-col p-2 text-dark">
        <div className="flex flex-col tablet:flex tablet:flex-row tablet:gap-2">
          {isLaptopOrBigger && (
            <Input
              type="radio"
              checked={isSelected}
              readOnly
              classNames={{
                wrapper: 'h-fit',
                input: 'h-5 w-5 cursor-pointer',
              }}
              aria-label="Select offer"
            />
          )}
          <div className="flex flex-col">
            <Typography variant="subtitle" className="mb-1" asChild>
              <span>
                {condition?.shortDescription ?? condition?.description}
              </span>
            </Typography>
            <Typography variant="subtitle" className="text-primary">
              <span>{minimalPrice?.amount}</span>
              {minimalPrice?.currency && (
                <span>{currencySymbolMap[minimalPrice.currency]}</span>
              )}
            </Typography>
          </div>
        </div>
        <Divider className="my-3 flex-none laptop:my-2" />
        <ExpandableText
          text={condition?.description}
          variant="body1"
          className="flex-1"
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
        <div className="flex flex-row gap-2 py-1.5">
          <Refundability
            condition={
              nonTripOffer.admissionOfferParts?.[0].refundable === 'YES'
            }
          />
          <Changeability
            condition={
              nonTripOffer.admissionOfferParts?.[0].exchangeable === 'YES'
            }
          />
        </div>
      </div>
      <SelectedToggle isSelected={isSelected} />
    </div>
  );
};

export default NonTripOfferCard;
