import type { FC } from 'react';
import { useMemo } from 'react';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import { useSelector } from '@/store/utils';
import {
  searchFormValuesSelector,
  selectedInboundOfferMapSelector,
  selectedOutboundOfferMapSelector,
} from '@/features/purchase/purchaseSelectors';
import { TransText } from '@/i18n/trans/text';
import { currencySelector } from '@/features/configuration/configurationSelector';

export const TotalPrice: FC = () => {
  const selectedOutboundOfferMap = useSelector(
    selectedOutboundOfferMapSelector
  );
  const selectedInboundOfferMap = useSelector(selectedInboundOfferMapSelector);
  const searchValues = useSelector(searchFormValuesSelector);
  const currency = useSelector(currencySelector);

  const amountForSinglePassenger = useMemo(
    () =>
      [selectedOutboundOfferMap, selectedInboundOfferMap].reduce(
        (directionOfferMapAcc, offerMap) =>
          directionOfferMapAcc +
          Object.values(offerMap).reduce(
            (offerMapAcc, offer) => offerMapAcc + (offer.price?.amount || 0),
            0
          ),
        0
      ),
    [selectedInboundOfferMap, selectedOutboundOfferMap]
  );

  return (
    <div className="flex flex-row items-center gap-2">
      <Icons.travelPass height={20} width={20} />
      <Typography variant="subtitle">
        <TransText i18nKey="total" />:
      </Typography>
      <Typography className="flex gap-1 text-nowrap">
        <span>
          {amountForSinglePassenger * (searchValues?.passengers.length || 0)}
        </span>
        <span>{currency.symbol}</span>
      </Typography>
    </div>
  );
};
