import { useState, useCallback } from 'react';

export enum STORAGE_KEYS {
  CHECKOUT_SUCCESS_DATA = 'checkoutSuccessData',
  PHONE_PREFIX_COUNTRY = 'phonePrefixCountry',
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  TOKEN_EXPIRY = 'tokenExpiry',
}

export function useStorage<T>(
  storage: Storage,
  key: STORAGE_KEYS
): {
  value: T | null;
  setValue: (value: T | null) => void;
  removeValue: () => void;
};
export function useStorage<T>(
  storage: Storage,
  key: STORAGE_KEYS,
  initialValue: T
): { value: T; setValue: (value: T) => void; removeValue: () => void };
export function useStorage<T>(
  storage: Storage,
  key: STORAGE_KEYS,
  initialValue?: T
) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(`Error reading storage key "${key}":`, error);
      return initialValue;
    }
  });

  const setValue = useCallback(
    (value: T | ((prevValue: T) => T)) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        if (valueToStore === null) {
          storage.removeItem(key);
        } else {
          storage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        console.error(`Error setting storage key "${key}":`, error);
      }
    },
    [key, storage, storedValue]
  );

  const removeValue = useCallback(() => {
    try {
      storage.removeItem(key);
      setStoredValue(undefined as unknown as T);
    } catch (error) {
      console.error(`Error removing storage key "${key}":`, error);
    }
  }, [key, storage]);

  return { value: storedValue, setValue, removeValue };
}
