import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/utils/cn';
import { useThemedColors } from '@/hooks/useThemedColors';
import { typographyVariants } from '@/components/primitives/Typography';
import { isLightBackground } from '@/utils/color';
import { useBackgroundTheme } from '@/hooks/useBackgroundTheme';

const radioVariants = cva(
  'peer shrink-0 rounded-full border bg-transparent ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-tint focus-visible:ring-offset-2',
  {
    variants: {
      size: {
        sm: 'h-4 w-4',
        md: 'h-5 w-5',
        lg: 'h-6 w-6',
      },
      error: {
        true: [
          'border-error-border',
          'bg-error-surface',
          'hover:border-error-border hover:bg-error-surface',
          'checked:bg-error-surface',
          'after:!border-0 after:!bg-error-border',
        ],
        false: '',
      },
    },
    defaultVariants: {
      size: 'sm',
      error: false,
    },
  }
);

type RadioVariants = VariantProps<typeof radioVariants>;

export interface RadioProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    RadioVariants {
  size?: RadioVariants['size'];
}

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    { className, children, id, size = 'sm', error, disabled, ...props },
    ref
  ) => {
    const { getRadioColors, getRadioLabelColors } = useThemedColors();
    const backgroundTheme = useBackgroundTheme();
    const generatedId = React.useId();
    const radioId = id || generatedId;

    return (
      <div className="flex items-center" onClick={(e) => e.stopPropagation()}>
        <div
          className={cn('group inline-flex items-center', {
            'hover:cursor-pointer': !disabled,
            'cursor-not-allowed': disabled,
          })}
        >
          <input
            type="radio"
            ref={ref}
            id={radioId}
            disabled={disabled}
            className={cn(
              radioVariants({ size, error }),
              !error && getRadioColors(),
              'relative appearance-none',
              'after:absolute after:left-1/2 after:top-1/2 after:rounded-full after:content-[""]',
              'after:-translate-x-1/2 after:-translate-y-1/2 after:transform after:opacity-0',
              'checked:after:opacity-100',
              'transition-colors duration-200 ease-in-out',
              {
                'cursor-pointer': !disabled,
                'cursor-not-allowed': disabled,
                'after:h-2 after:w-2': size === 'sm',
                'after:h-2.5 after:w-2.5': size === 'md',
                'after:h-3 after:w-3': size === 'lg',
                'after:border after:border-neutral-medium after:bg-white':
                  !error && !disabled,
                'after:!border-0 after:!bg-neutral-light': disabled,
              },
              className
            )}
            {...props}
          />
          {children && (
            <label
              htmlFor={radioId}
              className={cn(
                'ml-2 cursor-pointer peer-disabled:cursor-not-allowed',
                typographyVariants({
                  variant: size === 'lg' ? 'body1' : 'body2',
                }),
                {
                  'text-error-text group-hover:text-error-text': error,
                  [getRadioLabelColors()]: !error,
                  'group-hover:text-primary-dark':
                    !error && isLightBackground(backgroundTheme),
                }
              )}
            >
              {children}
            </label>
          )}
        </div>
      </div>
    );
  }
);

Radio.displayName = 'Radio';

export { Radio };
