import { type FC, useCallback } from 'react';
import ToggleableRow from '@/components/purchase/checkout/ToggleableRow';
import { useFormContext } from 'react-hook-form';
import type { AncillaryValues, CheckoutValues } from '@/utils/zodSchema';
import { Typography } from '@/components/primitives/Typography';
import { getPassengerName, getPassengerOtherDetails } from '@/utils/passenger';
import type { AdditionalOfferItem } from '@/types/offer';
import { Checkbox } from '@/components/primitives/Checkbox';
import { useTranslation } from '@/i18n';

interface PassengerOptionsProps {
  ancillaryId: NonNullable<AdditionalOfferItem['id']>;
  passengers: CheckoutValues['passengers'];
}

const PassengerOptions: FC<PassengerOptionsProps> = ({
  ancillaryId,
  passengers,
}) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<AncillaryValues>();
  const selectedAncillaries = watch('selectedAncillaries');
  const currentAncillary = selectedAncillaries.find(
    (ancillary) => ancillary.id === ancillaryId
  );

  const handlePassengerToggle = useCallback(
    (passenger: CheckoutValues['passengers'][number], checked: boolean) => {
      const allOtherSelectedAncillaries = selectedAncillaries.filter(
        (ancillary) => ancillary.id !== currentAncillary?.id
      );

      if (!currentAncillary) {
        return;
      }

      let updatedAncillary;

      if (checked) {
        updatedAncillary = {
          ...currentAncillary,
          passengersExternalReferences: [
            ...currentAncillary.passengersExternalReferences,
            passenger.externalReference,
          ],
        };
      } else {
        const newPassengersExternalReferences =
          currentAncillary.passengersExternalReferences.filter(
            (externalRef) => externalRef !== passenger.externalReference
          );

        if (newPassengersExternalReferences.length > 0) {
          updatedAncillary = {
            ...currentAncillary,
            passengersExternalReferences: [...newPassengersExternalReferences],
          };
        }
      }

      setValue('selectedAncillaries', [
        ...allOtherSelectedAncillaries,
        ...(updatedAncillary ? [updatedAncillary] : []),
      ]);
    },
    [currentAncillary, selectedAncillaries, setValue]
  );

  return (
    <div className="flex w-full flex-col gap-1 self-end pl-8">
      {passengers?.map((passenger, passengerIndex) => {
        const isActive = Boolean(
          currentAncillary?.passengersExternalReferences.includes(
            passenger.externalReference
          )
        );

        return (
          <ToggleableRow
            isActive={isActive}
            onClick={() => handlePassengerToggle(passenger, !isActive)}
            key={passenger.id}
            data-testid="ancillary-option-passenger"
          >
            <div className="flex w-full items-center gap-2">
              <Checkbox
                checked={isActive}
                onCheckedChange={(checked) =>
                  handlePassengerToggle(passenger, checked === true)
                }
                className="h-4 w-4"
                aria-label={t('text.selectPassenger')}
                data-testid="passenger-option-toggle"
              />
              <div className="flex flex-col">
                <Typography variant="body2-bold">
                  {getPassengerName(passenger, passengerIndex)}
                </Typography>
                <Typography variant="label" className="text-neutral-light">
                  {getPassengerOtherDetails(passenger)}
                </Typography>
              </div>
            </div>
          </ToggleableRow>
        );
      })}
    </div>
  );
};

export default PassengerOptions;
