import type { BookingDTO } from '@/types/dto';

export const isTicketBooking = (booking?: BookingDTO | null): boolean => {
  if (!booking) return false;
  return Boolean(booking.bookedTrips?.length);
};

export const isTravelPassBooking = (booking?: BookingDTO | null): boolean => {
  if (!booking) return false;
  return Boolean(booking.nonTripOffers?.length);
};
