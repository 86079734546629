import RefundNonTripOverview from '@/components/manage/refund/nonTripOfer/RefundNonTripOverview';
import { RefundNonTripSelection } from '@/components/manage/refund/nonTripOfer/RefundNonTripSelection';
import RefundModal from '@/components/manage/refund/RefundModal';
import { initiateNonTripOffersRefund } from '@/features/manage/manageActions';
import { refundSelectedNonTripAdmissionsSelector } from '@/features/manage/manageSelectors';
import { useDispatch, useSelector } from '@/store/utils';
import { useCallback, useMemo, type FC } from 'react';

interface RefundNonTripModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const RefundNonTripModal: FC<RefundNonTripModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch();

  const selectedNonTripAdmissions = useSelector(
    refundSelectedNonTripAdmissionsSelector
  );

  const selected = useMemo(
    () => Object.values(selectedNonTripAdmissions).length,
    [selectedNonTripAdmissions]
  );

  const handleInitiateRefund = useCallback(async () => {
    await dispatch(initiateNonTripOffersRefund()).unwrap();
  }, [dispatch]);

  return (
    <RefundModal
      isOpen={isOpen}
      refundSelection={RefundNonTripSelection}
      refundOverview={RefundNonTripOverview}
      selected={selected}
      chooseToRefundText="chooseTravelPassesToRefund"
      refundText="refundTravelPasses"
      onInitiateRefund={handleInitiateRefund}
      onClose={onClose}
    />
  );
};

export default RefundNonTripModal;
