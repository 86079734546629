import MultiRideAccountValidity from '@/components/manage/travel-passes/MultiRideAccountValidity';
import TravelPassAccountValidity from '@/components/manage/travel-passes/TravelPassAccountValidity';
import {
  type MultiRideAccountDTO,
  type TravelPassAccountDTO,
} from '@/types/dto';
import { type DecoratedTravelPassDetails } from '@/types/manage';
import { type FC } from 'react';

interface NonTripOfferValidityProps {
  data: DecoratedTravelPassDetails<MultiRideAccountDTO | TravelPassAccountDTO>;
  isRefunded?: boolean;
}

const NonTripOfferValidity: FC<NonTripOfferValidityProps> = ({
  data,
  isRefunded,
}) => {
  if (!data.travelAccount?.objectType) {
    return null;
  }

  if (data.travelAccount?.objectType === 'TravelPassAccount') {
    return (
      <TravelPassAccountValidity
        validFrom={data.travelAccount.validFrom}
        validUntil={data.travelAccount.validUntil}
        isRefunded={isRefunded}
      />
    );
  }

  return (
    <MultiRideAccountValidity
      validFrom={data.travelAccount.validFrom}
      validUntil={data.travelAccount.validUntil}
      balance={(data.travelAccount as MultiRideAccountDTO).balance}
      isRefunded={isRefunded}
    />
  );
};

export default NonTripOfferValidity;
