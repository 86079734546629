import HighlightedRow from '@/components/booking-summary/HighlightedRow';
import { Icons } from '@/components/icons';
import RefundSummaryItem from '@/components/manage/refund/summary/RefundSummaryItem';
import Divider from '@/components/primitives/Divider';
import { Typography } from '@/components/primitives/Typography';
import { currencySelector } from '@/features/configuration/configurationSelector';
import { TransText } from '@/i18n/trans/text';
import { useSelector } from '@/store/utils';
import { type AdmissionDTO, type RefundOffersDTO } from '@/types/dto';
import { type FC } from 'react';

interface RefundSummaryProps {
  refundSummary: RefundOffersDTO;
  admissions: Array<AdmissionDTO>;
}

const RefundSummary: FC<RefundSummaryProps> = ({
  refundSummary: { refundOffers },
  admissions,
}) => {
  const { refundableAmount, refundFee } = refundOffers?.[0] ?? {};
  const currency = useSelector(currencySelector);

  const totalAdmissionAmount = admissions.reduce((sum, admission) => {
    const admissionPrice = admission.price?.amount ?? 0;
    const ancillariesPrice =
      admission.ancillaries?.reduce(
        (acc, ancillary) => acc + (ancillary.price?.amount || 0),
        0
      ) || 0;

    return sum + admissionPrice + ancillariesPrice;
  }, 0);

  const getRefundFeeAmount = (amount?: number) => (amount ? `-${amount}` : 0);

  return (
    <>
      <div className="mb-5">
        <Typography variant="paragraph">
          <TransText i18nKey="refundIssuedText" />
        </Typography>
        <div className="mt-5 flex flex-col gap-2 rounded-lg rounded-b-none border border-neutral-light px-4 py-3">
          <RefundSummaryItem
            icon={
              <Icons.ticketReturn
                height={16}
                width={16}
                className="text-primary"
              />
            }
            title={<TransText i18nKey="totalPrice" />}
            amount={`${totalAdmissionAmount} ${currency.symbol}`}
          />
          <RefundSummaryItem
            icon={
              <Icons.coin height={16} width={16} className="text-primary" />
            }
            title={<TransText i18nKey="refundFee" />}
            amount={`${getRefundFeeAmount(refundFee?.amount)} ${currency.symbol}`}
          />
        </div>
        <HighlightedRow className="rounded-md rounded-t-none border border-t-0 border-neutral-light p-4">
          <RefundSummaryItem
            isTotal
            icon={
              <Icons.buyReturnTicket
                className="text-primary"
                width={16}
                height={16}
              />
            }
            title={<TransText i18nKey="totalRefund" />}
            amount={`${refundableAmount?.amount} ${currency.symbol}`}
          />
        </HighlightedRow>
      </div>
      <Divider />
    </>
  );
};

export default RefundSummary;
