import { NonTripOfferSelectionCard } from '@/components/manage/refund/nonTripOfer/NonTripOfferSelectionCard';
import { toggleRefundNonTripAdmission } from '@/features/manage/manageActions';
import { refundSelectedNonTripAdmissionsSelector } from '@/features/manage/manageSelectors';
import { useBookingNonTripOfferDetails } from '@/hooks/useBookingNonTripOfferDetails';
import { useDispatch, useSelector } from '@/store/utils';
import { Flow } from '@/types/booking';
import { useMemo, type FC } from 'react';

interface RefundNonTripSelectionProps {}

export const RefundNonTripSelection: FC<RefundNonTripSelectionProps> = () => {
  const dispatch = useDispatch();
  const selectedNonTripAdmissions = useSelector(
    refundSelectedNonTripAdmissionsSelector
  );
  const { travelPass, multiRide } = useBookingNonTripOfferDetails(
    Flow.manageBooking
  );
  const nonTripOffers = useMemo(
    () =>
      [...travelPass, ...multiRide].filter(
        (nonTripOffer) => nonTripOffer.status !== 'REFUNDED'
      ),
    [multiRide, travelPass]
  );

  return (
    <div className="flex flex-col gap-3">
      {nonTripOffers.map((nonTripOffer) => (
        <NonTripOfferSelectionCard
          key={nonTripOffer.id}
          nonTripOffer={nonTripOffer}
          isSelected={Boolean(
            nonTripOffer.id && selectedNonTripAdmissions[nonTripOffer.id]
          )}
          onSelect={() => {
            dispatch(toggleRefundNonTripAdmission(nonTripOffer));
          }}
        />
      ))}
    </div>
  );
};
