import type { RefundOffersDTO, AdmissionDTO, BookingDTO } from '@/types/dto';
import getApiInstance from '@/api';
import { createAsyncThunk } from '@/store/utils';
import { createAction } from '@reduxjs/toolkit';
import { runInSequence } from '@/utils/runInSequence';
import { fetchBooking } from '@/features/purchase/purchaseService';
import { fetchInitiateRefund } from '@/features/manage/manageService';

export const resetRefundSelection = createAction('manage/resetRefundSelection');

export const toggleRefundLeg = createAction(
  'manage/toggleRefundLeg',
  function prepare(
    legId: string,
    selectedLegAdmissions: Record<string, Array<AdmissionDTO>>,
    admissions: Array<AdmissionDTO> = []
  ) {
    const legAdmissions = selectedLegAdmissions[legId] || [];
    const areAllSelected = legAdmissions.length === admissions?.length;

    return {
      payload: {
        legId,
        admissions: areAllSelected ? [] : admissions,
      },
    };
  }
);

export const toggleRefundLegAdmission = createAction(
  'manage/toggleRefundLegAdmission',
  function prepare(
    legId: string,
    selectedLegAdmissions: Record<string, AdmissionDTO[]>,
    admission: AdmissionDTO
  ) {
    const legAdmissions = selectedLegAdmissions[legId] || [];
    const updatedLegAdmissions = legAdmissions.some(
      (a) => a.id === admission?.id
    )
      ? legAdmissions.filter((a) => a.id !== admission?.id)
      : [...legAdmissions, admission];

    return {
      payload: {
        legId,
        admissions: updatedLegAdmissions,
      },
    };
  }
);

export const toggleRefundNonTripAdmission = createAction<AdmissionDTO>(
  'purchase/toggleRefundNonTripAdmission'
);

export const initiateLegsRefund = createAsyncThunk<
  RefundOffersDTO | null,
  void
>('manage/initiateLegsRefund', async (_, { getState }) => {
  const state = getState();
  const { selectedLegAdmissions } = state.manage.refund;
  const bookingId = state.manage.booking?.id;

  const fulfillmentIds = Object.values(selectedLegAdmissions)
    .flat()
    .filter((admission) => admission.status !== 'REFUNDED')
    .flatMap((admission) => [
      ...(admission.fulfillments ?? []).map((f) => f.id),
      ...(admission.ancillaries?.flatMap(
        (ancillary) => ancillary.fulfillments?.map((f) => f.id) ?? []
      ) ?? []),
    ])
    .filter((item): item is string => Boolean(item));

  return fetchInitiateRefund(bookingId, fulfillmentIds);
});

export const initiateNonTripOffersRefund = createAsyncThunk<
  RefundOffersDTO | null,
  void
>('manage/initiateNonTripOffersRefund', async (_, { getState }) => {
  const state = getState();
  const { selectedNonTripAdmissions } = state.manage.refund;
  const bookingId = state.manage.booking?.id;

  if (!bookingId) {
    return null;
  }

  const fulfillmentIds = Object.keys(selectedNonTripAdmissions);

  return fetchInitiateRefund(bookingId, fulfillmentIds);
});

export const deleteRefundOffer = createAsyncThunk<void, void>(
  'manage/deleteRefundOffer',
  async (_, { getState }) => {
    const state = getState();
    const bookingId = state.manage.booking?.id;

    if (!bookingId) {
      return;
    }

    const api = (await getApiInstance()).agentApi;

    await runInSequence(
      (state.manage.refund.details?.refundOffers ?? []).map(
        (offer) => () =>
          offer.id &&
          api.RefundOffers_DeleteRefund({
            bookingId,
            refundOfferId: offer.id,
          })
      )
    );
  }
);

export const confirmRefundOffer = createAsyncThunk<void, void>(
  'manage/confirmRefundOffer',
  async (_, { getState }) => {
    const state = getState();
    const bookingId = state.manage.booking?.id;
    const api = (await getApiInstance()).agentApi;

    if (!bookingId) {
      return;
    }

    await runInSequence(
      (state.manage.refund.details?.refundOffers ?? []).map(
        (offer) => () =>
          offer.id &&
          api.RefundOffers_ConfirmRefund(
            { bookingId, refundOfferId: offer.id },
            { status: 'CONFIRMED' }
          )
      )
    );
  }
);

export const getManageFlowBookingById = createAsyncThunk<
  BookingDTO | null,
  string | null | undefined
>('manage/getManageFlowBookingById', async (id) => {
  if (!id) {
    return null;
  }

  return fetchBooking(id);
});

export const getManageFlowBookingByNumberAndEmail = createAsyncThunk<
  BookingDTO | null,
  {
    bookingNumber: string;
    email: string;
  }
>(
  'manage/getManageFlowBookingByNumberAndEmail',
  async ({ bookingNumber, email }) => {
    const api = (await getApiInstance()).agentApi;

    const searchResults = (
      await api.BookingsSearch_SearchBookingsCustom(null, {
        reference: bookingNumber,
        purchaserPassengerEmail: email,
        concessionVoucherSearchType: 'ALL',
      })
    ).data.bookingsSearchResults;

    if (searchResults?.length !== 1) {
      throw new Error(
        `Expected 1 search result, found ${searchResults?.length}.`
      );
    }

    const booking = searchResults[0];

    if (!booking.id) {
      return null;
    }

    return fetchBooking(booking.id);
  }
);

export const clearManageFlowBooking = createAction(
  'manage/clearManageFlowBooking'
);
