import type { TransKeys } from '@/i18n/trans/index';
import { createTrans } from '@/i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransText = createTrans({
  accountCreated: (
    <Trans i18nKey="text.accountCreated" defaults="Account created!" />
  ),
  accountDetails: (
    <Trans i18nKey="text.accountDetails" defaults="Account details" />
  ),
  activation: <Trans i18nKey="text.activation" defaults="Activation" />,
  addExtras: <Trans i18nKey="text.addExtras" defaults="Add extras" />,
  addNew: <Trans i18nKey="text.addNew" defaults="Add new" />,
  alreadyHaveAnAccount: (
    <Trans
      i18nKey="text.alreadyHaveAnAccount"
      defaults="Already have an account?"
    />
  ),
  areYouSureYouWantToCancelThePurchase: (
    <Trans
      i18nKey="text.areYouSureYouWantToCancelThePurchase"
      defaults="Are you sure you want to cancel the purchase?"
    />
  ),
  arrival: <Trans i18nKey="text.arrival" defaults="Arrival" />,
  back: <Trans i18nKey="text.back" defaults="Back" />,
  backToJourneySearch: (
    <Trans
      i18nKey="text.backToJourneySearch"
      defaults="Back to the journey search"
    />
  ),
  bookedTravelPasses: (
    <Trans i18nKey="text.bookedTravelPasses" defaults="Booked travel passes" />
  ),
  booking: <Trans i18nKey="text.booking" defaults="Booking" />,
  bookingItems: <Trans i18nKey="text.bookingItems" defaults="Booking items" />,
  bookingNo: (
    <Trans
      i18nKey="text.bookingNo"
      defaults="Your booking number is: {{number}}"
    />
  ),
  bookingNumber: (
    <Trans i18nKey="text.bookingNumber" defaults="Booking number" />
  ),
  bookingSessionExpired: (
    <Trans
      i18nKey="text.bookingSessionExpired"
      defaults="Booking session expired"
    />
  ),
  bookingSessionExpiresIn: (
    <Trans
      i18nKey="text.bookingSessionExpiresIn"
      defaults="Booking session expires in"
    />
  ),
  bookingSessionExpiryDescription: (
    <Trans
      i18nKey="text.bookingSessionExpiryDescription"
      defaults="Your booking session has expired. Start a new session to resume the booking process."
    />
  ),
  bookingSummary: (
    <Trans i18nKey="text.bookingSummary" defaults="Booking summary" />
  ),
  bookingTotal: <Trans i18nKey="text.bookingTotal" defaults="Booking total" />,
  bus: <Trans i18nKey="text.bus" defaults="Bus" />,
  cancel: <Trans i18nKey="text.cancel" defaults="Cancel" />,
  cancelPurchase: (
    <Trans i18nKey="text.cancelPurchase" defaults="Cancel purchase" />
  ),
  changeSearchInputs: (
    <Trans
      i18nKey="text.changeSearchInputs"
      defaults="No trips found. Please change the search inputs and try again."
    />
  ),
  changeable: <Trans i18nKey="text.changeable" defaults="Changeable" />,
  checkYourEmail: (
    <Trans i18nKey="text.checkYourEmail" defaults="Check your email" />
  ),
  checkout: <Trans i18nKey="text.checkout" defaults="Checkout" />,
  chooseTravelPassesToRefund: (
    <Trans
      i18nKey="text.chooseTravelPassesToRefund"
      defaults="Choose travel pass(es) to refund"
    />
  ),
  chooseTripsToRefund: (
    <Trans
      i18nKey="text.chooseTripsToRefund"
      defaults="Choose trip(s) to refund"
    />
  ),
  clickHereToSearch: (
    <Trans i18nKey="text.clickHereToSearch" defaults="Click here to search" />
  ),
  close: <Trans i18nKey="text.close" defaults="Close" />,
  confirm: <Trans i18nKey="text.confirm" defaults="Confirm" />,
  confirmRegistration: (
    <Trans i18nKey="text.confirmRegistration" defaults="Confirm registration" />
  ),
  confirmRegistrationDescription: (
    <Trans
      i18nKey="text.confirmRegistrationDescription"
      defaults="We have sent you a verification email. To continue, please click on the verification link provided in that email. If you haven’t received it, please check your spam folder or click on the resend link below."
    />
  ),
  continue: <Trans i18nKey="text.continue" defaults="Continue" />,
  continueWithEmail: (
    <Trans i18nKey="text.continueWithEmail" defaults="Continue with email" />
  ),
  createAPassword: (
    <Trans i18nKey="text.createAPassword" defaults="Create a password" />
  ),
  createAnAccount: (
    <Trans i18nKey="text.createAnAccount" defaults="Create an account" />
  ),
  createNewPassword: (
    <Trans i18nKey="text.createNewPassword" defaults="Create new password" />
  ),
  departure: <Trans i18nKey="text.departure" defaults="Departure" />,
  details: <Trans i18nKey="text.details" defaults="Details" />,
  direct: <Trans i18nKey="text.direct" defaults="Direct" />,
  dismiss: <Trans i18nKey="text.dismiss" defaults="Dismiss" />,
  dismissWarning: (
    <Trans i18nKey="text.dismissWarning" defaults="Dismiss warning" />
  ),
  doesntMatchCriteriaDescribedAbove: (
    <Trans
      i18nKey="text.doesntMatchCriteriaDescribedAbove"
      defaults="Doesn't match the criteria described above"
    />
  ),
  downloadPdf: <Trans i18nKey="text.downloadPdf" defaults="Download pdf" />,
  downloadPkpass: (
    <Trans i18nKey="text.downloadPkpass" defaults="Download pkpass" />
  ),
  editSearch: <Trans i18nKey="text.editSearch" defaults="Edit search" />,
  email: <Trans i18nKey="text.email" defaults="Email" />,
  emailUsedForBooking: (
    <Trans
      i18nKey="text.emailUsedForBooking"
      defaults="Email (used for booking)"
    />
  ),
  enterYourBookingNumber: (
    <Trans
      i18nKey="text.enterYourBookingNumber"
      defaults="Enter your booking number"
    />
  ),
  enterYourEmailAddress: (
    <Trans
      i18nKey="text.enterYourEmailAddress"
      defaults="Enter your email address"
    />
  ),
  enterYourPassword: (
    <Trans i18nKey="text.enterYourPassword" defaults="Enter your password" />
  ),
  errorDownloadingPkpass: (
    <Trans
      i18nKey="text.errorDownloadingPkpass"
      defaults="Failed to download pkpass"
    />
  ),
  errorSendingEmail: (
    <Trans
      i18nKey="text.errorSendingEmail"
      defaults="Confirmation email not sent"
    />
  ),
  extras: <Trans i18nKey="text.extras" defaults="Extras" />,
  failedToCreateAccount: (
    <Trans
      i18nKey="text.failedToCreateAccount"
      defaults="Failed to create account."
    />
  ),
  failedToCreateBooking: (
    <Trans
      i18nKey="text.failedToCreateBooking"
      defaults="Failed to create booking."
    />
  ),
  fillOutFollowingRegistrationForm: (
    <Trans
      i18nKey="text.fillOutFollowingRegistrationForm"
      defaults="Please fill out the following registration form to sign up with us."
    />
  ),
  findYourBooking: (
    <Trans i18nKey="text.findYourBooking" defaults="Find your booking" />
  ),
  firstName: <Trans i18nKey="text.firstName" defaults="First name" />,
  forgotPassword: (
    <Trans i18nKey="text.forgotPassword" defaults="Forgot password?" />
  ),
  from: <Trans i18nKey="text.from" defaults="From" />,
  gladToHaveYouOnBoard: (
    <Trans
      i18nKey="text.gladToHaveYouOnBoard"
      defaults="Glad to have you on board!"
    />
  ),
  goBack: <Trans i18nKey="text.goBack" defaults="Go back" />,
  hello: <Trans i18nKey="text.hello" defaults="Hello!" />,
  helloDisplayName: (
    <Trans i18nKey="text.helloDisplayName" defaults="Hello, {{displayName}}" />
  ),
  inbound: <Trans i18nKey="text.inbound" defaults="Inbound" />,
  inboundTrip: <Trans i18nKey="text.inboundTrip" defaults="Inbound trip" />,
  inboundTrips: (
    <Trans
      i18nKey="text.inboundTrips"
      defaults="(1)[Inbound trip];(2-inf)[Inbound trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  invalidEmailFormat: (
    <Trans i18nKey="text.invalidEmailFormat" defaults="Invalid email format" />
  ),
  invalidPhoneNumberFormat: (
    <Trans
      i18nKey="text.invalidPhoneNumberFormat"
      defaults="Invalid phone number format"
    />
  ),
  journeyTransfersCount: (
    <Trans
      i18nKey="text.journeyTransfersCount"
      defaults="(0)[{{count}} transfers];(1)[{{count}} transfer];(2-inf)[{{count}} transfers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  lastName: <Trans i18nKey="text.lastName" defaults="Last name" />,
  logIn: <Trans i18nKey="text.logIn" defaults="Log in" />,
  logOut: <Trans i18nKey="text.logOut" defaults="Log out" />,
  loginFailed: <Trans i18nKey="text.loginFailed" defaults="Login failed" />,
  loyalty: <Trans i18nKey="text.loyalty" defaults="Loyalty" />,
  manageBooking: (
    <Trans i18nKey="text.manageBooking" defaults="Manage booking" />
  ),
  maximum64: (
    <Trans
      i18nKey="text.maximum64"
      defaults="Can not be more than 64 characters long"
    />
  ),
  minimumEight: (
    <Trans
      i18nKey="text.minimumEight"
      defaults="Must be at least 8 characters long"
    />
  ),
  minimumOne: (
    <Trans i18nKey="text.minimumOne" defaults="Must choose at least one" />
  ),
  minutesCount: (
    <Trans
      i18nKey="text.minutesCount"
      defaults="(0)[{{count}} minutes];(1)[{{count}} minute];(2-inf)[{{count}} minutes];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  mustBeFilled: <Trans i18nKey="text.mustBeFilled" defaults="Must be filled" />,
  mustChooseLeg: (
    <Trans i18nKey="text.mustChooseLeg" defaults="You must choose a leg" />
  ),
  nextAvailableDate: (
    <Trans
      i18nKey="text.nextAvailableDate"
      defaults="Next available date: {{date}}"
    />
  ),
  noCountryFound: (
    <Trans i18nKey="text.noCountryFound" defaults="No country found" />
  ),
  noExtrasAdded: (
    <Trans i18nKey="text.noExtrasAdded" defaults="No extras added" />
  ),
  noTravelPassesFound: (
    <Trans
      i18nKey="text.noTravelPassesFound"
      defaults="No travel passes found"
    />
  ),
  noTripsFoundNextDate: (
    <Trans
      i18nKey="text.noTripsFoundNextDate"
      defaults="No trips found. Choose a different date or change the search inputs"
    />
  ),
  nonRefundable: (
    <Trans i18nKey="text.nonRefundable" defaults="Non-refundable" />
  ),
  notAvailable: <Trans i18nKey="text.notAvailable" defaults="Not available" />,
  notRegisteredYet: (
    <Trans i18nKey="text.notRegisteredYet" defaults="Not registered yet?" />
  ),
  numberOfYearsOld: (
    <Trans
      i18nKey="text.numberOfYearsOld"
      defaults="(0-1)[{{count}} year old];(2-inf)[{{count}} years old];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  optional: <Trans i18nKey="text.optional" defaults="optional" />,
  outbound: <Trans i18nKey="text.outbound" defaults="Outbound" />,
  outboundTrip: <Trans i18nKey="text.outboundTrip" defaults="Outbound trip" />,
  outboundTrips: (
    <Trans
      i18nKey="text.outboundTrips"
      defaults="(0)[Outbound trips];(1)[Outbound trip];(2-inf)[Outbound trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  partiallyRefunded: (
    <Trans i18nKey="text.partiallyRefunded" defaults="Partially refunded" />
  ),
  passengerIndex: (
    <Trans
      i18nKey="text.passengerIndex"
      defaults="(0)[Passenger];(1-inf)[Passenger {{count}}];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  passengers: <Trans i18nKey="text.passengers" defaults="Passengers" />,
  passengersCount: (
    <Trans
      i18nKey="text.passengersCount"
      defaults="(0)[{{count}} passengers];(1)[{{count}} passenger];(2-inf)[{{count}} passengers];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  password: <Trans i18nKey="text.password" defaults="Password" />,
  passwordsMustMatch: (
    <Trans i18nKey="text.passwordsMustMatch" defaults="Passwords must match" />
  ),
  pay: <Trans i18nKey="text.pay" defaults="Pay" />,
  payer: <Trans i18nKey="text.payer" defaults="Payer" />,
  payment: <Trans i18nKey="text.payment" defaults="Payment" />,
  phoneNumber: <Trans i18nKey="text.phoneNumber" defaults="Phone number" />,
  prefillWith: <Trans i18nKey="text.prefillWith" defaults="Prefill with:" />,
  priceIsForAllPassengers: (
    <Trans
      i18nKey="text.priceIsForAllPassengers"
      defaults="The price covers all passengers."
    />
  ),
  pricePerPassenger: (
    <Trans i18nKey="text.pricePerPassenger" defaults="Price per passenger " />
  ),
  purchase: <Trans i18nKey="text.purchase" defaults="Purchase" />,
  readMore: <Trans i18nKey="text.readMore" defaults="Read more" />,
  refund: <Trans i18nKey="text.refund" defaults="Refund" />,
  refundFee: <Trans i18nKey="text.refundFee" defaults="Refund fee" />,
  refundInitiated: (
    <Trans
      i18nKey="text.refundInitiated"
      defaults="Trip successfully cancelled. Your refund will be issued in 3 business days."
    />
  ),
  refundIssuedText: (
    <Trans
      i18nKey="text.refundIssuedText"
      defaults="The refund will be issued to the card that was used to pay for the booking."
    />
  ),
  refundModalDescription: (
    <Trans
      i18nKey="text.refundModalDescription"
      defaults="Modal for refunding tickets and admissions"
    />
  ),
  refundTravelPasses: (
    <Trans
      i18nKey="text.refundTravelPasses"
      defaults="(1)[Refund travel pass];(2-inf)[Refund travel passes]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  refundTrips: (
    <Trans
      i18nKey="text.refundTrips"
      defaults="(1)[Refund trip];(2-inf)[Refund trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  refundable: <Trans i18nKey="text.refundable" defaults="Refundable" />,
  refunded: <Trans i18nKey="text.refunded" defaults="Refunded" />,
  remainingUses: (
    <Trans i18nKey="text.remainingUses" defaults="Remaining uses" />
  ),
  remove: <Trans i18nKey="text.remove" defaults="Remove" />,
  repeatNewPassword: (
    <Trans i18nKey="text.repeatNewPassword" defaults="Repeat new password" />
  ),
  requiredField: (
    <Trans i18nKey="text.requiredField" defaults="Required field" />
  ),
  resendConfirmationEmail: (
    <Trans
      i18nKey="text.resendConfirmationEmail"
      defaults="Resend confirmation email"
    />
  ),
  returnTrip: <Trans i18nKey="text.returnTrip" defaults="Return trip" />,
  returnTrips: (
    <Trans
      i18nKey="text.returnTrips"
      defaults="(1)[Return trip];(2-inf)[Return trips]"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  secondsCount: (
    <Trans
      i18nKey="text.secondsCount"
      defaults="(0)[{{count}} seconds];(1)[{{count}} second];(2-inf)[{{count}} seconds];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  select: <Trans i18nKey="text.select" defaults="Select" />,
  selectExtra: <Trans i18nKey="text.selectExtra" defaults="Select extra" />,
  selectLanguage: (
    <Trans i18nKey="text.selectLanguage" defaults="Select language" />
  ),
  selectLeg: <Trans i18nKey="text.selectLeg" defaults="Select leg" />,
  selectLegToContinue: (
    <Trans
      i18nKey="text.selectLegToContinue"
      defaults="Select leg to continue"
    />
  ),
  selectOutboundFare: (
    <Trans i18nKey="text.selectOutboundFare" defaults="Select outbound fare" />
  ),
  selectOutboundTrip: (
    <Trans i18nKey="text.selectOutboundTrip" defaults="Select outbound trip" />
  ),
  selectPassenger: (
    <Trans i18nKey="text.selectPassenger" defaults="Select passenger" />
  ),
  selectPassengersToRefund: (
    <Trans
      i18nKey="text.selectPassengersToRefund"
      defaults="Select passengers to refund"
    />
  ),
  selectReturnFare: (
    <Trans i18nKey="text.selectReturnFare" defaults="Select return fare" />
  ),
  selectReturnTrip: (
    <Trans i18nKey="text.selectReturnTrip" defaults="Select return trip" />
  ),
  selectTravelPass: (
    <Trans i18nKey="text.selectTravelPass" defaults="Select travel pass" />
  ),
  selected: <Trans i18nKey="text.selected" defaults="Selected" />,
  sessionAboutToExpire: (
    <Trans
      i18nKey="text.sessionAboutToExpire"
      defaults="Session about to expire"
    />
  ),
  showLess: <Trans i18nKey="text.showLess" defaults="Show less" />,
  showMore: <Trans i18nKey="text.showMore" defaults="Show more" />,
  stopsCount: (
    <Trans
      i18nKey="text.stopsCount"
      defaults="(0)[{{count}} stops];(1)[{{count}} stop];(2-inf)[{{count}} stops];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  ticketPurchased: (
    <Trans i18nKey="text.ticketPurchased" defaults="Tickets purchased" />
  ),
  ticketSent: (
    <Trans
      i18nKey="text.ticketSent"
      defaults="Your tickets have been sent to your email address."
    />
  ),
  tickets: <Trans i18nKey="text.tickets" defaults="Tickets" />,
  total: <Trans i18nKey="text.total" defaults="Total" />,
  totalPrice: <Trans i18nKey="text.totalPrice" defaults="Total price" />,
  totalRefund: <Trans i18nKey="text.totalRefund" defaults="Total refund" />,
  train: <Trans i18nKey="text.train" defaults="Train" />,
  transfer: <Trans i18nKey="text.transfer" defaults="Transfer" />,
  transferTime: <Trans i18nKey="text.transferTime" defaults="transfer time" />,
  travelPass: <Trans i18nKey="text.travelPass" defaults="Travel pass" />,
  travelPassDetails: (
    <Trans i18nKey="text.travelPassDetails" defaults="Travel pass details" />
  ),
  travelPasses: <Trans i18nKey="text.travelPasses" defaults="Travel passes" />,
  tripLegPartIndex: (
    <Trans i18nKey="text.tripLegPartIndex" defaults="Part {{tripLegPart}}" />
  ),
  unchangeable: <Trans i18nKey="text.unchangeable" defaults="Unchangeable" />,
  undefinedConsentDescription: (
    <Trans
      i18nKey="text.undefinedConsentDescription"
      defaults="Undefined consent description"
    />
  ),
  validFrom: <Trans i18nKey="text.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="text.validTo" defaults="Valid to" />,
  vouchers: <Trans i18nKey="text.vouchers" defaults="Vouchers" />,
  yearsOld: (
    <Trans
      i18nKey="text.yearsOld"
      defaults="(0)[{{age}} years];(1)[{{age}} year];(2-inf)[{{age}} years];"
      tOptions={{ postProcess: 'interval' }}
    />
  ),
  youAreAboutToRefundFollowingItems: (
    <Trans
      i18nKey="text.youAreAboutToRefundFollowingItems"
      defaults="You are about to refund the following items:"
    />
  ),
  yourPaymentIsBeingProcessedPleaseWait: (
    <Trans
      i18nKey="text.yourPaymentIsBeingProcessedPleaseWait"
      defaults="Your payment is being processed. Please wait..."
    />
  ),
});

export type TransTextKeys = TransKeys<typeof TransText>;
