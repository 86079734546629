import type { FC } from 'react';
import {
  SheetRoot,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTrigger,
} from '@/components/primitives/Sheet';
import { Icons } from '@/components/icons';
import { Typography } from '@/components/primitives/Typography';
import Divider from '@/components/primitives/Divider';
import { TransText } from '@/i18n/trans/text';
import SummaryContent from '@/components/booking-summary/SummaryContent';
import type { BookingSummaryData } from '@/hooks/useBookingSummaryData';
import { useBooking } from '@/hooks/useBooking';
import type { Flow } from '@/types/booking';
import { useSelector } from '@/store/utils';
import { currencySelector } from '@/features/configuration/configurationSelector';

interface MobileBookingSummaryProps extends BookingSummaryData {
  flow: Flow;
}

const MobileBookingSummary: FC<MobileBookingSummaryProps> = (props) => {
  const booking = useBooking(props.flow);
  const currency = useSelector(currencySelector);

  return (
    <SheetRoot>
      <SheetTrigger
        data-testid="booking-summary-trigger"
        className="flex w-full flex-row items-center justify-between bg-white"
      >
        <div className="flex flex-row items-center gap-2">
          <Icons.travelPass className="h-4 w-4 text-primary" />
          <Typography variant="body1-bold" className="text-dark">
            <TransText i18nKey="bookingSummary" />
          </Typography>
        </div>
        <div className="flex flex-row items-center gap-1 text-primary">
          <Typography variant="body2-bold">
            <span>
              {props.flow === 'purchase'
                ? booking?.provisionalPrice?.amount
                : booking?.confirmedPrice?.amount}
            </span>
            <span>{currency.symbol}</span>
          </Typography>
          <Icons.chevronUp className="h-4 w-4" />
        </div>
      </SheetTrigger>
      <SheetContent
        data-testid="booking-summary-content"
        side="bottom"
        className="min-w-mobile rounded-t-2xl p-0"
      >
        <SheetHeader className="flex w-full flex-row items-center justify-between p-4">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <Icons.travelPass className="h-5 w-5 text-primary" />
              <Typography variant="subtitle" className="text-dark">
                <TransText i18nKey="bookingSummary" />
              </Typography>
            </div>
            <SheetClose>
              <Icons.xSmall className="h-12 w-12" />
              <span className="sr-only">Close</span>
            </SheetClose>
          </div>
        </SheetHeader>
        <Divider variant="medium" />
        <div className="max-h-[calc(100vh-170px)] overflow-y-auto">
          <SummaryContent {...props} />
        </div>
        <Divider variant="medium" />
        <div className="flex flex-row justify-between p-4">
          <div className="flex gap-2">
            <Icons.shoppingCart className="text-primary" />
            <Typography variant="body1-bold">
              <TransText i18nKey="totalPrice" />
            </Typography>
          </div>
          <Typography variant="body1-bold" className="text-primary">
            <span>
              {props.flow === 'purchase'
                ? booking?.provisionalPrice?.amount
                : booking?.confirmedPrice?.amount}
            </span>
            <span>{currency.symbol}</span>
          </Typography>
        </div>
      </SheetContent>
    </SheetRoot>
  );
};

export default MobileBookingSummary;
