import { type FC, useEffect } from 'react';
import { useBookingTripLegs } from '@/hooks/useBookingTripLegs';
import { useSelector } from '@/store/utils';
import { purchaseFlowBookingSelector } from '@/features/purchase/purchaseSelectors';
import LegOption from '@/components/purchase/checkout/ancillaries/ancillary-modal/LegOption';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import Divider from '@/components/primitives/Divider';
import { useFormContext } from 'react-hook-form';
import type { AncillaryValues } from '@/utils/zodSchema';

interface LegOptionsSectionProps {}

const LegOptionsSection: FC<LegOptionsSectionProps> = () => {
  const booking = useSelector(purchaseFlowBookingSelector)!;
  const { setValue } = useFormContext<AncillaryValues>();
  const { outboundLegs, inboundLegs } = useBookingTripLegs(booking);

  useEffect(() => {
    if (outboundLegs.length === 1 && outboundLegs[0].id) {
      setValue('selectedLegId', outboundLegs[0].id);
    }
  }, [outboundLegs, setValue]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-3">
        <Typography variant="body1-bold">
          <TransText
            i18nKey="outboundTrips"
            values={{ count: outboundLegs.length }}
          />
        </Typography>
        {outboundLegs.map((leg) => (
          <LegOption leg={leg} key={leg.id} />
        ))}
      </div>
      {!!inboundLegs.length && (
        <>
          <Divider />
          <div className="flex flex-col gap-3">
            <Typography variant="body1-bold">
              <TransText
                i18nKey="inboundTrips"
                values={{ count: inboundLegs.length }}
              />
            </Typography>
            {inboundLegs.map((leg) => (
              <LegOption leg={leg} key={leg.id} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default LegOptionsSection;
