import { type FC, type ReactNode, useCallback } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '@/utils/breakpoints';
import { TransText, type TransTextKeys } from '@/i18n/trans/text';
import { NavLink } from 'react-router-dom';
import { Icons } from '@/components/icons';
import { cn } from '@/utils/cn';
import Divider from '@/components/primitives/Divider';
import { Button } from '@/components/primitives/Button';
import _kebabCase from 'lodash/kebabCase';
import { useAuth } from '@/hooks/useAuth';
import { getDisplayName } from '@/utils/profile';
import { profileDataSelector } from '@/features/profile/profileSelectors';
import { useSelector } from 'react-redux';

const menuItems: [TransTextKeys, string, ReactNode][] = [
  ['tickets', '/account/tickets', <Icons.ticket height={24} width={24} />],
  ['travelPasses', '/account/travel-passes', <Icons.travelPass />],
  ['loyalty', '/account/loyalty', <Icons.loyalty />],
  ['vouchers', '/account/vouchers', <Icons.wallet />],
  ['accountDetails', '/account/details', <Icons.user />],
];

interface AccountMenuProps {}

const AccountMenu: FC<AccountMenuProps> = () => {
  const { logout } = useAuth();
  const profile = useSelector(profileDataSelector);
  const displayName = getDisplayName(profile);
  const isLaptopOrBigger = useMediaQuery({
    minWidth: breakpoints.laptop,
  });

  const renderLogoutButton = useCallback(
    () => (
      <div className="fixed bottom-0 flex w-full flex-col laptop:static laptop:pl-4">
        <Divider />
        <Button
          className="flex h-auto w-full flex-shrink-0 flex-row gap-4 p-4 text-left"
          data-testid="log-out-button"
          variant="ghost"
          onClick={logout}
        >
          <Icons.logOut className="text-accent" />
          <Typography className="flex-grow" variant="button">
            <TransText i18nKey="logOut" />
          </Typography>
          <Icons.chevronRight className="text-accent" />
        </Button>
      </div>
    ),
    [logout]
  );

  return (
    <div id="container" className="flex h-full w-full flex-col">
      <div className="flex h-full flex-col gap-6">
        <Typography
          variant={isLaptopOrBigger ? 'heading2' : 'mobile-title'}
          className="px-4 laptop:pt-0"
        >
          <TransText i18nKey="helloDisplayName" values={{ displayName }} />
        </Typography>
        <div className="flex h-full flex-col">
          <div className="flex h-full flex-col gap-8 px-4">
            {menuItems.map(([transKey, url, Icon]) => (
              <NavLink
                to={url}
                className={({ isActive }) =>
                  cn(
                    'flex w-full flex-row items-center gap-4 pl-4 hover:text-accent',
                    {
                      'text-accent': isActive,
                    }
                  )
                }
              >
                <div className="text-accent">{Icon}</div>
                <Typography className="flex-grow">
                  <TransText i18nKey={transKey} />
                </Typography>
                <Button
                  variant="icon"
                  data-testid={`go-to-${_kebabCase(transKey)}-page`}
                >
                  <Icons.chevronRight className="text-accent" />
                </Button>
              </NavLink>
            ))}
          </div>
          {isLaptopOrBigger && renderLogoutButton()}
        </div>
      </div>
      {!isLaptopOrBigger && renderLogoutButton()}
    </div>
  );
};

export default AccountMenu;
