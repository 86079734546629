import React, { useMemo } from 'react';
import { Typography } from '@/components/primitives/Typography';
import { TransText } from '@/i18n/trans/text';
import Divider from '@/components/primitives/Divider';
import { Icons } from '@/components/icons';
import { formatDateWithDayOfWeek } from '@/utils/date-time';
import type { AdmissionDTO, BookedTripDTO, PassengerDTO } from '@/types/dto';
import { useBookingRefundEligibleTrips } from '@/hooks/useBookingRefundEligibleTrips';
import { useSelector } from '@/store/utils';
import {
  manageFlowBookingSelector,
  refundDetailsSelector,
  refundSelectedLegAdmissionsSelector,
} from '@/features/manage/manageSelectors';
import { currencySelector } from '@/features/configuration/configurationSelector';
import RefundSummary from '@/components/manage/refund/summary/RefundSummary';
import HighlightedRow from '@/components/booking-summary/HighlightedRow';

export const RefundLegOverview: React.FC = () => {
  const booking = useSelector(manageFlowBookingSelector);
  const { refundEligibleOutboundTrips, refundEligibleInboundTrips } =
    useBookingRefundEligibleTrips(booking);
  const selectedLegAdmissions = useSelector(
    refundSelectedLegAdmissionsSelector
  );
  const refundSummary = useSelector(refundDetailsSelector);

  const passengerMap = useMemo(
    () =>
      new Map(
        booking?.passengers?.map((passenger) => [passenger.id, passenger]) ?? []
      ),
    [booking]
  );

  if (!refundSummary || !refundSummary.refundOffers) {
    return null;
  }

  return (
    <div>
      <RefundSummary
        refundSummary={refundSummary}
        admissions={Object.values(selectedLegAdmissions).flat()}
      />
      <Typography variant="subtitle" className="my-5">
        <TransText i18nKey="youAreAboutToRefundFollowingItems" />
      </Typography>
      {refundEligibleOutboundTrips.some((trip) =>
        trip.legs?.some(
          (leg) => leg.id && selectedLegAdmissions[leg.id]?.length > 0
        )
      ) && (
        <TripSection
          trips={refundEligibleOutboundTrips}
          title={
            <TransText
              i18nKey="outboundTrips"
              values={{ count: refundEligibleOutboundTrips.length }}
            />
          }
          selectedLegAdmissions={selectedLegAdmissions}
          passengerMap={passengerMap}
        />
      )}
      {refundEligibleInboundTrips.some((trip) =>
        trip.legs?.some((leg) => selectedLegAdmissions[leg.id!]?.length > 0)
      ) && (
        <>
          <Divider className="my-4" variant="medium" />
          <TripSection
            trips={refundEligibleInboundTrips}
            title={
              <TransText
                i18nKey="returnTrips"
                values={{ count: refundEligibleInboundTrips.length }}
              />
            }
            selectedLegAdmissions={selectedLegAdmissions}
            passengerMap={passengerMap}
          />
        </>
      )}
    </div>
  );
};

const AdmissionDetails: React.FC<{
  admission: AdmissionDTO;
  passengerMap: Map<string | undefined, PassengerDTO>;
}> = ({ admission, passengerMap }) => {
  const firstPassengerId = admission.passengerIds?.[0];
  const passenger = passengerMap.get(firstPassengerId);
  const passengerName = `${passenger?.firstName?.value} ${passenger?.lastName?.value}`;
  const currency = useSelector(currencySelector);

  return (
    <div className="mt-3 flex flex-col gap-3">
      <AdmissionItem
        icon={<Icons.ticket height={16} width={16} className="text-primary" />}
        title={admission.productSummary || admission.summary}
        subtitle={passengerName}
        price={`${admission.price?.amount} ${currency.symbol}`}
      />
      {admission.ancillaries?.map((ancillary, index) => (
        <AdmissionItem
          key={index}
          icon={
            <Icons.bicycle height={16} width={16} className="text-primary" />
          }
          title={ancillary.productSummary}
          subtitle={passengerName}
          price={`${ancillary.price?.amount} ${currency.symbol}`}
        />
      ))}
    </div>
  );
};

const AdmissionItem: React.FC<{
  icon: React.ReactNode;
  title: React.ReactNode;
  subtitle: string;
  price: string;
}> = ({ icon, title, subtitle, price }) => (
  <div className="flex flex-row items-stretch justify-between">
    <div className="flex flex-row items-start gap-2">
      <div className="flex h-5 w-5 items-center justify-center">{icon}</div>
      <div>
        <Typography variant="body1-bold">{title}</Typography>
        <Typography variant="body2" className="text-neutral">
          {subtitle}
        </Typography>
      </div>
    </div>
    <Typography variant="body2-bold" className="mt-[2px] text-primary">
      {price}
    </Typography>
  </div>
);

const TripSection: React.FC<{
  trips: Array<BookedTripDTO>;
  title: React.ReactNode;
  selectedLegAdmissions: Record<string, AdmissionDTO[]>;
  passengerMap: Map<string | undefined, PassengerDTO>;
}> = ({ trips, title, selectedLegAdmissions, passengerMap }) => (
  <div className="rounded-md border border-neutral-light">
    <HighlightedRow className="rounded-t-lg">
      <div className="flex flex-row items-center gap-2">
        <Icons.transportFrom height={16} width={16} className="text-primary" />
        <Typography variant="body1-bold">{title}</Typography>
      </div>
      <Typography variant="body2" className="text-neutral">
        {formatDateWithDayOfWeek(trips[0]?.legs?.[0]?.departureTime)}
      </Typography>
    </HighlightedRow>
    {trips.map((trip) => (
      <div key={trip.id} className="px-4 py-3">
        {trip.legs?.map((leg) => {
          const legAdmissions = leg.id && selectedLegAdmissions[leg.id];
          if (!legAdmissions || legAdmissions.length === 0) return null;
          return (
            <div key={leg.id}>
              {legAdmissions.length > 1 && <Divider className="my-3" />}
              <div className="flex flex-row items-center gap-1">
                <Typography variant="body1-bold">
                  {leg.originStop?.name}
                </Typography>
                <Icons.oneWayAlt height={16} width={16} />
                <Typography variant="body1-bold">
                  {leg.destinationStop?.name}
                </Typography>
              </div>
              {legAdmissions.map((admission) => (
                <AdmissionDetails
                  key={admission.id}
                  admission={admission}
                  passengerMap={passengerMap}
                />
              ))}
            </div>
          );
        })}
      </div>
    ))}
  </div>
);

export default RefundLegOverview;
