import { useFormContext } from 'react-hook-form';
import { type FC } from 'react';
import type { CheckoutValues } from '@/utils/zodSchema';
import { TransText } from '@/i18n/trans/text';
import { InputField } from '@/components/form/InputField';
import { useRequiredFormFields } from '@/hooks/useRequiredFormFields';
import { PhoneInputField } from '@/components/form/PhoneInputField';
import { Badge } from '@/components/primitives/Badge';
import { Typography } from '@/components/primitives/Typography';

interface PurchaseProps {}

const Purchaser: FC<PurchaseProps> = () => {
  const { control, watch, setValue } = useFormContext<CheckoutValues>();
  const { purchaser: requirements } = useRequiredFormFields();
  const passengers = watch('passengers');
  const passengersToBeFilledFrom = passengers.filter(
    (passenger) => !!passenger.firstName
  );

  return (
    <>
      {passengersToBeFilledFrom.length > 0 && (
        <div className="flex flex-wrap items-center gap-1">
          <Typography variant="body2" className="text-dark">
            <TransText i18nKey="prefillWith" />
          </Typography>
          {passengersToBeFilledFrom.map((passenger, key) => (
            <Badge
              key={key}
              className="cursor-pointer"
              onClick={() =>
                setValue('purchaser', passenger, { shouldValidate: true })
              }
            >
              <Typography variant="body2-bold">
                {passenger.firstName}
              </Typography>
            </Badge>
          ))}
        </div>
      )}
      <div
        className="grid grid-cols-1 gap-2 tablet:grid-cols-2 tablet:gap-4"
        data-testid="purchaser-content"
      >
        <InputField
          label={<TransText i18nKey="firstName" />}
          name="purchaser.firstName"
          control={control}
          required={requirements.firstName.required}
        />
        <InputField
          label={<TransText i18nKey="lastName" />}
          name="purchaser.lastName"
          control={control}
          required={requirements.lastName.required}
        />
        <InputField
          label={<TransText i18nKey="email" />}
          name="purchaser.email"
          control={control}
          required={requirements.email.required}
        />
        <PhoneInputField
          label={<TransText i18nKey="phoneNumber" />}
          name="purchaser.phone.number"
          control={control}
          required={requirements.phone.required}
        />
      </div>
    </>
  );
};

export default Purchaser;
