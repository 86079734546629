import type { TransKeys } from '@/i18n/trans/index';
import { createTrans } from '@/i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransAlert = createTrans({
  bookingsSearchFailed: (
    <Trans
      i18nKey="alert.bookingsSearchFailed"
      defaults="Did not find a booking with this email and booking number. Please check the inserted data."
    />
  ),
  consentsNotAgreedWith: (
    <Trans
      i18nKey="alert.consentsNotAgreedWith"
      defaults="You must accept the mandatory consents in order to continue."
    />
  ),
  mandatoryFieldsMustBeFilled: (
    <Trans
      i18nKey="alert.mandatoryFieldsMustBeFilled"
      defaults='All fields must be filled, if not marked as "optional".'
    />
  ),
  mustAgreeWithToC: (
    <Trans
      i18nKey="alert.mustAgreeWithToC"
      defaults="You must agree with terms and conditions to continue."
    />
  ),
  paymentFailed: (
    <Trans i18nKey="alert.paymentFailed" defaults="Payment failed" />
  ),
  purchaseCancellationFailed: (
    <Trans
      i18nKey="alert.purchaseCancellationFailed"
      defaults="Failed to cancel booking"
    />
  ),
});

export type TransAlertKeys = TransKeys<typeof TransAlert>;
